import { useState, useEffect } from 'react';
import { forgotPasswordEmail } from 'actions/userActions';
import { Button } from 'components/UI/button/button';
import { TextField } from 'components/UI/textField/textField';
import styles from './forgotPasswordForm.module.scss';
import { useForm } from 'react-hook-form';
import { validEmailRegex } from 'utils/regexValidations';
import { useKeyDownTrigger } from 'hooks/useKeyDownTrigger';

export interface PasswordFieldProps {
  forgotPasswordEmail: string;
}

export const ForgotPasswordFormComponent = () => {
  const { register, handleSubmit, formState: { errors }, setError, clearErrors } = useForm<PasswordFieldProps>();
  const [showSuccessMessage, setShowSuccessMessage] = useState<string | null>(null);

  const emailValidationSchema = {
    required: "Email is required",
    pattern: {
      value: validEmailRegex,
      message: "Entered value does not match email format",
    }
  };

  const onSubmit = async (values: PasswordFieldProps) => {
    clearErrors();
    try {
      const response: any = await forgotPasswordEmail({ email: values.forgotPasswordEmail });
      if (response.status === 200) {
        setShowSuccessMessage('Check your email for a link to reset your password.');
      }
    } catch (error) {
      setError('forgotPasswordEmail', {
        type: 'manual',
        message: 'An error occurred, please try again.',
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.topContainer}>
      <TextField 
        id="forgotPasswordEmail" 
        register={register} 
        type="text" 
        placeholder="example@gmail.com" 
        className={styles.field} 
        labelClassName={styles.labels}  
        errors={errors}
        validationSchema={emailValidationSchema}
        autoFocus={true}
      />
      <Button className={styles.submitButton} type="submit">
        Send
      </Button>
      {showSuccessMessage &&
        <div className={styles.responseMessage}>
          {showSuccessMessage}
        </div>
      }
    </form>
  );
};
