import { FileStatus } from "components/companyTableRow/companyTableRow";
import * as base from "./lib/baseActions";
import { apiEndpoints } from "config/config";
import useSWR from "swr";
import { ApplicationFormFields, base64Uploads } from "components/newApplicationForm/newApplicationForm";
import { DateFormat, IntStatus, formatDateToString, parseDateString } from "utils/commonFunctions";
import { ApplicationStatus } from "pages/reviewApplications/reviewApplications";
import { useStickySWR } from "hooks/useStickySWR";
import useSWRInfinite from "swr/infinite";
import { useRef } from "react";
export interface ApplicationsFilters {
  searchWord?: string;
  rowNumber: number;
  pageNumber?: number;
  clientID?: string;
  orderBy: string;
  sortOrder: string;
  intStatus: IntStatus[];
}

export interface Applications {
  fileId: string;
  fileNo: number;
  applicantName: string
  fileDesc: string;
  lawyer: string;
  createdOn: string;
  fileStatus: FileStatus;
}

export interface ApplicationInfo {
  applicantName: string;
  applicantNumber: string;
  applicantEmail: string;
  streetName: string;
  unitNumber: string;
  city: string;
  provinceState: string;
  country: string;
  responsibleName: string;
  responsiblePhone: string;
  responsibleEmail: string;
  analystName: string;
  analystPhone: string;
  analystEmail: string;
}

export interface NewFileRequestParams {
  clientID: string, 
  formFields: ApplicationFormFields,
  reviewStatus: IntStatus,
  fileUploads?: base64Uploads,
  recordID?: string,
}

export interface ApplicationRequestsListParams {
  pageNumber?: number,
  rowNumber: number,
  searchWord?: string,
  orderBy?: string,
  sortOrder?: string,
  intStatus?: IntStatus[],
  responsibleLawyerID?: string,
}

interface FileNumberData {
  fileDescription: string;
  fileID: string;
}
export interface FileNumberOption {
  label: string;
  value: string;
}

export type ApplicationStatusType = typeof ApplicationStatus[keyof typeof ApplicationStatus];

export type ApplicationReviewType = {
  applicantName: string;
  clientID: number;
  clientName: string;
  clientNo: number;
  recordID: number;
  requestedOn: string;
  status: ApplicationStatusType;
  txtComments?: string;
  onClick?: (recordID: number) => void;
};


const getApplications = async (params: ApplicationsFilters): Promise<Applications[]> => {
  const { searchWord, orderBy, sortOrder, intStatus, rowNumber, pageNumber, clientID } = params;
  try {
    const result = await base.get(apiEndpoints.applications, {
      params: {
        searchWord,
        rowNumber,
        pageNumber,
        clientID,
        orderBy,
        sortOrder,
        intStatus,
      },
    });
    return result.data;
  }
  catch (error) {
    console.error("Error fetching applications: ", error);
    throw error;
  }
};

const useGetApplications = (params: ApplicationsFilters) => {
  const getKey = (pageIndex: number, previousPageData: Applications[] | null) => {
    if (previousPageData && previousPageData.length === 0) return null;
    return [apiEndpoints.applications, { ...params, pageNumber: pageIndex + 1 }];
  };

  const fetcher = async ([url, params]: [string, ApplicationsFilters]) => {
    const response = await getApplications(params);
    return response;
  };

  const { data, error, mutate, size, setSize, isValidating } = useSWRInfinite(getKey, fetcher, {
    revalidateFirstPage: false,
    keepPreviousData: false,
  });
  
  const applicationsData = data ? data.filter(application => application !== null).flat() : [];
  const hasMore = data && data[data.length - 1]?.length === params.rowNumber;

  return {
    data: applicationsData,
    error,
    mutate,
    isValidating,
    size,
    setSize,
    hasMore,
    noDataFound: applicationsData && applicationsData.length === 0,
  };
};


const getApplicationInfo = async (fileID: string): Promise<ApplicationInfo> => {
  try {
    const result = await base.get(apiEndpoints.applicationInfo, {params: {fileID}});
    return result.data;
  } catch (error) {
    console.error("Error fetching application information: ", error);
    throw error;
  }
};

const useGetApplicationInfo = (fileID: string) => {
  const { data, error } = useSWR([apiEndpoints.applicationInfo, fileID], () => getApplicationInfo(fileID));
  return { data, error };
};

const createApplication = async (clientID: string, data: ApplicationFormFields, recordID: string) => {
  const {
    textRequestDetails,   
    lastName,
    firstName,
    streetName,
    unitNumber,
    town,
    province,
    postal,
    country,
    phone,
    fileUploads
  } = data;
  
  try {
    await base.post(apiEndpoints.createApplication, {
      params: {
        clientID: clientID,
        recordID: recordID,
        textRequestDetails: textRequestDetails,
        firstName: firstName,
        lastName: lastName,
        streetName: streetName,
        unitNumber: unitNumber,
        townCity: town,
        provinceState: province,
        countryCode: country,
        postalCode: postal,
        phoneNumber: phone,
        fileUploads: fileUploads
      },
    });
  } catch (err) {
    console.error("error creating a new application: ", err);
  }
};

// helper function for createApplicationRequest & reviewApplicastionRequest
const extractNewFileRequestParams = ({
  clientID, 
  formFields,
  reviewStatus,
  fileUploads,
  recordID,
}: NewFileRequestParams) => {
  const {   
    textRequestDetails,
    fullName,
    lastName,
    firstName,
    birthday,
    nationality,
    passportNumber,
    title,
    streetName,
    unitNumber,
    town,
    province,
    postal,
    country,
    email,
    phone,
    department,
    matterType,
    comments = "",
    assignedLawyer,
    caseAnalyst
  } = formFields;

  return {
    recordID: recordID,
    clientID: clientID,
    textRequestDetails: textRequestDetails,
    fullName: fullName,
    title: title,
    firstName: firstName,
    lastName: lastName,
    nationality: nationality,
    passportNumber: passportNumber,
    birthday: birthday,
    streetName: streetName,
    unitNumber: unitNumber,
    townCity: town,
    provinceState: province,
    countryCode: country,
    postalCode: postal,
    email: email,
    phoneNumber: phone,
    passportBio: fileUploads?.passportUpload,
    educationDocs: fileUploads?.postSecondaryUpload,
    resume: fileUploads?.resumeUpload,
    cadStatusDoc: fileUploads?.canadianUpload,
    marriageCert: fileUploads?.marriageUpload,
    familyPassBio: fileUploads?.familyPassportUpload,
    birthCerts: fileUploads?.familyBirthCertUpload,
    intStatus: reviewStatus,
    comments: comments,
    department: department,
    matterType: matterType,
    assignedLawyer: assignedLawyer,
    caseAnalyst: caseAnalyst,
  };
};

const createApplicationRequest = async (data: NewFileRequestParams) => {
  const params = extractNewFileRequestParams(data);
  if (params.intStatus === IntStatus.PendingReview) {
    try {
      await base.post(apiEndpoints.createApplicationRequest, {params});
    } catch (err) {
      console.error("error submitting application: ", err);
    } 
  } else {
    console.error("error submitting application: incorrect IntStatus: ", data.reviewStatus);
  }
};
  
const reviewApplicationRequest = async (data: NewFileRequestParams) => {
  const params = extractNewFileRequestParams(data);
  if (data.reviewStatus === IntStatus.PendingReview 
    || data.reviewStatus === IntStatus.Rejected
    || data.reviewStatus === IntStatus.Approved) {
    try {
      await base.put(apiEndpoints.reviewApplicationRequest, {params});
    } catch (err) {
      console.error("error submitting review: ", err);
    } 
  } else {
    console.error("error submitting review: incorrect IntStatus: ", data.reviewStatus);
  }
};

const getApplicationRequestsList = async (params: ApplicationRequestsListParams): Promise<any[]> => {
  const result = await base.get(apiEndpoints.getApplicationRequestsList, { params: params });
  return result.data;
};

const parseApplicationRequestsList = (rawData: any[]) => {
  return rawData.map((rawAppData: any) => {
    return {
      applicantName: rawAppData.applicantName,
      clientID: rawAppData.clId && parseInt(rawAppData.clId, 10),
      clientName: rawAppData.clName,
      clientNo: rawAppData.clNo && parseInt(rawAppData.clNo, 10),
      recordID: rawAppData.recordId && parseInt(rawAppData.recordId, 10),
      requestedOn: formatDateToString(parseDateString(rawAppData.requestedOn), DateFormat.YYYY_MM_DD_slashes),
      status: rawAppData.status,
      txtComments: rawAppData.txtComments,
    } as ApplicationReviewType;
  });
};

const useGetApplicationRequestsList = (params: ApplicationRequestsListParams) => {
  const getKey = (pageIndex: number, previousPageData: ApplicationReviewType[] | null) => {
    if (previousPageData && previousPageData.length === 0) return null; // reached the end
    return [apiEndpoints.getApplicationRequestsList, { ...params, pageNumber: pageIndex + 1 }];
  };

  const fetcher = async ([url, params]: [string, ApplicationRequestsListParams]) => {
    const response = parseApplicationRequestsList( await getApplicationRequestsList(params));
    return response;
  };

  const { data, error, mutate, size, setSize, isValidating } = useSWRInfinite(getKey, fetcher, {
    revalidateFirstPage: false,
  });

  const applicationsListData = data ? data.flat() : [];
  const hasMore = data && data[data.length - 1]?.length === params.rowNumber;

  return {
    data: applicationsListData,
    error,
    mutate,
    isValidating,
    size,
    setSize,
    hasMore,
    noDataFound: applicationsListData && applicationsListData.length === 0,
  };
};
const getApplicationRequest = async (recordID: string) => {
  try {
    const result = await base.get(apiEndpoints.getApplicationRequest, {
      params: { recordID }
    });
    return result.data[0];
  } catch (err) {
    console.error("Error fetching application request: ", err);
    return null;
  }
};

const getFileNumbers = async (searchWord?: string): Promise<FileNumberData[]> => {
  const result = await base.get(apiEndpoints.fileNumbers, {
    params: { searchWord }
  });
  return result.data;
};

const useGetFileNumbers = (searchWord?: string) => {
  const fileNumberOptions = useRef<FileNumberOption[]>([]);
  const { data, error } = useSWR([apiEndpoints.fileNumbers, searchWord], () => getFileNumbers(searchWord === '' ? undefined : searchWord));
  if (data) {
    const options: FileNumberOption[] = (data || []).map(item => ({ label: item.fileDescription, value: item.fileID }));
    fileNumberOptions.current = options;
  }
  return { data: fileNumberOptions.current, error };
};

export {
  useGetApplications,
  getApplicationInfo,
  useGetApplicationInfo,
  createApplication,
  createApplicationRequest,
  reviewApplicationRequest,
  useGetApplicationRequestsList,
  getApplicationRequest,
  useGetFileNumbers,
};