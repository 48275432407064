import React, { ChangeEvent, useCallback, useState } from 'react';
import style from './searchInput.module.scss';
import _ from 'lodash';
import SearchIcon from 'components/icons/searchIcon';
import classNames from 'classnames';

interface SearchInputProps {
  id?: string;
  placeholder?: string;
  startingValue?: string;
  debounceTime?: number;
  className?: string;
  onChange: (query: string) => void;
  posIconLeft?: boolean;
}

export const SearchInput =  ({
  id,
  placeholder, 
  startingValue,
  debounceTime = 200,
  className,
  onChange,
  posIconLeft,
}: 
SearchInputProps) => {
  const [searchQuery, setSearchQuery] = useState<string>(startingValue || '');

  const debouncedSearch = useCallback(
    _.debounce(onChange, debounceTime, { leading: false, trailing: true }),
    [onChange]
  );

  const onSearchQueryChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newSearchQuery = event.target.value;
    setSearchQuery(newSearchQuery);
    debouncedSearch(newSearchQuery);
  };

  const PositionClassName = `${style.searchContainer} ${posIconLeft ? style.positionLeft : ''}`;

  return (
    <div className={classNames(PositionClassName, className)}>
      {posIconLeft && (
        <div className={style.searchMobile}>
          <SearchIcon />
        </div>
      )}
      <input
        id={id}
        name="search" 
        placeholder={placeholder}
        onChange={onSearchQueryChange}
        value={searchQuery}>
      </input>
      <div className={style.search}>
        <SearchIcon />
      </div>
    </div>
  );
};
