import { ReactNode, useState, useEffect } from "react";
import classNames from "classnames";
import styles from "./input.module.scss";
import { UseFormRegister, FieldErrors } from 'react-hook-form';
import { parseHTML } from 'utils/commonFunctions';

export interface ValidationSchema {
  required?: string;
  pattern?: {
    value: any;
    message: string;
  },
  minLength?: {
    value: number,
    message: string
  },
  validate?: (value: string) => boolean | string;
}

export interface InputProps {
  id: string;
  containerClass?: string;
  wrapperClass?: string;
  labelClass?: string;
  inputClass?: string;
  placeholder?: string;
  type?: string;
  value?: string;
  label: string;
  register?: UseFormRegister<any>; 
  errors?: FieldErrors<any>;
  locked?: boolean;
  validationSchema?: ValidationSchema;
  onChange?: (value: string) => void;
  isTablePreviewQuestion?: boolean;
  accepted?: boolean;
  showErrorStyle?: boolean;
}

export const Input = ({
  id,
  containerClass,
  wrapperClass,
  labelClass,
  inputClass,
  placeholder,
  type = 'text',
  value,
  label,
  register,
  errors,
  validationSchema,
  locked,
  onChange,
  isTablePreviewQuestion,
  accepted,
  showErrorStyle,
}: InputProps) => {
  const [inputValue, setInputValue] = useState(value);

  const hasErrors = showErrorStyle || ((errors && errors[id] || accepted === false) && !isTablePreviewQuestion);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleChange = (event: any) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };
  const inputProps = register ? register(id as `${string}`, validationSchema) : {};
  return (
    <div className={classNames(styles.wrapperDiv, wrapperClass, isTablePreviewQuestion && styles.tableQuestionMobileStyle)}>
      <div className={classNames(styles.inputContainer, containerClass, hasErrors && styles.errorBorder)}>
        {/* TODO: We should use the <label> element here instead so it's easier to target and can be consistent throughout the app */}
        {label && !isTablePreviewQuestion && <div className={classNames(styles.label, labelClass, hasErrors && styles.errorText)}>{parseHTML(label)}</div>}

        {/* TODO: This, along with the other inputs should be styled the same way using the mixin */}
        <input 
          id={id}
          type={type}
          className={classNames(styles.input, inputClass)} 
          placeholder={placeholder} 
          value={inputValue || ''}
          onChange={handleChange}
          disabled={locked}
          {...(onChange ? {} : inputProps)}
        />
      </div>
      {errors && isTablePreviewQuestion !== true && (
        <div>
          <span id={`${id}-error`} className={styles.alert}>{errors[id]?.message}</span>
        </div>
      )}
    </div>
  );
};