import { useRef, useEffect, MutableRefObject } from 'react';
import { debounce } from 'lodash'; // Ensure you have lodash installed

const useInfiniteScroll = (
  loadMore: () => void,
  hasMore: boolean | undefined,
  dependencies: any[] = []
): [MutableRefObject<HTMLDivElement | null>] => {
  const loader = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '200px',
      threshold: 0.2,
    };

    const debouncedLoadMore = debounce(loadMore, 100); // Adjust the debounce delay as needed

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting && hasMore) {
        debouncedLoadMore();
      }
    }, options);

    if (loader.current) {
      observer.observe(loader.current);
    }

    return () => {
      if (loader.current) {
        observer.unobserve(loader.current);
      }
      debouncedLoadMore.cancel(); // Cancel any pending debounced calls
    };
  }, [loader, hasMore, ...dependencies]);

  return [loader];
};

export default useInfiniteScroll;
