import { useState, useEffect } from 'react';
import style from './twoFactor.module.scss';
import Logos from 'assets/images/company-logo.png';
import TwoFactorGraphic from 'assets/images/twoFactorImage.svg';
import {TwoFactorForm} from '../../components/twoFactorForm/twoFactorForm';
import SimpleLayout from 'components/simpleLayout/simpleLayout';
import { setup2FA, getHasVerified2FA } from 'actions/userActions';
import { LoadingContainer } from 'components/UI/loadingContainer/loadingContainer';

const useTwoFactorSetup = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [userHas2FA, setUserHas2FA] = useState(false);
  const [qrCode, setQrCode] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchTwoFactorStatus = async () => {
      try {
        setIsLoading(true);
        const { has2FA } = await getHasVerified2FA();
        setUserHas2FA(has2FA);
        if (!has2FA) {
          const twoFactorInfo = await setup2FA();
          setQrCode(twoFactorInfo.qrCode);
        }
      } catch (err) {
        setError('Failed to load two-factor authentication settings. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchTwoFactorStatus();
  }, []);

  return { isLoading, userHas2FA, qrCode, error };
};

export const TwoFactor = () => {
  const { isLoading, userHas2FA, qrCode, error } = useTwoFactorSetup();

  if (isLoading || !TwoFactorGraphic) {
    return (
      <div className={style.container}>
        <div className={style.loading}>
          <LoadingContainer loadingLabel="Loading two-factor authentication settings" />
        </div>
      </div>
    );
  }

  if (error) {
    return <div className={style.error}>{error}</div>;
  }

  return (
    <SimpleLayout>
      <div className={style.content}>
        <div className={style.form}>
          <img className={style.logo} src={Logos} alt="Company Logo" />
          <h3 className={style.twoStep}>2-Step Verification</h3>
          {!userHas2FA && qrCode && (
            <div className={style.twoFactorContent}>
              <ul className={style.instructionList}>
                <li>Download the <b>Google Authenticator Application</b> from your Mobile App Store.</li>
                <li>In the App, click the <b>+</b> icon to setup a new account.</li>
                <li>Select <b>“Scan a QR Code.”</b></li>
              </ul>
              <img className={style.barcode} src={qrCode} alt="2FA QR Code" />
            </div>
          )}
          <TwoFactorForm />
        </div>
        <div className={style.imageContainer}>
          <img className={style.twofactorImage} src={TwoFactorGraphic} alt="Two-Factor Authentication" />
        </div>
      </div>
    </SimpleLayout>
  );
};
