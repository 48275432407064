import React from 'react';
import style from "./forgotPassword.module.scss";
import logo from 'assets/images/company-logo.png';
import ForgotPasswordGraphic from 'components/icons/loginGraphics/forgotPasswordGraphic';
import { ForgotPasswordFormComponent } from "../../components/forgotPasswordForm/forgotPasswordForm";
import SimpleLayout from 'components/simpleLayout/simpleLayout';
import { LoginGraphicContainer } from 'components/loginGraphicContainer/loginGraphicContainer';

export const ForgotPassword = () => {
  return (
    <SimpleLayout>
      <div className={style.content}>
        <div className={style.form}>
          <img className={style.logo} src={logo}></img>
          <h3>Forgot Password</h3>
          <div className={style.instructionsCenter}>
            <p className={style.instructions}>Please enter your email below to receive a link to reset your password.</p>
          </div>

          <ForgotPasswordFormComponent/>

        </div>
        <LoginGraphicContainer Graphic={ForgotPasswordGraphic}/>
      </div>
    </SimpleLayout>
  );
};
