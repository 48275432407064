import styles from './navLink.module.scss';
import { NavLink } from 'react-router-dom';
import { ReactNode } from "react";
import classNames from "classnames";
import { useLocation } from 'react-router-dom';



interface InterfaceNavLinkProps {
  children?: ReactNode | string;
  id?: string;
  to: string;
  className?: string;
  iconClassName?: string;
  icon?: any;
  activeLinks: Array<string>;
  onClick?: any;
}

export const InterfaceNavLink = ({
  id,
  to,
  children,
  className,
  iconClassName,
  icon,
  activeLinks,
  onClick,
}: InterfaceNavLinkProps) => {

  const { pathname } = useLocation();

  return (
    <NavLink
      id={id}
      to={to}
      className={classNames(styles.interfaceNavLink, className)}
      activeClassName={styles.interfaceNavLinkActive}
      isActive={ () => activeLinks.includes(pathname)}
      onClick={onClick}
    >
      <div id={`${id}-icon`}className={classNames(styles.linkIcon, iconClassName)}>
        {icon}
      </div>
      {children}
    </NavLink>
  );
};