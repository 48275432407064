import { useEffect, useMemo, useState } from 'react';
import { useForm } from "react-hook-form";
import { useGetQuestionnaireInfo, QuestionnaireInfo, Section, saveQuestionnaireInfo, QuestionTableRecord, TableQuestionRecord } from 'actions/questionnaireActions';
import { IntStatus, fileToBase64, getFieldId, parseFileExtensions, parentValueCheck } from 'utils/commonFunctions';
import { QuestionDataType } from './questionnaireComponents/question/question';
import { QuestionTypes } from './questionnaireComponents/questionnaireBaseComponent/questionBaseComponent';
import { DocumentName, DocumentNames, getDocumentNamesFromPaths } from 'actions/documentActions';
import { DocumentAdditionParams, DocumentDeletionParams } from 'components/UI/FileUpload/fileUpload';
import { AllowedExtensions } from 'utils/imageFunctions';
import { COMMENTS_QUESTION_ID, COMMENTS_SECTION_ID, QuestionnaireDataParams } from './questionnaire';

interface QuestionGroups {
  [key: number]: QuestionDataType[];
}

interface QuestionValidationParams {
  updatedQuestion: QuestionDataType;
  updatedValue: string;
  updatedDocumentNames: DocumentNames;
  sectionId: number;
  recordId?: number;
  isTableQuestion: boolean;
  isInitialScan: boolean;
}

interface SectionValidationParams {
  section: Section;
  isInitialScan: boolean;
}

interface TableRowValidationParams {
  sectionId: number;
  recordId: number;
  isInitialScan: boolean;
}

interface QuestionnaireValidationParams {
  isInitialScan: boolean;
}

interface ClearChildTableRowQuestionErrorParams {
  parentQuestion: QuestionDataType;
  updatedParentValue: string;
  sectionId: number; 
  recordId: number;
}

interface ClearChildGridQuestionErrorParams {
  parentQuestion: QuestionDataType;
}

interface QuestionLockingParams {
  accepted: boolean | null | undefined,
  isTableQuestion: boolean,
  isReview: boolean,
  intStatus: IntStatus,
}

export interface ShouldRenderTableQuestionParams {
  sectionId: number,
  recordId: number,
  questionId: string
}

export interface TableModalSaveParams {
  sectionId: number;
  recordId: number;
}

export interface TableModalCloseParams {
  sectionId: number;
  recordId: number;
}

export interface UpdateGridQuestionParams {
  sectionId: number, 
  updatedQuestion: QuestionDataType, 
  updatedDocumentNames?: DocumentNames,
}

export interface UpdateTableQuestionParams {
  questionnaireQuestion: QuestionDataType
  sectionId: number;
  recordId: number;
  newValue: string;
}

export interface AddTableRowParams {
  sectionId: number;
}

export interface DeleteTableRowParams {
  sectionId: number;
  recordId: number;
}

export interface SectionAcceptanceParams {
  sectionId: number;
  accepted: boolean | null;
}
  
export interface FileStorage {
  file: File;
  id: string;
  questionId: string;
}

export interface ConfirmationOaths {
  oathOfSubmit: boolean;
  oathOfHonesty: boolean;
}

const getUploadedDocumentNames = async (json: QuestionnaireInfo, intStatus: IntStatus): Promise<DocumentName[]> => {
  const rawDocumentNames = json.sections.reduce<DocumentName[]>((questionnaireDocuments, section) => {
    return [
      ...questionnaireDocuments,
      ...section.questions.reduce<DocumentName[]>((sectionDocuments, question) => {
        return [
          ...sectionDocuments,
          ...(question.documents || []).map(path => {
            return {
              name: path, // to be converted by getDocumentNamesFromPaths()
              fieldId: getFieldId({ label: question.label, isTableQuestion: false }), 
              permanent: intStatus === IntStatus.Approved,
              path: path,
            };
          }),
        ];
      }, []),
    ];
  }, []); 
  if (rawDocumentNames.length === 0) return [];

  const paths = rawDocumentNames.map(rawDoc => rawDoc.name);
  const names = await getDocumentNamesFromPaths(paths);

  const documentNames = rawDocumentNames.map((rawDoc, index) => {
    return {
      ...rawDoc,
      name: names[index]
    };
  });
  return documentNames;
};

const removeDropdownOptions = (json: QuestionnaireInfo): QuestionnaireInfo => {
  return {
    ...json,
    sections: json.sections.reduce<Section[]>((accSections, section) => {
      accSections.push({
        ...section,
        questions: section.questions.reduce<QuestionDataType[]>((accQuestions, question) => {
          accQuestions.push({
            ...question,
            drop_down: undefined,
          });
          return accQuestions;
        }, []),
      });
      return accSections;
    }, []),
  };
};

const setAcceptanceOnReviewerSubmit = (json: QuestionnaireInfo, intStatus: IntStatus): QuestionnaireInfo => {
  return {
    ...json,
    sections: json.sections.map(section => {
      let updatedSection = section;
      if (section.bit_isTable) {
        updatedSection = {
          ...section,
          accepted: section.accepted === false ? false : true,
        };
      } else {
        updatedSection = {
          ...section,
          questions: section.questions.map(question => {
            return {
              ...question,
              accepted: (
                question.id === COMMENTS_QUESTION_ID 
                  ? (intStatus === IntStatus.Approved ? true : null) // comments
                  : (question.accepted === false ? false : true) // non-comments
              )
            };
          }),
        };
      }
      return updatedSection;
    })
  };
};

const isLockedQuestion = ({ accepted, isTableQuestion, isReview, intStatus }: QuestionLockingParams): boolean => {
  if (isReview || intStatus === IntStatus.PendingReview || intStatus === IntStatus.Approved) {
    return true;
  }

  // TODO: update once question.accepted field is solidified
  // until then, editTableModal question locking will be managed by isLockedTable
  if (isTableQuestion) return false;

  if (intStatus === IntStatus.Rejected) {
    return !!accepted;
  }
  return false;
};

const parseIncomingQuestionnaire = (json: QuestionnaireInfo, isReview: boolean, intStatus: number): QuestionnaireInfo => {
  let commentSection: Section = {
    accepted: null,
    sectionID: COMMENTS_SECTION_ID,
    txtLabel: 'Comments',
    questions: [{
      questionID: null,
      id: COMMENTS_QUESTION_ID,
      label: 'Comments',
      fieldtype: 'MULTILINE',
      width: 100,
      required: false, 
      characterlimit: 1000, 
      value: '', 
      grid_id: 1,
      locked: !isReview || intStatus === IntStatus.Approved,
    }]
  };
  const hasCommentSection = json.sections.some(section => section.txtLabel === 'Comments');

  const sectionsWithIds = json.sections.map((section: Section, index) => ({
    ...section,
    id: index.toString(),
    questions: section.questions.map((question: QuestionDataType) => ({
      ...question,
      id: question.id.toString(),
      locked: isLockedQuestion({ accepted: question.accepted, isTableQuestion: !!section.bit_isTable, isReview, intStatus }),
    })),
  }));

  commentSection = hasCommentSection ? sectionsWithIds[sectionsWithIds.length - 1] : commentSection;
  commentSection = {
    ...commentSection, 
    questions: [{
      ...commentSection.questions[0], 
      locked: !isReview || intStatus === IntStatus.Approved, 
      label: 'Comments', 
      id: COMMENTS_QUESTION_ID,
    }],
  };
  const nonCommentSections = hasCommentSection ? sectionsWithIds.slice(0, sectionsWithIds.length - 1) : sectionsWithIds;
  const sectionsIncludingComments = [...nonCommentSections, commentSection];

  let questionnaire;
  if (isReview || commentSection.questions[0].value) {
    questionnaire = {
      ...json,
      sections: sectionsIncludingComments,
    };
  } else {
    questionnaire = {
      ...json,
      sections: sectionsIncludingComments.slice(0, sectionsIncludingComments.length - 1),
    };
  }

  // parse out corrupted records
  // TODO: data integrity should be handled by the API or SP
  questionnaire = {
    ...questionnaire,
    sections: questionnaire.sections.map(section => {
      if (section.bit_isTable) {
        return {
          ...section,
          records: section.records?.filter(record => record.questions),
        };
      } else {
        return section;
      }
    })
  };

  // parse out cyclical questions (questions that are their own parents)
  // TODO: data integrity should be handled by the API or SP
  questionnaire = {
    ...questionnaire,
    sections: questionnaire.sections.map(section => {
      return {
        ...section,
        questions: section.questions.map(question => {
          return {
            ...question,
            parentID: String(question.parentID) === question.id ? undefined : question.parentID,
          };
        }),
      };
    }),
  };

  return questionnaire;
};

const parseOutgoingQuestionnaire = (json: QuestionnaireInfo): QuestionnaireInfo => {
  const questionnaire = removeDropdownOptions(json);
  return questionnaire;
};

export const useQuestionnaireHook = (
  selectedQuestionnaire: QuestionnaireDataParams, 
  closeQuestionnaire: () => void, 
  mutateQuestionnaires: () => void,
  isReview: boolean, 
  setHideQuestionnaireModalHeader: (value: boolean) => void,
) => {
  const { contID, fileID, questionaireID, intStatus } = selectedQuestionnaire;
  const { formState: { errors }, reset, register, setValue, setError, clearErrors, handleSubmit } = useForm();
  const { data: questionnaireJSON, error: questionnaireJSONError } = useGetQuestionnaireInfo({ contId: contID, fileId: fileID, questionnaireId: questionaireID });
  const parsedQuestionnaire = questionnaireJSON ? parseIncomingQuestionnaire(questionnaireJSON, isReview, intStatus) : null;
  const [oaths, setOaths] = useState<ConfirmationOaths>({ oathOfSubmit: false, oathOfHonesty: false });
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [fileStorage, setFileStorage] = useState<FileStorage[]>([]);
  const [workingQuestionnaire, setWorkingQuestionnaire] = useState<QuestionnaireInfo | null>(parsedQuestionnaire);
  const [hasQuestionnaireChanged, setHasQuestionnaireChanged] = useState<boolean>(false);
  const [modalQuestionnaire, setModalQuestionnaire] = useState<QuestionnaireInfo | null>(parsedQuestionnaire);
  const [isInitialScan, setIsInitialScan] = useState<boolean>(true);
  const [isLoadingDocuments, setIsLoadingDocuments] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [submitMessage, setSubmitMessage] = useState("Submitting");
  const [tableModalIsOpen, setTableModalIsOpen] = useState<boolean>(false);
  const [documentNames, setDocumentNames] = useState<DocumentNames>({ localDocuments: [], uploadedDocuments: [] });
  useEffect(() => {
    setIsLoadingDocuments(true);
    setHideQuestionnaireModalHeader(true);

    if (!questionnaireJSON) return;

    setWorkingQuestionnaire(parseIncomingQuestionnaire(questionnaireJSON, isReview, intStatus));
    setModalQuestionnaire(parseIncomingQuestionnaire(questionnaireJSON, isReview, intStatus));

    const fetchDocumentNames = async () => {
      const uploadedDocuments = await getUploadedDocumentNames(questionnaireJSON, selectedQuestionnaire.intStatus);
      setDocumentNames({ localDocuments: [], uploadedDocuments });

      setIsLoadingDocuments(false);
      setHideQuestionnaireModalHeader(false);
    };
    fetchDocumentNames();
  }, [questionnaireJSON]);

  useEffect(() => {
    if (workingQuestionnaire) {
      setModalQuestionnaire(workingQuestionnaire);
      if (isInitialScan) {
        validateQuestionnaire({ isInitialScan });
      }
      setIsInitialScan(false);
    }
  }, [workingQuestionnaire]);

  const isLockedTable = (sectionId: number) => {
    const initialSection = questionnaireJSON?.sections.find(section => section.sectionID === sectionId);

    if (!initialSection || isReview || isLockedQuestionnaire) return true;

    if (selectedQuestionnaire.intStatus === IntStatus.Rejected) {
      return initialSection.accepted === true;
    }
    return false;
  };

  const isLockedQuestionnaire = useMemo(() => {
    const status: IntStatus = selectedQuestionnaire.intStatus;

    if (isReview) {
      return status === IntStatus.Approved;
    } else {
      return status === IntStatus.Approved || status === IntStatus.PendingReview;
    }
  }, [selectedQuestionnaire, isReview]);

  const shouldRenderTableQuestion = ({ sectionId, recordId, questionId }: ShouldRenderTableQuestionParams): boolean => {
    const section = modalQuestionnaire?.sections.find(section => section.sectionID === sectionId);
    const childQuestion = section?.questions.find(question => question.id === questionId);

    if (!childQuestion?.parentID) return true;

    const record = section?.records?.find(record => record.recordID === recordId);
    const parentQuestionRecord = record?.questions.find(questionRecord => String(questionRecord.questionID) === String(childQuestion?.parentID));

    const shouldRenderChild = parentValueCheck(childQuestion.parentValue, parentQuestionRecord?.value, childQuestion.matchParentValues);
    const shouldRenderParent = shouldRenderTableQuestion({ sectionId, recordId, questionId: String(parentQuestionRecord?.questionID) });
    
    return shouldRenderChild && shouldRenderParent;
  };

  const getParentQuestion = (parentId: string, sectionId: number): QuestionDataType | undefined => {
    const section = workingQuestionnaire?.sections.find(s => s.sectionID === sectionId);
    const parentQuestion = section?.questions.find(q => q.id === parentId);
    return parentQuestion;
  };

  const clearChildTableRowQuestionErrors = ({ parentQuestion, updatedParentValue, sectionId, recordId }: ClearChildTableRowQuestionErrorParams) => {
    modalQuestionnaire?.sections.forEach(section => {
      if (section.sectionID === sectionId) {
        section.questions.forEach(childQuestion => {
          if (childQuestion.parentID?.toString() === parentQuestion.id && !parentValueCheck(childQuestion.parentValue, updatedParentValue, childQuestion.matchParentValues)) {
            clearErrors(getFieldId({ label: childQuestion.label, recordId: recordId, isTableQuestion: true }));
          }
        });
      }
    });
  };

  const clearChildGridQuestionErrors = ({ parentQuestion }: ClearChildGridQuestionErrorParams) => {
    workingQuestionnaire?.sections.forEach(section => {
      section.questions.forEach(childQuestion => {
        if (childQuestion.parentID?.toString() === parentQuestion.id && !parentValueCheck(childQuestion.parentValue, parentQuestion.value, childQuestion.matchParentValues)) {
          clearErrors(getFieldId({ label: childQuestion.label, isTableQuestion: false }));
        }
      });
    });
  };

  const clearTableRowErrors = (sectionId: number, recordId: number) => {
    const section = modalQuestionnaire?.sections.find(section => section.sectionID === sectionId);
    section?.questions.forEach(question => {
      const fieldId = getFieldId({ label: question.label, recordId, isTableQuestion: true });
      clearErrors(fieldId);
    });
  };

  const validateQuestion = ({ 
    updatedQuestion, 
    updatedValue,
    updatedDocumentNames,
    sectionId,
    recordId,
    isTableQuestion,
    isInitialScan, 
  }: QuestionValidationParams): boolean => {
    const parentQuestion = getParentQuestion(`${updatedQuestion.parentID}`, sectionId);
    const value = updatedValue;
    const fieldId = getFieldId({ label: updatedQuestion.label, recordId, isTableQuestion });
    const emptyTypes = [undefined, null, false, '', 'false'];

    let isValid = true;
    if (
      (parentQuestion && !parentValueCheck(updatedQuestion.parentValue, parentQuestion.value, updatedQuestion.matchParentValues)) ||
      isReview ||
      updatedQuestion.fieldtype === QuestionTypes.label
    ) {
      return isValid;
    }

    if (isTableQuestion) {
      clearChildTableRowQuestionErrors({
        parentQuestion: updatedQuestion,
        updatedParentValue: value,
        sectionId: sectionId, 
        recordId: recordId || -1
      });
    } else {
      clearChildGridQuestionErrors({ parentQuestion: updatedQuestion });
    }

    if (!isTableQuestion && updatedQuestion.accepted === false && selectedQuestionnaire.intStatus !== IntStatus.PendingReview) {
      setError(fieldId, {
        type: "required",
        message: `Missing/Incorrect data; please review & complete.`
      });
      return false;
    }
    if (updatedQuestion.fieldtype === QuestionTypes.document && !isInitialScan) {
      if (updatedQuestion.required && updatedDocumentNames.localDocuments.length === 0 && updatedDocumentNames.uploadedDocuments.length === 0) {
        isValid = false;
        setError(fieldId, {
          type: "required",
          message: `${updatedQuestion.label} is required`
        });
      }     
    }
  
    if (isValid && 
      updatedQuestion.required && 
      (!value || emptyTypes.includes(value)) && 
      !isInitialScan && 
      updatedQuestion.fieldtype !== QuestionTypes.document) {
      setError(fieldId, {
        type: "required",
        message: `${updatedQuestion.label} is required`
      });
      isValid = false;
    }

    const isTextboxOrMultiline = updatedQuestion.fieldtype === QuestionTypes.textbox || updatedQuestion.fieldtype === QuestionTypes.multiline;
    if (isTextboxOrMultiline && updatedQuestion.characterlimit && value && value.length > updatedQuestion.characterlimit && !isInitialScan) {  
      setError(fieldId, {
        type: "maxCharacters",
        message: `Max character limit reached: ${value.length} / ${updatedQuestion.characterlimit}`
      });
      isValid = false;
    }
  
    if (isTextboxOrMultiline && isValid && updatedQuestion.regex && value && !isInitialScan) {
      const first = updatedQuestion.regex.charAt(0);
      const last = updatedQuestion.regex.slice(-1);
      const regex = RegExp(first === '/' && last === '/' ? updatedQuestion.regex.slice(1, -1) : updatedQuestion.regex);

      if (!regex.test(value)) {
        setError(fieldId, {
          type: "pattern", 
          message: `Invalid format for ${updatedQuestion.label}`
        });
        isValid = false;
      }
    }

    if (isValid) {
      clearErrors(fieldId); 
    }
    return isValid;
  };
  
  const validateSection = ({ section, isInitialScan }: SectionValidationParams) => {
    let hasErrors = false;

    if (section.bit_isTable) {
      hasErrors = Boolean(section.records?.reduce<boolean>((hasError, record) => {
        const isValidRow = validateTableRow({
          sectionId: section.sectionID,
          recordId: record.recordID,
          isInitialScan: isInitialScan,
        });
        return hasError || !isValidRow;
      }, false));

    } else {
      hasErrors = section.questions.reduce<boolean>((hasError, question) => {
        const isValid = validateQuestion({ 
          updatedQuestion: question, 
          updatedValue: question.value,
          updatedDocumentNames: documentNames,
          sectionId: section.sectionID, 
          isTableQuestion: false,
          isInitialScan: isInitialScan,
        });
        return hasError || !isValid; 
      }, false);

    }
    return hasErrors;
  };

  const validateTableRow = ({ sectionId, recordId, isInitialScan }: TableRowValidationParams) => {
    const section = modalQuestionnaire?.sections.find(section => section.sectionID === sectionId);
    const record = section?.records?.find(record => record.recordID === recordId);

    // ignore table row if data is corrupted
    if (!record?.questions) return true;

    const recordValueMap = new Map(
      record?.questions?.map(q => [String(q.questionID), q.value]) // TODO: fix when questionID typecast issue is resolved
    );
    
    return Boolean(section?.questions.reduce<boolean>((isValidRow, question) => {
      const isValidQuestion = validateQuestion({
        updatedQuestion: question,
        updatedValue: recordValueMap.get(String(question.id)) || '', // TODO: fix when questionID typecast issue is resolved
        updatedDocumentNames: documentNames,
        sectionId: sectionId,
        recordId: recordId,
        isTableQuestion: true,
        isInitialScan: isInitialScan,
      });
      return isValidRow && isValidQuestion;
    }, true));
  };

  const validateQuestionnaire = ({ isInitialScan }: QuestionnaireValidationParams) => {
    if (isReview) return false;     
    let hasErrorsQuestionnaire = false;
    if (workingQuestionnaire) {
      hasErrorsQuestionnaire = workingQuestionnaire.sections.reduce<boolean>((hasErrorsQuestionnaire, section) => {
        const hasErrorsSection = validateSection({ section, isInitialScan });
        return hasErrorsQuestionnaire || hasErrorsSection;
      }, false);
      
    }
    return hasErrorsQuestionnaire;
  };

  const openTableModal = () => {
    setTableModalIsOpen(true);
  };

  const closeTableModal = ({ sectionId, recordId }: TableModalCloseParams) => {
    clearTableRowErrors(sectionId, recordId);
    setModalQuestionnaire(workingQuestionnaire);
    setHasQuestionnaireChanged(false);
    setTableModalIsOpen(false);
  };

  const saveTableModal = async ({ sectionId, recordId }: TableModalSaveParams) => {
    const isValid = validateTableRow({ sectionId, recordId, isInitialScan: false });
    if (isValid) {
      setWorkingQuestionnaire(modalQuestionnaire);
      setHasQuestionnaireChanged(false);
      setTableModalIsOpen(false);
    }
  };

  const onClickClientSaveClose = () => {
    const oldStatus: IntStatus = selectedQuestionnaire.intStatus;
    const newStatus: IntStatus = (oldStatus === IntStatus.GetStarted) ? IntStatus.Active : oldStatus;

    if (oldStatus === IntStatus.PendingReview || oldStatus === IntStatus.Approved) {
      closeQuestionnaire();
      return;
    }
    setSubmitMessage("Saving");
    onSubmit(newStatus, workingQuestionnaire);
  };

  const onClickClientSubmit = () => {
    const oldStatus: IntStatus = selectedQuestionnaire.intStatus;

    if (oldStatus === IntStatus.PendingReview) {
      setSubmitError("Cannot re-submit a questionnaire pending review");
      return;
    }
    if (oldStatus === IntStatus.Approved) {
      setSubmitError("Cannot re-submit an approved questionnaire");
      return;
    }
    setSubmitMessage("Your questionnaire is being submitted");
    onSubmit(IntStatus.PendingReview, workingQuestionnaire);
  };

  const onClickReviewerSaveClose = () => {
    const oldStatus: IntStatus = selectedQuestionnaire.intStatus;

    if (oldStatus === IntStatus.Approved) {
      closeQuestionnaire();
      return;
    }
    setSubmitMessage("Saving");
    onSubmit(oldStatus, workingQuestionnaire);
  };
  
  const onClickReviewerSubmit = () => {
    const oldStatus: IntStatus = selectedQuestionnaire.intStatus;

    if (oldStatus === IntStatus.Approved) {
      setSubmitError("Cannot re-submit an approved questionnaire");
      return;
    }

    const isRejected = workingQuestionnaire?.sections.some(section => 
      section.accepted === false || 
      section.questions.some(question => question.accepted === false)
    );
    const intStatus = isRejected ? IntStatus.Rejected : IntStatus.Approved;
    
    if (!workingQuestionnaire) return;
    const parsedQuestionnaire = setAcceptanceOnReviewerSubmit(workingQuestionnaire, intStatus);

    setSubmitMessage("Your review is being submitted");
    onSubmit(intStatus, parsedQuestionnaire);
  };
  
  const onSubmit = async (reviewStatus: IntStatus, questionnaire: QuestionnaireInfo | null) => {
    if (!questionnaire) {
      setSubmitError("No questionnaire data to save.");
      return;
    }
    if (!isReview && reviewStatus === IntStatus.PendingReview) {
      const hasErrors = validateQuestionnaire({ isInitialScan: false });
      
      const hasAcceptanceErrors = (
        selectedQuestionnaire.intStatus !== IntStatus.PendingReview && 
        questionnaire.sections.some(section => section.accepted === false)
      );
      
      if (hasErrors || hasAcceptanceErrors) {
        return;
      }
    }

    setIsSubmitting(true);
    setHideQuestionnaireModalHeader(true);

    const base64Files = await Promise.all(
      fileStorage.map(async fileStorageItem => {
        const base64String = await fileToBase64(fileStorageItem.file);
        const base64Data = base64String.split(',')[1] || base64String;
        return {
          fileName: fileStorageItem.file.name,
          base64Data: base64Data,
          questionId: fileStorageItem.questionId
        };
      })
    );

    const questionnaireData = {
      contId: selectedQuestionnaire.contID,
      fileId: selectedQuestionnaire.fileID,
      questionnaireId: Number(selectedQuestionnaire.questionaireID),
      intStatus: reviewStatus,
      questionnaireInfo: parseOutgoingQuestionnaire(questionnaire),
      files: base64Files
    };

    try {
      await saveQuestionnaireInfo(questionnaireData);
      setSubmitError(null);
    } catch (error) {
      setSubmitError('Error saving questionnaire. Please try again.'); 
    } finally {
      mutateQuestionnaires();
      closeQuestionnaire();
      setIsSubmitting(false);
      setHideQuestionnaireModalHeader(false);
    }
  };

  const updateGridQuestion = ({ sectionId, updatedQuestion, updatedDocumentNames = documentNames }: UpdateGridQuestionParams) => {
    const fieldId = getFieldId({ label: updatedQuestion.label, isTableQuestion: false });
    if (errors[fieldId]?.message === "Missing/Incorrect data; please review & complete.") {
      clearErrors(fieldId);
    }
    validateQuestion({
      updatedQuestion: updatedQuestion, 
      updatedValue: updatedQuestion.value,
      updatedDocumentNames: updatedDocumentNames,
      sectionId: sectionId,
      isTableQuestion: false,
      isInitialScan: false
    });
    setWorkingQuestionnaire(currentData => {
      if (!currentData) return null;
  
      const updatedSections = currentData.sections.map(section => {
        if (section.sectionID === sectionId) {
          const updatedQuestions = section.questions.map(question => 
            question.id === updatedQuestion.id ? updatedQuestion : question
          );
          return { ...section, questions: updatedQuestions };
        }
        return section;
      });
  
      setHasQuestionnaireChanged(true);
      return { ...currentData, sections: updatedSections };
    });
  };

  const updateTableQuestion = ({ questionnaireQuestion, sectionId, recordId, newValue }: UpdateTableQuestionParams) => {
    setModalQuestionnaire(currentData => {
      if (!currentData) return null;
      return {
        ...currentData,
        sections: currentData?.sections.map(section => {
          if (section.sectionID === sectionId) {
            return {
              ...section,
              accepted: null,
              records: section.records?.map(record => {
                if (record.recordID === recordId) {
                  return {
                    ...record,
                    questions: record.questions?.map(recordQuestion => {
                      if (String(recordQuestion.questionID) === questionnaireQuestion.id) { // TODO: fix when questionID typecast issue is resolved
                        validateQuestion({
                          updatedQuestion: questionnaireQuestion,
                          updatedValue: newValue,
                          updatedDocumentNames: documentNames,
                          sectionId: sectionId,
                          recordId: recordId,
                          isTableQuestion: true,
                          isInitialScan: false,
                        });
                        return {
                          ...recordQuestion,
                          value: newValue,
                        };
                      } else {
                        return recordQuestion;
                      }
                    })
                  };
                } else {
                  return record;
                }
              })
            };
          } else {
            return section;
          }
        }) || [],
      };
    });
  };

  const updateSection = (updatedSection: Section) => {
    setWorkingQuestionnaire(currentData => {
      if (!currentData) return null;
      
      const updatedSections = currentData.sections.map(section => 
        (section.sectionID === updatedSection.sectionID) ? updatedSection : section
      );
      return { ...currentData, sections: updatedSections };
    });
    setHasQuestionnaireChanged(true);
  };

  const addTableRow = ({ sectionId }: AddTableRowParams) => {
    const section = workingQuestionnaire?.sections.find(section => section.sectionID === sectionId);
    if (!section || isLockedTable(section.sectionID)) return;

    const newRecordQuestions: QuestionTableRecord[] = section.questions.map(question => ({
      questionID: Number(question.id), // TODO: fix when questionID typecast issue is resolved
      value: '',
      locked: true,
      isVisible: question.bit_isVisibleInTable || false,
    }));

    let highestID = 0;

    if (section.records) {
      section.records.forEach(record => {
        if (record.recordID > highestID) {
          highestID = record.recordID;
        }
      });
    }
  
    const newRecord: TableQuestionRecord = {
      recordID: highestID + 1,
      internalRecordID: null,
      questions: newRecordQuestions
    };

    const updatedRecords = section.records ? [...section.records, newRecord] : [newRecord];
    updateSection({ 
      ...section, 
      records: updatedRecords, 
      accepted: null, 
    });
  };

  const deleteTableRow = ({ sectionId, recordId }: DeleteTableRowParams) => {
    const section = workingQuestionnaire?.sections.find(section => section.sectionID === sectionId);
    if (!section || isLockedTable(section.sectionID)) return;

    if (section.records) {
      // clear errors on old record
      const recordToDelete = section.records.find(record => record.recordID !== recordId);
      if (recordToDelete) {
        const labelMap = new Map(section.questions.map(q => [q.id, q.label]));
        clearErrors(recordToDelete.questions?.map(q => {
          return getFieldId({ 
            label: labelMap.get(String(q.questionID)) || "", // TODO: fix when questionID typecast issue is resolved
            recordId: recordId, 
            isTableQuestion: true 
          });
        }));
      }

      // remove records from workingQuestionnaire
      const updatedRecords = section.records.filter(record => record.recordID !== recordId);
      updateSection({ 
        ...section, 
        records: updatedRecords, 
        accepted: null,
      });
    }
  };

  const setSectionAcceptance = ({ sectionId, accepted }: SectionAcceptanceParams) => {
    const section = workingQuestionnaire?.sections.find(section => section.sectionID === sectionId);
    if (!section) return;

    updateSection({
      ...section,
      accepted: accepted,
    });
  };

  const findQuestion = (fieldId: string) => {
    let question = {} as QuestionDataType;
    let sectionId = -1;
    workingQuestionnaire?.sections.forEach(section => {
      const findResult = section.questions.find(q => fieldId === getFieldId({ label: q.label, isTableQuestion: false }));
      if (findResult) {
        question = findResult;
        sectionId = section.sectionID;
      }
    });
    return {question, sectionId};
  };

  const addDocuments = ({ files, fieldId }: DocumentAdditionParams) => {
    const { supportedFiles, error } = parseFileExtensions(files, AllowedExtensions);
    const newDocumentNames = supportedFiles.map(file => {
      return {
        name: file.name,
        fieldId: fieldId, 
        permanent: false 
      } as DocumentName;
    });
    const updatedDocumentNames = {
      ...documentNames,
      localDocuments: [...documentNames.localDocuments, ...newDocumentNames],
    };
    const { question, sectionId } = findQuestion(fieldId);

    setDocumentNames(updatedDocumentNames);

    setFileStorage(currentData => {
      const newFileStorageData: FileStorage[] = supportedFiles.map(file => {
        return {
          file,
          id: file.name,
          questionId: question.id,
        };
      });
      return [...currentData, ...newFileStorageData];
    });

    const updatedQuestion = {...question, accepted: null};
    updateGridQuestion({ sectionId, updatedQuestion, updatedDocumentNames });
    
    if (error) {
      setError(fieldId, error);
    }
  };

  const deleteDocument = ({ name, fieldId }: DocumentDeletionParams) => {
    const isDeletedDocument = (doc: DocumentName) => {
      return doc.name === name && doc.fieldId == fieldId;
    };

    const isLocalDocument = documentNames.localDocuments.some(isDeletedDocument);
    const isUploadedDocument = documentNames.uploadedDocuments.some(isDeletedDocument);

    const {question, sectionId} = findQuestion(fieldId);

    let updatedQuestion = question;
    let updatedDocumentNames = {} as DocumentNames;
    if (isLocalDocument) {
      const doc = documentNames.localDocuments.find(isDeletedDocument);

      updatedDocumentNames = {
        uploadedDocuments: documentNames.uploadedDocuments,
        localDocuments: documentNames.localDocuments.filter(doc => !isDeletedDocument(doc)),
      };
      setFileStorage(fileStorage.filter(fs => fs.questionId !== question.id || fs.id !== doc?.name));

    } else if (isUploadedDocument) {
      const doc = documentNames.uploadedDocuments.find(isDeletedDocument);
      if (doc?.permanent) return;

      updatedDocumentNames = {
        uploadedDocuments: documentNames.uploadedDocuments.filter(doc => !isDeletedDocument(doc)),
        localDocuments: documentNames.localDocuments,
      };
      updatedQuestion = {
        ...question, 
        accepted: null,
        documents: question.documents?.filter(path => path !== doc?.path)
      };
    }
    updateGridQuestion({ sectionId, updatedQuestion, updatedDocumentNames });
    setDocumentNames(updatedDocumentNames);
  };

  return { 
    parsedQuestionnaire,
    workingQuestionnaire,
    questionnaireJSONError,
    submitError,
    register,
    setValue,
    handleSubmit,
    updateGridQuestion,
    updateTableQuestion,
    errors,
    intStatus,
    isLockedQuestionnaire,
    isLockedTable,
    onClickClientSaveClose,
    onClickClientSubmit,
    onClickReviewerSaveClose,
    onClickReviewerSubmit, 
    tableModalIsOpen,
    openTableModal,
    closeTableModal,
    saveTableModal,
    addTableRow,
    deleteTableRow,
    setSectionAcceptance,
    isLoadingDocuments,
    isSubmitting,
    submitMessage,
    documentNames,
    addDocuments,
    deleteDocument,
    shouldRenderTableQuestion,
  };
};
