import style from "./dashboardTableRow.module.scss";
import FolderIcon from "components/icons/folder";
import ViewIcon from "components/icons/view";

interface IDashboardTableRow {
  number: number;
  company: string;
  responsibleLawyer: string;
  cases: number;
  onClick?: () => void;
}

export const DashboardTableRow = ({
  number,
  company,
  responsibleLawyer,
  cases,
  onClick = () => undefined,
}: IDashboardTableRow) => {
  return (
    <>
      <tr className={style.barRow}>
        <td colSpan={6} />
      </tr>
      <tr onClick={onClick} className={style.selectableRow}>
        <td>
          <div className={style.bubble}>{number ? number : "n/a"}</div>
        </td>
        <td>
          <div className={style.companyName}>
            <FolderIcon />
            {company ? company : "n/a"}
          </div>
        </td>
        <td>
          <div className={style.lawyer}>
            {responsibleLawyer ? responsibleLawyer : "n/a"}
          </div>
        </td>
        <td>
          <div className={style.cases}>{cases ? cases : "n/a"}</div>
        </td>
      </tr>
    </>
  );
};
