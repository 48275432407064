import { ChangeEvent, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { UseFormRegister, FieldErrors } from 'react-hook-form';
import styles from './fileUpload.module.scss';
import RemoveIcon from 'components/icons/removeIcon';
import { parseDocName, parseHTML } from 'utils/commonFunctions';
import { DocumentName, DocumentNames } from 'actions/documentActions';

export enum FileUploadStyle {
  Primary = "primary",
  BlueBubble = "blueBubble",
}

interface FileUploadProps {
  id: string;
  label: string;
  subLabel?: string;
  className?: string;
  subLabelClass?: string;
  documentNames?: DocumentNames;
  register?: UseFormRegister<any>;
  locked?: boolean;
  inputClass?: string;
  valueColorClass?: string;
  placeholderColorClass?: string;
  errors?: FieldErrors<any>;
  addDocuments?: (params: DocumentAdditionParams) => void;
  deleteDocument?: (params: DocumentDeletionParams) => void;
  style?: string;
  accepted?: boolean;
  showErrorStyle?: boolean;
  onClickDocument?: (documentName: string, documentPath: string) => void;
}

export interface DocumentAdditionParams {
  files: File[];
  fieldId: string;
}

export interface DocumentDeletionParams {
  name: string;
  fieldId: string;
}

const FileUpload = ({
  id,
  label,
  register,
  documentNames = { localDocuments: [], uploadedDocuments: [] }, 
  locked,
  inputClass,
  subLabel = '',
  className,
  subLabelClass,
  valueColorClass = styles.valueColorClass,
  placeholderColorClass = styles.placeholderColorClass,
  errors,
  addDocuments,
  deleteDocument,
  style = FileUploadStyle.Primary,
  accepted,
  showErrorStyle,
  onClickDocument,
}: FileUploadProps) => {
  
  const hasErrors = showErrorStyle || (errors && errors[id] || accepted === false);

  const labelColorClass = useMemo(() => {
    if (
      documentNames.localDocuments.some(doc => doc.fieldId === id) ||
      documentNames.uploadedDocuments.some(doc => doc.fieldId === id)
    ) {
      return valueColorClass;
    }
    return placeholderColorClass;
  }, [documentNames]);

  useEffect(() => {
    if (register && id) {
      register(id);
    }
  }, [register, id]);

  // allows user to add a document, delete it, and then upload it again (otherwise, onChange doesn't get called)
  const onClick = (event: any) => {
    event.target.value = null;
  };

  const handleAddDocument = (event: ChangeEvent<HTMLInputElement>) => {
    const fileInput = event.target;
    const newFiles = Array.from(fileInput.files || []).map(file => {
      return new File([file], parseDocName(file.name), { type: file.type });
    });

    // filter duplicates within new files
    const visitedFileNames = new Set();
    const nonInternalDuplicateFiles = newFiles.filter(file => {
      if (visitedFileNames.has(file.name)) {
        return false;
      }
      visitedFileNames.add(file.name);
      return true;
    });

    // filter duplicates between new & existing files
    const existingDocNames = new Set([
      ...documentNames.localDocuments.map(doc => doc.name),
      ...documentNames.uploadedDocuments.map(doc => doc.name),
    ]);
    const nonDuplicateFiles = nonInternalDuplicateFiles.filter(file => !existingDocNames.has(file.name));

    if (addDocuments && nonDuplicateFiles.length > 0) {
      addDocuments({ files: nonDuplicateFiles, fieldId: id });
    }
  };

  const handleDeleteDocument = (doc: DocumentName) => {
    deleteDocument && deleteDocument({ name: doc.name, fieldId: id });
  };
  
  return (
    <div className={classNames(styles.fileUploadContainer, inputClass)}>
      <label 
        htmlFor={id} 
        className={classNames(styles.customFileUpload, className, labelColorClass, style && styles[style], locked && styles.locked, hasErrors && styles.errorBorder)}
      >{parseHTML(label)}</label>
      <input
        type="file"
        id={id}
        className={styles.hiddenFileInput}
        onClick={onClick}
        onChange={handleAddDocument}
        multiple
        disabled={locked}
      />
      <div className={styles.fileNameContainer}>
        {documentNames.uploadedDocuments.filter(doc => doc.fieldId === id).map((doc, index) => (
          <div 
            key={doc.fieldId || index} 
            className={classNames(styles.fileName, styles.clickableFileName)} 
            onClick={() => onClickDocument?.(doc.name, doc.path || "")}
          >
            {doc.name}{' '}
            {doc.permanent || locked ||
              <div className={styles.removeFileButton} onClick={(event) => {event.stopPropagation(); handleDeleteDocument(doc);}}>
                <RemoveIcon />
              </div> 
            }
          </div>
        ))}
        {documentNames.localDocuments.filter(doc => doc.fieldId === id).map((doc, index) => (
          <div key={index} className={styles.fileName}>
            {doc.name}{' '}
            {locked ||
              <div className={styles.removeFileButton} onClick={() => handleDeleteDocument(doc)}>
                <RemoveIcon />
              </div>
            }
          </div>
        ))}
      </div>
      {errors && errors[id] && (
        <div className={styles.fileError}>
          <span className={styles.alert}>{errors[id]?.message}</span>
        </div>
      )}

      {(subLabel && subLabel.length > 0) ? <p className={subLabelClass}>{parseHTML(subLabel)}</p> : null}
    </div>
  );
};

export default FileUpload;
