import { useEffect, useState } from "react";
import styles from "./navbar.module.scss";
import { SearchInput } from "../UI/searchInput/searchInput";
import HamburgerIcon from "components/icons/hamburgerMenu";
import { InterfaceNavLink } from "components/sidebar/navLink/navLink";
import { useHistory, useLocation } from "react-router-dom";
import { useSetAppState } from "context";
import { logoutUser } from "actions/userActions";
import HelpButtonBlue from "components/icons/helpButtonBlue";
import NotificationButtonBlue from "components/icons/notificationButtonBlue";
import NotificationButtonBlueRed from "components/icons/notificationButtonBlueRed";
import SettingsButtonBlue from "components/icons/settingsButtonBlue";
import Sidebar from "components/sidebar/sidebar";
import LogoutButtonIcon from "components/icons/logoutIcon";
import { Modal } from "components/modal/modal";
import { isUserClient } from "utils/userFunctions";
import SettingsModalCheckmark from "../icons/settingsModalCheckmark";
import { RoutePaths } from "../../app/routing/routing";
import { useGetNotificationsInfo, userNotification } from "actions/notificationsActions";
import classNames from "classnames";
import { NotificationRow, notificationRowClassTypes } from "components/notificationRow/notificationRow";
import { useOutsideClick } from "hooks/useOutsideClick";

export interface NotificationTableRow {
  notificationInfo: userNotification;
}

interface LocationState {
  searchQuery: string;
}

const Navbar = () => {
  const { pathname } = useLocation();
  const setAppState = useSetAppState();
  const [sideBarOpen, setSideBarOpen] = useState<boolean>(false);
  const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false);
  const [showNotificationsList, setShowNotificationsList] = useState<boolean>(false);
  const { data: notificationsData, error: notificationsError } = useGetNotificationsInfo();
  const [notifications, setNotifications] = useState<NotificationTableRow[]>([]);
  const history = useHistory();
  const location = useLocation();
  const { state } = location || {};
  const { searchQuery } = state as LocationState || {};
  const notificationsRead = notificationsData?.some(notification => !notification.bitRead);
  const { ref: notificationRef } = useOutsideClick(() => setShowNotificationsList(false));
  
  useEffect(() => {
    setNotifications(notificationsData?.map((notification) => { 
      return { notificationInfo: notification, showInTable: true }; 
    }) || [])
  }, [notificationsData]);
  const toggleSidebar = () => {
    setSideBarOpen(!sideBarOpen);
  };

  const untoggleSidebar = () => {
    setSideBarOpen(false);
  };

  const handleLogout = () => {
    logoutUser();
    setAppState({ Authenticated: false, Authenticating: false });
  };

  const showAllLink = () => {
    history.push(RoutePaths.Notifications);
  };  

  const searchBarChange = (query: string) => {
    history.push({
      pathname: RoutePaths.Search,
      state: {
        searchQuery: query,
      }
    });
  }; 

  const handleRemoveNotification = (index: number) => {
    setNotifications(prevNotifications => 
      prevNotifications.filter((_, i) => i !== index)
    );
  };

  const handleSettingsClick = () => {
    setShowSettingsModal(true);
  };

  const handleCloseModal = () => {
    setShowSettingsModal(false);
  };

  const isCurrentPath = (path: string) => {
    return path === pathname;
  };

  return (
    <div className={styles.header}>
      <div id="mobileIcon" className={styles.mobileDesktopIcon} onClick={toggleSidebar}>
        <HamburgerIcon className={styles.hamburgerIcon}/>
      </div>
      <div className={styles.container} onClick={untoggleSidebar}>

        <div className={styles.sidebar} style={{ display: sideBarOpen ? "block" : "none" }}>
          <Sidebar />        
        </div>

        {isUserClient() && (
          <>
            <div className={styles.searchContainer}>
              <SearchInput 
                placeholder="Search" 
                debounceTime={1000}
                startingValue={searchQuery}
                className={styles.searchBar} 
                onChange={(query: string) => {searchBarChange(query)}}
              ></SearchInput>
            </div>
            <div className={styles.notification} onClick={() => setShowNotificationsList(!showNotificationsList)} ref={notificationRef}>
              {notificationsData && notificationsRead ? <NotificationButtonBlueRed/> : <NotificationButtonBlue/>}
              { showNotificationsList && 
                <div className={styles.notificationList}> 
                  {notifications.slice(0, 5).map((notification, index: number) => {
                    return (
                      <div key={index} className={styles.row}>
                        <NotificationRow id={`notification-table-${index}`} notification={notification.notificationInfo} notificationRowClass={notificationRowClassTypes.Dropdown}/>
                        <div 
                          className={classNames(styles.removeButton, notification.notificationInfo.bitRead ? styles.removeButtonRead : styles.removeButton)} 
                          onClick={(event) => {
                            event.stopPropagation();
                            handleRemoveNotification(index);
                          }}
                        >
                          X
                        </div>
                      </div>
                    );
                  })}
                  <div className={styles.showAllNotifications} onClick={() => showAllLink()}>SHOW ALL NOTIFICATIONS</div>
                </div>
              }

            </div>
                
            <div className={styles.desktopIcon}>
              <InterfaceNavLink 
                id={"settingsButton"} 
                className={styles.mobileRemoval} 
                to={RoutePaths.Settings} 
                activeLinks={[]} 
                onClick={handleSettingsClick} 
                icon={<SettingsButtonBlue/>} 
              />
            </div>
            
            <div className={styles.mobileDesktopIcon}>
              <SettingsButtonBlue onClick={handleSettingsClick}/>
            </div>
                
            <div className={styles.desktopIcon}>
              <InterfaceNavLink 
                className={styles.mobileRemoval} 
                to={RoutePaths.Help} 
                activeLinks={[]} 
                icon={<HelpButtonBlue/>} 
              />
            </div>
          </>
        )}


        <div id={'logoutButton'} className={classNames(styles.rightMostDesktopIcon, isUserClient() && styles.mobileRemoval)}>
          <InterfaceNavLink className={styles.mobileRemoval} to={RoutePaths.Login} onClick={handleLogout} activeLinks={[]} icon={<LogoutButtonIcon/>} />
        </div>
        <div className={styles.coverModal}>
          <Modal id='navBar' title="" hideModalHeader={true} show={showSettingsModal} onClose={handleCloseModal} className={styles.settingModal} lockModal={false}>
            <div className={styles.modalContent}>
              <InterfaceNavLink 
                id={"settingsLink"}
                to={RoutePaths.Settings} 
                className={styles.contentRow} 
                icon={isCurrentPath(RoutePaths.Settings) ? <SettingsModalCheckmark/> : null} 
                activeLinks={[]} 
                onClick={handleSettingsClick}
              >
                <div className={styles.labelContainer}>
                  <div className={styles.label}>Settings</div>  
                </div>
              </InterfaceNavLink>
              
              <InterfaceNavLink 
                id={"helpLink"}
                to={RoutePaths.Help}
                className={styles.contentRow} 
                icon={isCurrentPath(RoutePaths.Help) ? <SettingsModalCheckmark/> : null}
                activeLinks={[]}
              >
                <div className={styles.labelContainer}>
                  <div className={styles.label}>Help</div>  
                </div>
              </InterfaceNavLink>

              <InterfaceNavLink 
                id={'settingsLogoutButton'}
                to={RoutePaths.Login} 
                className={styles.contentRow} 
                onClick={handleLogout} 
                activeLinks={[]}
              >
                <div className={styles.labelContainer}>
                  <div className={styles.label}>Sign Out</div>  
                </div>
              </InterfaceNavLink>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
