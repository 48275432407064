import style from './loginGraphicContainer.module.scss';

export interface LoginGraphicProps {
  Graphic: React.ComponentType<{className?: string}>;
}

export const LoginGraphicContainer = ({ Graphic }: LoginGraphicProps) => {
  return (
    <div className={style.container}>
      <Graphic />
    </div>
  );
};