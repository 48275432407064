import BellIcon from "components/icons/bell";
import GreyBellIcon from "components/icons/bellGrey";
import style from "./notificationRow.module.scss";
import { userNotification } from "actions/notificationsActions";
import { parseHTML, timeSince } from "utils/commonFunctions";
import { markNotificationAsRead } from "actions/notificationsActions";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { RoutePaths } from "app/routing/routing";

export enum notificationRowClassTypes {
  Table = 'table',
  Dropdown = 'dropdown',
}

enum searchBarRowTypes {
  File = 'FILE',
  Matter = 'MATTER',
  Client = 'CLIENT',
}

interface notificationRowProps {
  id: string;
  notification: userNotification;
  notificationRowClass: notificationRowClassTypes;
}

export const NotificationRow = ({ id, notification, notificationRowClass }: notificationRowProps) => {  
  const history = useHistory();
  // we need to update the titles of each page
  const clickedNotification = async () => {
    try {
      await markNotificationAsRead(notification.recordId);
    }catch(e){
      console.error(e);
    }
    if(notification.objectType == searchBarRowTypes.Client) {
      history.push({
        pathname: RoutePaths.Company,
        state: {
          clientID: notification.id,
          clientName: notification.title,
          clientNumber: notification.clientNumber,
        },
      });    
    } else if(notification.objectType == searchBarRowTypes.Matter || notification.objectType == searchBarRowTypes.File) {
      history.push({
        pathname: RoutePaths.Application,
        state: {
          fileId: notification.id,
          description: notification.title,
          clientNumber: notification.clientNumber,

        }
      });
    }
  }

  return (
    <div id={id} onClick={() => clickedNotification()} className={classNames(
      style.notificationRow, 
      notification.bitRead ? style.readNotification : style.unreadNotification,
      style[notificationRowClass]
    )}>
      <div className={style.notificationRowIcon}>
        { notification.bitRead ? <GreyBellIcon/> : <BellIcon/>}
      </div>
      <div className={style.notificationRowContent}>
        <div className={style.notificationTextDetails}>
          {parseHTML(notification.textDetails)}
        </div>
        <div className={style.notificationDate}>
          {timeSince(notification.dateCreated)} ago
        </div>
      </div>
    </div>
  );
};
