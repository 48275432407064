import React, {useState} from 'react';
import styles from './settings.module.scss';
import Layout from 'components/layout/Layout';
import { Tab } from 'components/UI/tab/tab';
import {ResetPasswordForm} from "../../components/resetPasswordForm/resetPasswordForm";
import Person from 'components/icons/person';
import Alert from 'components/icons/alert';
import { UpdateUserForm } from 'components/updateUserForm/updateUserForm';


export const Settings = () => {
  const [activeTab, setActiveTab] = useState('Account Details'); // Set the default active tab

  const handleTabClick = (label: string) => {
    setActiveTab(label);
  };

  return (
    <Layout>
      <div className={styles.settings}>
        <h2>Settings</h2>
      </div>
      <div className={styles.tabs}>
        <Tab
          id="accountDetailsTab"
          label='Account Details'
          icon={<Person/>}
          isActive={activeTab === 'Account Details'}
          onClick={() => handleTabClick('Account Details')}
        />
        <Tab
          id="resetPasswordTab"
          label='Reset Password'
          icon={<Alert/>}
          isActive={activeTab === 'Reset Password'}
          onClick={() => handleTabClick('Reset Password')}
        />
      </div>
      <div className={styles.settingsContentWrapper}>

        {activeTab === 'Account Details' && (
          <UpdateUserForm/>
        )}

        {activeTab === 'Reset Password' && (
          <div className={styles.resetPassword}>
            <ResetPasswordForm/>
          </div>
        )}
      </div>
    </Layout>
  );
};