import { FC, useState } from 'react';
import styles from './passwordComponent.module.scss';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import HideIcon from 'components/icons/hide';
import ShowIcon from 'components/icons/show';
import TextField from 'components/UI/textField/textField';

interface PasswordFieldProps {
  name: string;
  register: UseFormRegister<any>;
  errors? : FieldErrors<any>;
  validationSchema?: any;
  label?: string;
  placeholder?: string;
  className?: string;
  startingValue?: string;
  onChange?: (value: string) => void;
}

export const PasswordComponent  = ({name, label, placeholder, className, register, errors, validationSchema, startingValue, onChange } : PasswordFieldProps) => {
  const [showingPassword, setShowingPassword] = useState(false);

  const onShowHidePassword = () => {
    setShowingPassword(!showingPassword);
  };

  const handleChange = (value: string) => {
    if(onChange){
      onChange(value);
    }
  };

  return (
    <div className={styles.passwordContainer}>
      <TextField
        id={ name }
        register={register}
        type={showingPassword ? 'text' : 'password'}
        placeholder={ placeholder }
        label= { label }
        className={className}
        errors={errors}
        validationSchema={validationSchema}
        onChange={handleChange}
      />
      {showingPassword ? <ShowIcon onClick={onShowHidePassword} /> : <HideIcon onClick={onShowHidePassword} />}
    </div>
  );
};

export default PasswordComponent;