import { useEffect, useState } from "react";
import { useGetNotificationsInfo } from "actions/notificationsActions";
import style from "./notifications.module.scss";
import Layout from "components/layout/Layout";
import { NotificationRow, notificationRowClassTypes } from "components/notificationRow/notificationRow";
import { NotificationTableRow } from "components/navbar/navbar";
import NoDataMessage from "components/noDataBlock/noDataMessage";


export const Notifications = () => {
  const { data: notificationsData, error: notificationsError } = useGetNotificationsInfo();
  const [notifications, setNotifications] = useState<NotificationTableRow[]>([]);

  useEffect(() => {
    setNotifications(
      notificationsData?.map((notification) => {
        return { notificationInfo: notification};
      }) || []
    );
  }, [notificationsData]);

  return (
    <Layout>
      <div className={style.notificationPage}>
        <h3 className={style.notificationTitle}>Notifications Feed</h3>
        <div className={style.notificationTableWrapper}>
          <div className={style.notificationTable}>
            {notifications?.map((notification, index) => (
              <div key={index} className={style.row}>
                <NotificationRow
                  id={`notification-page-${index}`}
                  notification={notification.notificationInfo} 
                  notificationRowClass={notificationRowClassTypes.Table}
                />
              </div>
            ))}
            {notificationsError || notifications.length == 0 &&
              <NoDataMessage
                title="No Notifications Found"
                description= {notificationsError ? notificationsError.message : ''}
              />
            }
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Notifications;
