// Matches phone numbers with optional international prefix, 
// allowing spaces, dots, or hyphens as separators. Examples: +123 456-7890, (123) 456-7890
export const validPhoneNumberRegex = /^(?:\+\d{1,3})?[\s.-]?\(?\d{2,3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

// Matches both Canadian postal codes and US ZIP codes. 
// Canadian format: A1A 1A1 (with a space or no space). US format: 12345 or 12345-1234
export const validZipCodePostalCodeRegex = /^(?:[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d|\d{5}(?:[-\s]\d{4})?)$/;

//Matches email addresses with alphanumeric characters, periods, hyphens, and underscores.
// Must contain an @ symbol and a domain name. Example: test_1@gmail.com
export const validEmailRegex = /^[A-Za-z0-9._-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

// Matches unit numbers consisting of alphanumeric characters and hyphens. Example: 12B, 100-1
export const validUnitNumberRegex = /^[A-Za-z0-9-]+$/;

// Matches street addresses, allowing alphanumeric characters, spaces, commas, periods, and hyphens. 
// Minimum of 3 characters required. Example: 123 Main St., Apt. 4
export const validStreetNumberRegex = /^[A-Za-z0-9\s.,'-]{3,}$/;

// Matches names, allowing alphabetic characters and spaces or hyphens between names. 
// Example: John Doe, Anne-Marie
export const validNameRegex = /^[A-Za-z]+(?:[ -][A-Za-z]+)*$/;

// Matches city or town names, allowing alphabetic characters, spaces, periods, apostrophes, and hyphens. 
// Example: St. John's, San Francisco
export const validTownCityRegex = /^[A-Za-z\s.'-]+$/;

// Example: Sat, Jan 13, 2024, EST 00:00:00 Eastern Standard Time
export const explicitDateRegex = /(\w+),\s+(\w+)\s+(\d+),\s+(\d+),\s+(\w+)\s+(\d+:\d+:\d+)\s+(.+)/;

// Example: 2024-01-24T14:18:15.620Z
export const noTimeZoneDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;

// Examplee: 2024-12-20 (YYYY-MM-DD)
export const YYYY_MM_DD_DateRegex = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;

// Example: !@#$%^&*()
export const nonAlphaNumericRegex = /[^a-zA-Z0-9]/;

// Example: file'name*&.png
export const invalidDocNameCharacterRegex = /[^\w\-. ]/;

// Has at least 1 number
export const hasDigit = /^(?=.*[0-9]).+$/;

// Has at least 1 special character
export const hasSpecialCharacter = /^(?=.*[!@#$%^&*]).+$/;

// Has at least 1 uppercase letter
export const hasUpperCase = /^(?=.*[A-Z]).+$/;

// Regex to ensure only allowed characters are used
export const allowedCharactersRegex = /^[a-zA-Z0-9!@#$%^&*]+$/; 

// Example: tableQuestion-label-1
export const tableQuestionFieldIdRegex = /^tableQuestion-[a-zA-Z0-9-]*-\d+$/;

// Example: 1984-4-21
export const dateFormatRegex = /^[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}$/g;

export const dateLengthRegex = /^[0-9-]{0,10}$/g;

export const passwordValidationRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Z])(?=\S+$).{8,50}$/;