import * as base from "./lib/baseActions";
import { apiEndpoints } from "config/config";
import { useStickySWR } from "hooks/useStickySWR";
import useSWRInfinite from "swr/infinite";

export interface CompanyFilters {
  searchWord?: string;
  orderBy?: string;
  sortOrder?: string;
  rowNumber: number;
  pageNumber?: number;
}

export interface Company {
  clId: string;
  clName: string;
  cdDesc: string;
  clNo: number;
  responsibleLawyer: string;
  liveMatters: number;
}

const getCompanies = async (params: CompanyFilters): Promise<Company[]> => {
  const result = await base.get(apiEndpoints.company, { params });
  return result.data;
};

const useGetCompanies = (params: CompanyFilters) => {
  const getKey = (pageIndex: number, previousPageData: Company[] | null) => {
    if (previousPageData && previousPageData.length === 0) return null; // reached the end
    return [apiEndpoints.company, { ...params, pageNumber: pageIndex + 1 }];
  };

  const fetcher = async ([url, params]: [string, CompanyFilters]) => {
    const response = await getCompanies(params);
    return response;
  };

  const { data, error, mutate, size, setSize, isValidating } = useSWRInfinite(getKey, fetcher, {
    revalidateFirstPage: false,
    keepPreviousData: false,
  });

  const companiesData = data ? data.filter(company => company !== null).flat() : [];
  const hasMore = data && data[data.length - 1]?.length === params.rowNumber;
  return {
    data: companiesData,
    error,
    mutate,
    isValidating,
    size,
    setSize,
    hasMore,
    noDataFound: companiesData && companiesData.length === 0,
  };
};

export {
  useGetCompanies
};