import { ReactNode, useState, useEffect } from "react";
import classNames from "classnames";
import styles from "./multilineInput.module.scss";
import { UseFormRegister, FieldErrors } from 'react-hook-form';


export interface ValidationSchema {
  required?: string;
  pattern?: {
    value: any;
    message: string;
  },
  minLength?: {
    value: number,
    message: string
  },
}

export interface MultilineInputProps {
  id: string;
  containerClass?: string;
  labelClass?: string;
  inputClass?: string;
  placeholder?: string;
  type?: string;
  value?: string;
  label: string;
  register?: UseFormRegister<any>; 
  errors?: FieldErrors<any>;
  locked?: boolean;
  validationSchema?: ValidationSchema;
  rows?: number;
  isReview?: boolean;
  isComments?: boolean;
  onChange?: (value: string) => void;
  accepted?: boolean
  showErrorStyle?: boolean;
}

export const MultilineInput = ({
  id,
  containerClass,
  labelClass,
  inputClass,
  placeholder,
  value,
  label,
  register,
  errors,
  validationSchema,
  locked,
  rows = 4,
  isReview = false,
  isComments = false,
  onChange,
  accepted,
  showErrorStyle,
}: MultilineInputProps) => {
  const [inputValue, setInputValue] = useState(value);
  const [commentsClass, setCommentsClass] = useState('');

  const hasErrors = showErrorStyle || (errors && errors[id] || accepted === false);

  useEffect(() => {
    setInputValue(value);
    if (isComments) {
      setCommentsClass(isReview ? styles.reviewerComments : styles.applicantComments);
    }
  }, [value]);

  const handleChange = (event: any) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };
  const inputProps = register ? register(id as `${string}`, validationSchema) : {};
  return (

    <div className={classNames(styles.wrapperContainer)}>
      <div className={classNames(styles.inputContainer, containerClass, commentsClass, hasErrors && styles.errorBorder, locked && styles.lockedMultilineInputContainer)}>
        {label && <div className={classNames(styles.label, labelClass, hasErrors && styles.errorText)}>{label}</div>}
        
        {
          locked ? 
            // Locked MultilineInput
            <div className={classNames(styles.lockedMultilineInput, styles.textarea, inputClass)}>
              {inputValue}
            </div>

            // Unlocked Multiline Input
            : <textarea
              id={id}
              className={classNames(styles.textarea, inputClass)}
              rows={rows}
              placeholder={placeholder}
              value={inputValue || ''}
              onChange={handleChange}
              disabled={locked}
              {...(onChange ? {} : inputProps)}
            />
        }
      </div>
      {errors && (
        <div>
          <span className={styles.alert}>{errors[id]?.message}</span>
        </div>
      )}
    </div>
  );
};