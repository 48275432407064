import { AuthTokenPayload } from "actions/lib/jwtActions";
import { UserRoles } from "../actions/userActions";
import { jwtData } from "../config/config";
import { jwtDecode } from "jwt-decode";

const getUserType = () => {
  const authToken = localStorage.getItem(jwtData.authToken);
  if (!authToken) {
    return null;
  }
  const decodedToken = jwtDecode<AuthTokenPayload>(authToken);
  const userType = decodedToken.userType;
  return userType;
};
  
export const isUserClient = () => {
  return getUserType() === UserRoles.Client;
};

export const isUserStandard = () => {
  return getUserType() === UserRoles.Standard;
};
