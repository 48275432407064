import { FC } from 'react';
import Sidebar from '../sidebar/sidebar';
import ContentWrapper from "../contentWrapper/contentWrapper";
import styles from './layout.module.scss';
import Header from "../../components/navbar/navbar";
import { useSetFilterState, useFilterState } from "context/filter";

const Layout : FC = ({children}) => {

  const setFilterState = useSetFilterState();

  const onClickBody = () => {
    setFilterState({
      isShowingFilter: false
    });

  };

  return (<div className={styles.layoutParent} onClick={onClickBody}>
    <Header/>
    <div className={styles.layoutContent}>
      <div className={styles.leftSideBar}>
        <Sidebar/>
      </div>
      <ContentWrapper>
        {children}
      </ContentWrapper>
    </div>
  </div>);
};

export default Layout;
