import React from 'react';
import styles from './noDataMessage.module.scss';
import classNames from 'classnames';

interface INoDataMessage {
  title: string;
  description?: string;
  className?: string;
}

const NoDataMessage = ({ title, description, className }: INoDataMessage) => {
  return (
    <div id={'noDataMessage'} className={classNames(styles.noDataContainer, className)}>
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.description}>{description}</p>
    </div>
  );
};

export default NoDataMessage;
