import styles from './gradientTextButton.module.scss';
import { Button, IButtonProps } from "../button/button";
import AddRowArrow from 'components/icons/addRowArrow';
import classNames from 'classnames';

export interface GradientTextButtonProps extends IButtonProps {
  text: string;
}

export const GradientTextButton = ({
  text,
  ...props
}: GradientTextButtonProps) => {
  return (
    <Button {...props} className={classNames(styles.gradientTextContainer, props.className)} style={'none'}>
      <div className={styles.gradientText}>{text}</div>
      <div className={styles.gradientTextArrow}><AddRowArrow /></div>
    </Button>
  );
};