import React, { useEffect, useState, useRef, useCallback } from "react";
import style from "./company.module.scss";
import Layout from "components/layout/Layout";
import { Filter } from "components/UI/filter/filter";
import { SearchInput } from "components/UI/searchInput/searchInput";
import { CompanyTableRow } from "components/companyTableRow/companyTableRow";
import { Button } from "components/UI/button/button";
import { BackArrow } from "components/UI/backArrow/backArrow";
import { useHistory, useLocation } from "react-router-dom";
import { RoutePaths } from "app/routing/routing";
import { Modal } from "components/modal/modal";
import { ApplicationFormFields, NewApplication } from "components/newApplicationForm/newApplicationForm";
import { createApplicationRequest, useGetApplications } from "actions/applicationsActions";
import NoDataMessage from "components/noDataBlock/noDataMessage";
import { IntStatus } from "utils/commonFunctions";
import { LoadingContainer } from "components/UI/loadingContainer/loadingContainer";
import SingleFilterDropDown, { OptionItem } from "components/UI/singleFilterDropDown/singleFilterDropDown";
import { getClientById } from "actions/clientActions";
import useInfiniteScroll from "hooks/useInfiniteScroll";

interface LocationState {
  clientNumber: string;
  clientName: string;
  clientID: string;
}

const intStatusOptions = [
  { id: '0', label: 'Active' },
  { id: '2', label: 'Closed' },
  { id: '3', label: 'On Hold' },
  { id: '4', label: 'Pending Review' },
  { id: '1', label: 'Submitted' },
];

const sortOptions: OptionItem[] = [
  {
    label: "Matter Number - Highest to Lowest",
    value: { orderBy: "fileNo", sortOrder: "DESC" },
  },
  {
    label: "Matter Number - Lowest to Highest",
    value: { orderBy: "fileNo", sortOrder: "ASC" },
  },
  {
    label: "Applicant Name - Alphabetical (A to Z)",
    value: { orderBy: "ApplicantName", sortOrder: "ASC" },
  },
  {
    label: "Applicant Name - Alphabetical (Z to A)",
    value: { orderBy: "ApplicantName", sortOrder: "DESC" },
  },
  {
    label: "Status - Alphabetical (A to Z)",
    value: { orderBy: "intStatus", sortOrder: "ASC" },
  },
  {
    label: "Status - Alphabetical (Z to A)",
    value: { orderBy: "intStatus", sortOrder: "DESC" },
  },
];

export const Company = () => {
  const history = useHistory();
  const location = useLocation();
  const { state } = location || {};
  const { clientNumber, clientName, clientID } = (state as LocationState) || {};
  const [canAddNewMatter, setCanAddNewMatter] = useState<boolean>(false);
  const [searchWord, setSearchWord] = useState<string>("");
  const [orderBy, setOrderBy] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<string>("");
  const [intStatus, setIntStatus] = useState<Array<IntStatus>>([]);
  const [showApplicationModal, setShowApplicationModal] = useState(false);
  const [isFilterDropdownVisible, setFilterDropdownVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchClientData = async () => {
      try {
        const clientData = await getClientById({ clientId: clientID });
        setCanAddNewMatter(clientData.bitButtonVisible == 1);
      } catch (error) {
        console.error("Error fetching client data: ", error);
      }
    };
    fetchClientData();
  }, [clientID]);

  const {
    data: applicationsData,
    error: applicationsError,
    mutate: mutateApplications,
    noDataFound: noApplicationsDataFound,
    isValidating,
    size,
    setSize,
    hasMore,
  } = useGetApplications({
    rowNumber: 100,
    searchWord: searchWord,
    clientID: clientID,
    orderBy: orderBy,
    sortOrder: sortOrder,
    intStatus: intStatus,
  });



  const handleSortChange = (value: any) => {
    setOrderBy(value?.orderBy || "");
    setSortOrder(value?.sortOrder || "");
    resetData();
  };

  const onSearch = (value: string) => {
    setSearchWord(value);
    resetData();
  };

  const onFilterChange = async (selectedOptions: any[]) => {
    setIntStatus(selectedOptions as IntStatus[]);
    resetData();
  };

  const onViewClick = (
    clientNumber: number,
    description: string,
    fileId: string
  ) => {
    history.push({
      pathname: RoutePaths.Application,
      state: {
        clientNumber,
        description,
        fileId,
      },
    });
  };

  if (!state) {
    history.push(RoutePaths.Dashboard);
  }

  useEffect(() => {
    if (showApplicationModal) {
      setIsSubmitting(false);
    }
  }, [showApplicationModal]);

  const onClickNewApplication = () => {
    setShowApplicationModal(true);
  };

  const handleCloseModal = () => {
    setShowApplicationModal(false);
  };

  const onSubmitApplication = async (data: ApplicationFormFields) => {
    try {
      setIsSubmitting(true);
      await createApplicationRequest({
        clientID: clientID,
        formFields: data,
        reviewStatus: IntStatus.PendingReview,
        fileUploads: data.fileUploads,
      });
    } catch (error) {
      console.error("Error submitting application: ", error);
    } finally {
      handleCloseModal();
      setIsSubmitting(false);
      mutateApplications();
    }
  };

  const resetData = () => {
    setSize(1);
    mutateApplications();
  };

  const loadMore = () => {
    setSize(prevSize => prevSize + 1);
  };

  const [loader] = useInfiniteScroll(loadMore, hasMore, [orderBy, sortOrder, searchWord, intStatus]);

  return (
    <Layout>
      <div className={style.company}>
        <div className={style.backArrow}>
          <BackArrow />
        </div>
        <h2 className={style.headerDesktop}>
          {clientNumber} - {clientName}’s Matters
        </h2>
        <h2 className={style.headerMobile}>{clientName}’s Matters</h2>
      </div>
      <div className={style.filterButtons}>
        <Filter
          text="Filter Matters"
          isVisible={isFilterDropdownVisible}
          setVisible={setFilterDropdownVisible}
          onCheckboxChange={onFilterChange}
          filterOptions={intStatusOptions}
          mobileText="Filter"
          className={style.filter}
        />
        <SingleFilterDropDown
          id={'sortMatters'}
          label={"Sort Matters"}
          optionItems={sortOptions}
          onOptionSelected={handleSortChange}
          dropDownDirectionClass={style.dropDownDirectionClass}
          className={style.sortMatters}
          mobileText="Sort"
        />
        <SearchInput
          placeholder="Try 'work permit'"
          onChange={onSearch}
          className={style.searchBar}
        />
        {canAddNewMatter && (
          <Button
            id="newApplicationButton"
            onClick={onClickNewApplication}
            style="upload"
            mobileText="New"
            className={style.newApplicationButton}
          >
            New Matter
          </Button>
        )}
      </div>
      <div className={style.clientTableContainerWrapper}>
        <div className={style.clientTableContainer}>
          <div>
            <table className={style.clientTable}>
              <thead>
                <tr>
                  <th>Matter Number</th>
                  <th>Applicant Name</th>
                  <th className={style.description}>Description</th>
                  <th className={style.description}>Lawyer</th>
                  <th className={style.description}>Created On</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {applicationsData &&
                  applicationsData.filter(application => application !== null).map((applicationRowData, index) => {
                    return (
                      <CompanyTableRow
                        key={index}
                        number={applicationRowData.fileNo}
                        company={applicationRowData.applicantName}
                        description={applicationRowData.fileDesc}
                        status={applicationRowData.fileStatus}
                        lawyer={applicationRowData.lawyer}
                        createdOn={applicationRowData.createdOn}
                        onClick={() =>
                          onViewClick(
                            applicationRowData.fileNo,
                            applicationRowData.fileDesc,
                            applicationRowData.fileId
                          )
                        }
                      />
                    );
                  })}
                {isValidating && applicationsData && size > 2 && <tr><td><span>Loading...</span></td></tr>}
              </tbody>
            </table>
            <div ref={loader} style={{ height: '1px', backgroundColor: 'transparent' }} />
            {size <= 2 && isValidating &&  <LoadingContainer loadingLabel="Loading matters..." />}
          </div>
          {noApplicationsDataFound && !isValidating && (
            <NoDataMessage
              title="No Matters Found!"
              description="Please reach out to your primary contact at the firm for assistance."
            />
          )}
        </div>
      </div>

      <Modal
        id="newApplicationModal"
        title="New Matter"
        show={showApplicationModal}
        onClose={handleCloseModal}
        className={style.newApplication}
        headerClass={style.newApplicationHeader}
        hideModalHeader={isSubmitting}
      >
        {isSubmitting
          ? <LoadingContainer loadingLabel="Your request is being submitted" />
          : <NewApplication onSubmitForm={onSubmitApplication} />
        }
      </Modal>
    </Layout>
  );
};
