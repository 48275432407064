const HamburgerIcon = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={35}
    height={35}
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <circle cx="20" cy="20" r="20" fill="white"/>
    <path
      fill="#2F528F"
      d="M12 26C11.7167 26 11.479 25.904 11.287 25.712C11.095 25.52 10.9993 25.2827 11 25C11 24.7167 11.096 24.479 11.288 24.287C11.48 24.095 11.7173 23.9993 12 24H28C28.2833 24 28.521 24.096 28.713 24.288C28.905 24.48 29.0007 24.7173 29 25C29 25.2833 28.904 25.521 28.712 25.713C28.52 25.905 28.2827 26.0007 28 26H12ZM12 21C11.7167 21 11.479 20.904 11.287 20.712C11.095 20.52 10.9993 20.2827 11 20C11 19.7167 11.096 19.479 11.288 19.287C11.48 19.095 11.7173 18.9993 12 19H28C28.2833 19 28.521 19.096 28.713 19.288C28.905 19.48 29.0007 19.7173 29 20C29 20.2833 28.904 20.521 28.712 20.713C28.52 20.905 28.2827 21.0007 28 21H12ZM12 16C11.7167 16 11.479 15.904 11.287 15.712C11.095 15.52 10.9993 15.2827 11 15C11 14.7167 11.096 14.479 11.288 14.287C11.48 14.095 11.7173 13.9993 12 14H28C28.2833 14 28.521 14.096 28.713 14.288C28.905 14.48 29.0007 14.7173 29 15C29 15.2833 28.904 15.521 28.712 15.713C28.52 15.905 28.2827 16.0007 28 16H12Z"
    />
  </svg>
);

export default HamburgerIcon;
