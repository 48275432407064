import * as base from "./lib/baseActions";
import { apiEndpoints } from "config/config";

type CaseAnalystOptions = {
  value: string;
  label: string;
}

const getCaseAnalysts = async (): Promise<CaseAnalystOptions[]> => {
  try {
    const response = await base.get(apiEndpoints.caseAnalysts);
    if (!Array.isArray(response.data) || response.data.length === 0) {
      throw new Error("Case analysts data is empty or not in expected format.");
    }
    const caseAnalysts = response.data;
    if (!Array.isArray(caseAnalysts)) {
      throw new Error("Case analysts data is not an array.");
    }

    const formattedCaseAnalysts = caseAnalysts.map((caseAnalyst: any) => ({
      value: String(caseAnalyst.value),
      label: caseAnalyst.label,
    }));

    return formattedCaseAnalysts;
  } catch (error) {
    console.error("Error fetching case analysts:", error);
    throw error; 
  }
};

export {
  getCaseAnalysts,
};
