const NotificationButtonBlue = ({ ...props }) => {
  return (
    <svg width="35" height="35" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill="white"/>
      <path d="M26.5288 25.3801H12.7988V17.4701C12.7988 13.6801 15.8688 10.6001 19.6688 10.6001C23.4588 10.6001 26.5388 13.6701 26.5388 17.4701V25.3801H26.5288Z" fill="#2F528F"/>
      <path d="M10.5996 25.3801H28.7296" stroke="#2F528F" strokeWidth="1.2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M21.6787 27.3701C21.6787 28.4801 20.7787 29.3901 19.6587 29.3901C18.5387 29.3901 17.6387 28.4901 17.6387 27.3701" stroke="#2F528F" strokeWidth="1.2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};
      
export default NotificationButtonBlue;
  