import { useEffect, useState } from "react";
import style from "./search.module.scss";
import Layout from "components/layout/Layout";
import helpfulBeaver from 'assets/images/helpfulBeaver.svg';
import { useGetSiteWideSearch } from "actions/clientActions";
import { useLocation } from "react-router-dom";
import { SearchBarRow, searchBarRowProps} from "components/searchBarRow/searchBarRow";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import { LoadingContainer } from "components/UI/loadingContainer/loadingContainer";

interface LocationState {
  searchQuery: string;
}
export const Search = () => {
  const location = useLocation();
  const { state } = location || {};
  const { searchQuery } = state as LocationState || {};

  const {
    data: searchResults,
    error: searchResultsDataError,
    mutate: mutateSearch,
    isValidating,
    size,
    setSize,
    hasMore,
    noDataFound,
  } = useGetSiteWideSearch({ searchQuery, rowNumber: 100 });
  
  const loadMore = () => {
    setSize(prevSize => prevSize + 1);
  };
  const [loader] = useInfiniteScroll(loadMore, hasMore, [searchQuery]);


  return (
    <Layout>
      <div className={style.searchPage}>
        <h3 className={style.searchTitle}>Search Results for "{searchQuery}"</h3>
        <div className={style.searchTableWrapper}>
          <div className={style.searchTable}>
            {noDataFound && !isValidating ? (
              <div className={style.noResultsSection}>
                <img className={style.helpfulBeaver} src={helpfulBeaver} alt="Helpful Beaver" />
                <h3 className={style.noResultsTitle}>No Results Found</h3>
                <div className={style.noResultsDescription}> Try adjusting your search to find what you are looking for!</div>
              </div>
            ) : (
              searchResults.map((searchReturn: searchBarRowProps, index) => (
                <SearchBarRow
                  key={index}
                  id={searchReturn.id}
                  objectType={searchReturn.objectType}
                  description={searchReturn.description}
                  clientNumber={searchReturn.clientNumber}
                  fileNumber={searchReturn.fileNumber}
                />
              ))
            )}
            {isValidating && searchResults && size > 1 && <div>Loading...</div>}

            <div ref={loader} style={{ height: '1px', backgroundColor: 'transparent' }} />
            {size === 1 && isValidating &&  <LoadingContainer loadingLabel="Searching..." />}
          </div>
        </div>
      </div>
    </Layout>
  );
};
