import React, { useState } from "react";
import style from "./reviewApplications.module.scss";
import companyStyle from "pages/company/company.module.scss"; 
import Layout from "components/layout/Layout";
import { SearchInput } from "components/UI/searchInput/searchInput";
import { Filter } from "components/UI/filter/filter";
import classNames from "classnames";
import FolderIcon from "components/icons/folder";
import { ReviewApplicationForm } from "components/reviewApplicationForm/reviewApplicationForm";
import { Modal } from "components/modal/modal";
import { ApplicationReviewType, ApplicationStatusType, NewFileRequestParams, reviewApplicationRequest, useGetApplicationRequestsList } from "actions/applicationsActions";
import SingleFilterDropDown, { OptionItem } from "components/UI/singleFilterDropDown/singleFilterDropDown";
import { useGetLawyers } from "actions/lawyerActions";
import { IntStatus } from "utils/commonFunctions";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import { LoadingContainer } from "components/UI/loadingContainer/loadingContainer";
import NoDataMessage from "components/noDataBlock/noDataMessage";

const sortOptions: OptionItem[] = [
  {
    label: "Client Name - A to Z",
    value: { orderBy: "clName", sortOrder: "ASC" },
  },
  {
    label: "Client Name - Z to A",
    value: { orderBy: "clName", sortOrder: "DESC" },
  },
  {
    label: "Applicant Name - A to Z",
    value: { orderBy: "ApplicantName", sortOrder: "ASC" },
  },
  {
    label: "Applicant Name - Z to A",
    value: { orderBy: "ApplicantName", sortOrder: "DESC" },
  },
  {
    label: "Requested Date - Newest to Oldest",
    value: { orderBy: "RequestedOn", sortOrder: "DESC" },
  },
  {
    label: "Requested Date - Oldest to Newest",
    value: { orderBy: "RequestedOn", sortOrder: "ASC" },
  }
];

const statusOptions = [
  { id: '4', label: 'Approved' },
  { id: '2', label: 'Pending Review' },
  { id: '3', label: 'Rejected' }
];

export const ApplicationStatus = {
  Approved: "Approved",
  Pending: "Pending Review",
  Rejected: "Rejected",
} as const;

export const ReviewApplications = () => {

  const [searchWord, setSearchWord] = useState<string>('');
  const [orderBy, setOrderBy] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<string>('');
  const [intStatus, setIntStatus] = useState<IntStatus[]>();
  const [responsibleLawyerID, setResponsibleLawyerID] = useState<string>(''); 
  const [isStatusVisible, setIsStatusVisible] = useState(false);
  const [showApplicationModal, setShowApplicationModal] = useState(false);
  const [selectedRecordID, setSelectedRecordID] = useState<number>(0);
  const [hideModalHeader, setHideModalHeader] = useState(false);

  const { 
    data: applications, 
    error: applicationsError, 
    mutate: mutateApplications, 
    noDataFound: noApplicationsDataFound, 
    size,
    setSize,
    isValidating,
    hasMore,
  } = useGetApplicationRequestsList({ rowNumber: 100, orderBy, sortOrder, searchWord, intStatus, responsibleLawyerID});


  const onSearch = (value: string) => {
    setSearchWord(value);
    resetData();
  };

  const { data: questionnaireLawyers, error: questionnairelawyersError } = useGetLawyers();
  const lawyerOptions: OptionItem[] = questionnaireLawyers?.map(lawyer => ({
    label: lawyer.label,
    value: lawyer.id
  })) ?? [];

  const getStatusClass = (name: ApplicationStatusType) => {
    switch(name) {
      case ApplicationStatus.Approved:
        return style.approved;
      case ApplicationStatus.Pending:
        return style.pending;
      case ApplicationStatus.Rejected:
        return style.rejected;
      default:
        return style.active;
    }
  };

  const onClickReviewApplication = (recordID: number) => {
    setSelectedRecordID(recordID);
    setShowApplicationModal(true);
  };

  const handleCloseModal = () => {
    setShowApplicationModal(false);
  };

  const handleSortChange = (value: any) => {
    setOrderBy(value?.orderBy || "");
    setSortOrder(value?.sortOrder || "");
    resetData();
  };

  const handleIntStatusChange = (selectedOptions: any[]) => {
    setIntStatus(selectedOptions as IntStatus[]);
    resetData();
  };

  const onSubmitReview = async (data: NewFileRequestParams) => {
    try {
      await reviewApplicationRequest(data);
    } catch (error) {
      console.error("Error submitting review: ", error);
    } finally {
      handleCloseModal();
      mutateApplications();
    }
  };
  
  const ApplicationReviewRow = ({
    applicantName,
    clientID,
    clientName,
    clientNo,
    recordID,
    requestedOn,
    status,
    txtComments,
    onClick = onClickReviewApplication 
  }: ApplicationReviewType) => {
    const statusClass = getStatusClass(status);
    return (
      <>
        <tr className={style.barRow}>
          <td colSpan={5}/>
        </tr>
        <tr onClick={() => onClick(recordID)} className={style.selectableRow}>
          <td className={style.clientNo}>
            <div className={style.bubble}>{clientNo}</div>
          </td>
          <td className={style.clientName}>
            <div className={style.nameWrapper}><FolderIcon/>{clientName}</div>
          </td>
          <td  className={style.applicantName}> 
            <div>{applicantName}</div>
          </td>
          <td className={style.requestedOn}>
            <div>{requestedOn}</div>
          </td>
          <td className={style.status}>
            <div className={classNames(statusClass, style.bubble)}>{status}</div>
          </td>
        </tr>
      </>
    );
  };
  

  const resetData = () => {
    setSize(1);
    mutateApplications();
  };

  const loadMore = () => {
    setSize(prevSize => prevSize + 1);
  };

  const [loader] = useInfiniteScroll(loadMore, hasMore, [orderBy, sortOrder, searchWord, responsibleLawyerID, intStatus]);

  return (
    <Layout>
      <div className={style.company}>
        <h2>New Matter Requests</h2>
      </div>
      <div className={style.filterButtons}>
        <SingleFilterDropDown 
          id={'sortClients'} 
          label={"Sort"} 
          optionItems={sortOptions} 
          onOptionSelected={handleSortChange}
        />
        <SingleFilterDropDown 
          label="Responsible Lawyer"
          onOptionSelected={(value) => setResponsibleLawyerID(value)}
          optionItems={lawyerOptions}
          mobileText="Responsible Lawyer"
        />
        <Filter
          text="Status"
          isVisible={isStatusVisible}
          setVisible={setIsStatusVisible}
          onCheckboxChange={(selectedOptions) => handleIntStatusChange(selectedOptions)}
          filterOptions={statusOptions}
          mobileText="Status"
          className={style.sort}
        />
        <SearchInput placeholder="Try 'work permit'" onChange={onSearch} className={style.searchBar}/>
      </div>
      <div className={style.clientTableContainerWrapper}>
        <div className={style.clientTableContainer}>
          {noApplicationsDataFound && !isValidating ? 
            <NoDataMessage title={"No Matters available."} /> : 
            <table className={style.clientTable}>
              <thead>
                <tr>
                  <th className={style.clientNo}>Client No.</th>
                  <th className={style.clientName}>Client Name</th>
                  <th className={style.applicantName}>Applicant Name</th>
                  <th className={style.requestedOn}>Requested On</th>
                  <th className={style.status}><div className={style.statusPadding}>Status</div></th>
                </tr>
              </thead>
              {applications?.map((row: ApplicationReviewType) => (ApplicationReviewRow(row)))}
              {isValidating && applications && size > 1 && <tr><td><span>Loading...</span></td></tr>}
            </table>
          }
          {size === 1 && isValidating && <LoadingContainer loadingLabel="Loading matters..." />}
          <div ref={loader} style={{ height: '1px', backgroundColor: 'transparent' }} />
        </div>
      </div>

      <Modal 
        id='newApplicationModal'
        title="New Matter" 
        show={showApplicationModal} 
        onClose={handleCloseModal} 
        className={companyStyle.newApplication}
        headerClass={companyStyle.newApplicationHeader}
        hideModalHeader={hideModalHeader}
      >
        <ReviewApplicationForm 
          onSubmitForm={onSubmitReview}
          recordID={`${selectedRecordID}`}
          closeModal={handleCloseModal}
          setHideModalHeader={setHideModalHeader}
        />
      </Modal>
    </Layout>
  );
};

