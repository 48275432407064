import styles from "./helpPage.module.scss";
import Layout from "components/layout/Layout";
import HelpfulBeaver from 'assets/images/helpfulBeaver.svg';
import { DropDownTextBlock } from "components/dropDownTextBlock/dropDownTextBlock";
import SettingsButtonBlue from "components/icons/settingsButtonBlue";

export const HelpPage = () => {
  return (
    <Layout>
      <div className={styles.HelpPageHeader}>
        <div>
          <h2 className={styles.helpPageTitle}>How Can We Help You?</h2>
          <p>We've put together some commonly asked questions for you. Please click on the question links below to reveal the answers!</p>
          <p>If you have a technical question that you can’t find the answer to, please contact us at <a className={styles.boldBlue}>IT@gands.com</a>.</p>
          <p>For all case-specific questions, please contact your Lawyer or Case Analyst.</p>
        </div>
        <div className={styles.imageContainer}>
          <img className={styles.helpfulBeaver} src={HelpfulBeaver} alt="Helpful Beaver" />
        </div>
      </div>
      <div className={styles.HelpPageWrapper}>
        <div className={styles.HelpPageKnowledgeBlocks}>
          <DropDownTextBlock 
            title="How can I reset my password?" 
            dropDownClassName={styles.DropDownTextBlock}
          >
            <div>
              <p>To reset your password:</p>
              <ol>
                <li>Go to <a className={styles.boldBlack}>Settings</a> (click the<span className={styles.settingsIconPlaceholder}><SettingsButtonBlue/></span>icon on the top right corner).</li>
                <li>Click on the <a className={styles.boldBlack}>Reset Password</a> tab.</li>
                <li>Enter your old and new passwords. Please note, your new password must be different from previously used passwords, and must be 8 to 50 characters long, with at least 1 number, 1 special character and 1 upper case character.</li>
                <li>Click <a className={styles.boldBlack}>Save Password</a>.</li>
              </ol>
            </div>
          </DropDownTextBlock>
          <DropDownTextBlock 
            title="Which file formats are accepted in the portal for document uploads?"  
            dropDownClassName={styles.DropDownTextBlock}
          >
            <div>
              <p>We accept documents in the following file formats: .PDF, .DOC, .DOCX, .PNG, .JPG, .JPEG, .HEIC, .XLS, .XLSX, .CSV and .TXT</p>
              <p>Please do not upload zipped files.</p>
            </div>
          </DropDownTextBlock>
          <DropDownTextBlock 
            title="How can I pay for my consultation?" 
            dropDownClassName={styles.DropDownTextBlock}
          >
            <div>
              <p>To pay your Consultation fees online with your Visa or Mastercard, please go to:&nbsp; 
                <a className={styles.boldBlue} href="https://www.gands.com/consultation-payment/" target="_blank" rel="noopener noreferrer">
                  https://www.gands.com/consultation-payment/
                </a>
              </p>
              <p>Please note, we do not accept payments via American Express (AMEX).</p>
            </div>
          </DropDownTextBlock>
          <DropDownTextBlock 
            title="How can I pay my invoice for my legal fees?" 
            dropDownClassName={styles.DropDownTextBlock}
          >
            <div>
              <p>You can pay your Green and Spiegel invoices online with Visa or Mastercard conveniently using the links below. Please note, we do not accept payments via American Express (AMEX).</p>
              <ul>
                <li>To pay your invoice in <span className={styles.boldBlue}>CAD</span>, please go to:&nbsp; 
                  <a className={styles.boldBlue} href="https://www.gands.com/invoice-payment-cad/" target="_blank" rel="noopener noreferrer">
                    https://www.gands.com/invoice-payment-cad/
                  </a>
                </li>
                <li>To pay your invoice in <span className={styles.boldBlue}>USD</span>, please go to:&nbsp; 
                  <a className={styles.boldBlue} href="https://www.gands.com/invoice-payment-usd/" target="_blank" rel="noopener noreferrer">
                    https://www.gands.com/invoice-payment-usd/
                  </a>
                </li>
              </ul>
            </div>
          </DropDownTextBlock>
          <DropDownTextBlock 
            title="How long will my application process take?" 
            dropDownClassName={styles.DropDownTextBlock}
          >
            <div>
              <p>Each application is different, and as such takes a different amount of time to process. Although we can’t tell you exactly how long it will take the government to process your application, here are some tools you can use.</p>
              <p>To check the average processing time based on your application type, please go to:&nbsp; 
                <a className={styles.boldBlue} href="https://www.canada.ca/en/immigration-refugees-citizenship/services/application/check-processing-times.html" target="_blank" rel="noopener noreferrer">
                  https://www.canada.ca/en/immigration-refugees-citizenship/services/application/check-processing-times.html
                </a>
              </p>
              <p>Once we have submitted your application, you can check your application status online at:&nbsp; 
                <a className={styles.boldBlue} href="https://www.canada.ca/en/immigration-refugees-citizenship/services/application/check-status.html" target="_blank" rel="noopener noreferrer">
                  https://www.canada.ca/en/immigration-refugees-citizenship/services/application/check-status.html
                </a>
              </p>
            </div>
          </DropDownTextBlock>
        </div>
      </div>
    </Layout>
  );
};
