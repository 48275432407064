import styles from './label.module.scss';

interface ILabelProps {
  id?: string;
  label?: string;
}

export const Label = ({
  id,
  label,
}: ILabelProps) => {
  return (
    <div className={styles.labelContainer}>
      <label id={id} className={styles.label}>
        {label}
      </label>
    </div>
  );
};
