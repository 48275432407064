import style from "./companyTableRow.module.scss";
import classNames from "classnames";
import FolderIcon from "components/icons/folder";
import { DateFormat, formatDateToString, parseDateString } from "utils/commonFunctions";


export enum FileStatus {
  Active = 'Active',
  PendingReview = 'Pending Review',
  Submitted = 'Submitted',
  Closed = 'Closed',
  OnHold = 'On Hold'
}

interface ICompanyTableRow {
  number: number;
  company: string;
  description: string;
  status: FileStatus;
  lawyer: string;
  createdOn: string;
  onClick?: () => void;
  ref?: any;
}

const getStatusClass = (status: FileStatus) => {
  switch(status) {
    case FileStatus.Active:
      return style.active;
    case FileStatus.Submitted:
      return style.submitted;
    case FileStatus.Closed:
      return style.closed;
    case FileStatus.OnHold:
      return style.onHold;
    case FileStatus.PendingReview:
      return style.pendingReview;
    default:
      return style.active;
  }
};

export const CompanyTableRow = ({
  number,
  company,
  description,
  lawyer,
  createdOn,
  status,
  ref,
  onClick = () => undefined
}: ICompanyTableRow) => {

  const formattedCreatedOn = createdOn ? formatDateToString(parseDateString(createdOn), DateFormat.YYYY_MM_DD_slashes) : 'n/a';
  const statusClass = getStatusClass(status);

  return (
    <>
      <tr className={style.barRow}>
        <td colSpan={6}/>
      </tr>
      <tr onClick={onClick} className={style.selectableRow} ref={ref}>
        <td>
          <div className={style.bubble}>{number ? number : 'n/a'}</div>
        </td>
        <td>
          <div className={style.companyName}><FolderIcon/>{company ? company : 'n/a'}</div>
        </td>
        <td className={style.description}> 
          <div>{description ? description : 'n/a'}</div>
        </td>
        <td className={style.description}> 
          <div>{lawyer ? lawyer : 'n/a'}</div>
        </td>
        <td className={style.description}> 
          <div>{formattedCreatedOn}</div>
        </td>
        <td>
          <div className={classNames(statusClass, style.bubble)}>{status ? status : 'n/a'}</div>
        </td>
      </tr>
    </>
  );
};
