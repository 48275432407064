import React from 'react';
import { UserForm } from '../../components/userForm/userForm';
import style from "./editUser.module.scss";
import Layout from 'components/layout/Layout';

export const EditUser = () => {
  return (
    <Layout>
      <div className={style.content} >
        <div>
          <UserForm />
        </div>
      </div>
    </Layout>
  );
};

