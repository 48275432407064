import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import styles from "./XLSXViewer.module.scss";
import classNames from "classnames";
interface XLSXViewerProps {
  file: string;
}

interface SheetData {
  [key: string]: any;
}

const XLSXViewer: React.FC<XLSXViewerProps> = ({ file }) => {
  const [sheetsData, setSheetsData] = useState<{ [sheetName: string]: SheetData[] }>({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(file);
        const blob = await response.blob();
        const arrBuff = await blob.arrayBuffer();
        const view = new Uint8Array(arrBuff);
        const workbook = XLSX.read(view, { type: "array" });

        const allSheetsData: { [sheetName: string]: SheetData[] } = {};
        
        workbook.SheetNames.forEach(sheetName => {
          const worksheet = workbook.Sheets[sheetName];
          const jsonData: SheetData[] = XLSX.utils.sheet_to_json(worksheet);
          allSheetsData[sheetName] = jsonData;
        });

        setSheetsData(allSheetsData);
      } catch (error) {
        console.error("Error reading XLSX file:", error);
      }
    };

    fetchData();
  }, [file]);

  return (
    <div className={classNames(styles.xlsxViewer)}>
      {Object.keys(sheetsData).map(sheetName => (
        <div key={sheetName} className={classNames(styles.sheet)}>
          <h3 className={classNames(styles.sheetName)}>{sheetName}</h3>
          <table className={classNames(styles.sheetTable)}>
            <thead>
              {sheetsData[sheetName].length > 0 && (
                <tr>
                  {Object.keys(sheetsData[sheetName][0]).map((key) => (
                    <th key={key}>{key}</th>
                  ))}
                </tr>
              )}
            </thead>
            <tbody>
              {sheetsData[sheetName].map((row, index) => (
                <tr key={index}>
                  {Object.values(row).map((value, idx) => (
                    <td key={idx}>{value}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};

export default XLSXViewer;
