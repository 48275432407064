import style from "./tab.module.scss";

interface ITab {
  id?: string;
  label: string;
  icon?: any;
  isActive: boolean;
  onClick: () => void;
}

export const Tab = ({
  id,
  label,
  icon,
  isActive,
  onClick,
}: ITab) => {

  const tabClassName = `${style.tab} ${isActive ? style.active : ''}`;

  return (
    <div id={id} className={tabClassName} onClick={onClick}>
      <div className={style.icon}>{icon}</div>
      {label}
    </div>
  );
};