const ArrowSide = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5098 3.87039L15.7398 2.10039L5.83984 12.0004L15.7398 21.9004L17.5098 20.1304L9.37984 12.0004L17.5098 3.87039Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ArrowSide;
