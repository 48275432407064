import BackArrowIcon from 'components/icons/backArrowIcon';
import style from "./backArrow.module.scss";
import { useHistory } from 'react-router-dom';

export const BackArrow = () => {
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  return (
    <div className={style.backArrow} onClick={goBack}>
      <BackArrowIcon/>
    </div>
  );
};