import { Key } from "react";
import { UseFormRegister, FieldErrors } from "react-hook-form";
import RemoveIcon from "components/icons/removeIcon";
import RemoveIconRed from "components/icons/removeIconRed";
import style from "./question.module.scss";
import { UpdateGridQuestionParams, UpdateTableQuestionParams } from "components/questionnaire/questionnaireHook";
import QuestionBaseComponent, { QuestionTypes } from "components/questionnaire/questionnaireComponents/questionnaireBaseComponent/questionBaseComponent";
import { DocumentNames } from "actions/documentActions";
import { DocumentAdditionParams, DocumentDeletionParams } from "components/UI/FileUpload/fileUpload";
import classNames from "classnames";
import { COMMENTS_QUESTION_ID } from "components/questionnaire/questionnaire";
import { IntStatus } from "utils/commonFunctions";

export interface QuestionOption {
  id?: string;
  label: string;
  value: string;
}

export interface QuestionRecord {
  recordID: string;
  value: string;
  locked?: boolean;
}

export interface QuestionDropDownType {
  cdcode?: string;
  cddesc?: string;
  cdhelp?: string;
  cdaddlink?: string;
  cdgroup?: number;
}

export interface QuestionDataType {
  questionID: Key | null | undefined;
  id: string;
  label: string;
  fieldtype: string;
  required: boolean;
  characterlimit: number;
  grid_id: number;
  width?: number;
  tableViewWidth?: number;
  value: string;
  locked: boolean;
  // TODO: will need to be resolved when uploaded & non-uploaded documents get mixed together
  // non-uploaded documents are string[], uploaded documents are UploadedFile[]
  documents?: any[]; 
  records?: QuestionRecord[];
  drop_down?: QuestionDropDownType[];
  matchParentValues?: boolean;
  parentID?: number;
  parentValue?: string[];
  regex?: string;
  bit_isVisibleInTable?: boolean;
  accepted?: boolean | null;
}

export interface QuestionProps {
  fieldId: string;
  sectionId: number;
  question?: QuestionDataType;
  register: UseFormRegister<any>;
  updateTableQuestion?: (params: UpdateTableQuestionParams) => void;
  updateGridQuestion?: (params: UpdateGridQuestionParams) => void;
  errors: FieldErrors<any>;
  recordId?: number;
  lockClass?: string;
  locked: boolean;
  isReview: boolean;
  isTablePreviewQuestion: boolean;
  isLockedQuestionnaire?: boolean;
  documentNames?: DocumentNames;
  addDocuments?: (params: DocumentAdditionParams) => void;
  deleteDocument?: (params: DocumentDeletionParams) => void;
  showErrorStyle?: boolean;
  onClickDocument?: (documentName: string, documentPath: string) => void;
  intStatus?: IntStatus;
}

const Question = ({
  fieldId,
  sectionId,
  question,
  updateGridQuestion,
  updateTableQuestion,
  register,
  errors,
  lockClass,
  recordId,
  locked,
  isReview,
  isTablePreviewQuestion,
  isLockedQuestionnaire,
  documentNames,
  addDocuments,
  deleteDocument,
  showErrorStyle,
  onClickDocument,
  intStatus,
}: QuestionProps) => {
  const handleQuestionChange = (newValue: string) => {
    if (!question) return;
    const partialUpdate: QuestionDataType = question;
    
    if (recordId !== undefined) {
      updateTableQuestion &&
        updateTableQuestion({ questionnaireQuestion: question, sectionId, recordId, newValue });
    } else {
      partialUpdate.value = newValue;
      const updatedQuestion = { ...question, ...partialUpdate, accepted: null};
      updateGridQuestion && updateGridQuestion({ sectionId, updatedQuestion });
    }
  };

  const setAccepted = (newValue: boolean | null) => {
    if (question) {
      const updatedQuestion: QuestionDataType = {
        ...question,
        accepted: newValue,
      };
      updateGridQuestion && updateGridQuestion({ sectionId, updatedQuestion });
    }
  };

  const questionComponent = (
    <QuestionBaseComponent
      id={fieldId}
      question={question}
      handleQuestionChange={handleQuestionChange}
      errors={errors}
      locked={locked}
      lockClass={lockClass}
      valueColorClass={style.valueColorClass}
      placeholderColorClass={style.placeholderColorClass}
      dropdownWrapperClass={style.dropdownWrapperClass}
      isReview={isReview}
      isTablePreviewQuestion={isTablePreviewQuestion}
      register={register}
      documentNames={documentNames}
      addDocuments={addDocuments}
      deleteDocument={deleteDocument}
      showErrorStyle={showErrorStyle}
      onClickDocument={onClickDocument}
      intStatus={intStatus}
    />
  );

  return (
    <div className={classNames(style.questionDiv, question?.fieldtype === QuestionTypes.document && style.documentUpload)}>
      {questionComponent}
      {!isTablePreviewQuestion && isReview && question?.id !== COMMENTS_QUESTION_ID && !isLockedQuestionnaire && !question?.accepted && (
        <button
          id={question?.label}
          className={style.rejectButton}
          type="button"
          onClick={() => setAccepted(question?.accepted === false ? null : false)}
        >
          <div className={style.rejectButton} id={`${question?.label}-rejectButton`}>
            {question?.accepted === false ? <RemoveIconRed /> : <RemoveIcon />}
          </div>
        </button>
      )}
    </div>
  );
};

export default Question;
