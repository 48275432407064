import useSWR, { KeyedMutator } from "swr";

export type CacheKey<ParamType> = [
  endpoint: string, 
  params: ParamType
];

export interface StickySWRResponse<ReturnType> {
  data: ReturnType[],
  error: Error | undefined,
  mutate: KeyedMutator<ReturnType[]>,
  noDataFound: boolean,
} 

const useStickySWR = <ParamType, ReturnType>(key: CacheKey<ParamType>, fetcher: () => Promise<ReturnType[]>): StickySWRResponse<ReturnType> => {
  const { data, error, mutate, isLoading } = useSWR(key, fetcher, { keepPreviousData: false });
  return { data: data || [], error, mutate, noDataFound: !isLoading && !data };
};

export { useStickySWR };