import { useState } from "react";
import style from "./filter.module.scss";
import FilterIcon from "components/icons/filterIcon";
import SortIcon from "components/icons/sortIcon";
import CheckCircle from "components/icons/checkCircle";
import { useOutsideClick } from "hooks/useOutsideClick";
import classNames from "classnames";

interface IFilter {
  id?: string;
  text: string;
  className?: string;
  mobileText?: string;
  filterOptions?: {
    id: string;
    label: string;
  }[];
  isVisible: boolean;
  setVisible: (isVisible: boolean) => void;
  onCheckboxChange?: (selectedOptions: string[]) => void;
}

export const Filter = ({
  id,
  text,
  className,
  mobileText,
  filterOptions = [],
  isVisible = false,
  setVisible,
  onCheckboxChange,
}: IFilter) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const { ref: filterRef } = useOutsideClick(() => setVisible(false));
  
  const handleCheckboxChange = (e: any) => {
    const updatedOptions = filterOptions
      .filter((option) => {
        const checkbox = document.getElementById(option.id) as HTMLInputElement;
        return checkbox.checked;
      })
      .map((option) => option.id);
    if (onCheckboxChange) {
      onCheckboxChange(updatedOptions);
    }
    setSelectedOptions(updatedOptions);
  };

  return (
    <div className={classNames(className, style.filterContainer)} ref={filterRef}>
      <div id={id} className={style.filter} onClick={() => setVisible(!isVisible)}>
        <div className={style.mobileOnly}>{mobileText}</div>
        <div className={mobileText ? style.desktopOnly : ''}>{text}</div>
        <div className={style.filterIcon}>
          {selectedOptions.length > 0 ? <CheckCircle /> : (isVisible ? <SortIcon /> : <FilterIcon />)}
        </div>
      </div>

      {isVisible && filterOptions.length > 1 && (
        <div className={style.filterDropdown}>
          {filterOptions.map((option, index) => (
            <>
              { index != 0 && <div className={style.barDiv}/>}
              <label className={style.filterRow} key={option.id} htmlFor={option.id}>
                <input
                  type="checkbox"
                  id={option.id}
                  onChange={(e) => handleCheckboxChange(e)}
                  checked={selectedOptions.includes(option.id)}
                />
                {option.label}
              </label>
            </>
          ))}
        </div>
      )}
    </div>
  );
};
