import { useState } from "react";
import style from "./dashboard.module.scss";
import Layout from "components/layout/Layout";
import { DashboardTableRow } from "components/dashboardTableRow/dashboardTableRow";
import { Filter } from "components/UI/filter/filter";
import { SearchInput } from "components/UI/searchInput/searchInput";
import { RoutePaths } from "app/routing/routing";
import { useHistory } from "react-router-dom";
import { useGetCompanies } from "../../actions/companyActions";
import NoDataMessage from "components/noDataBlock/noDataMessage";
import SingleFilterDropDown from "components/UI/singleFilterDropDown/singleFilterDropDown";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import { LoadingContainer } from "components/UI/loadingContainer/loadingContainer";

const sortOptions = [
  {
    label: "Client Number - Newest to Oldest",
    value: { orderBy: "clNo", sortOrder: "DESC" },
  },
  {
    label: "Client Number - Oldest to Newest",
    value: { orderBy: "clNo", sortOrder: "ASC" },
  },
  {
    label: "Client Name - Alphabetical (A to Z)",
    value: { orderBy: "clName", sortOrder: "ASC" },
  },
  {
    label: "Client Name - Alphabetical (Z to A)",
    value: { orderBy: "clName", sortOrder: "DESC" },
  },
];

export const Dashboard = () => {
  const history = useHistory();
  const [searchWord, setSearchWord] = useState("");
  const [orderBy, setOrderBy] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<string>("");
  
  const {
    data: companyData,
    error: companyDataError,
    mutate: mutateCompanies,
    noDataFound: noCompanyDataFound,
    isValidating: isValidating,
    size,
    setSize,
    hasMore,
  } = useGetCompanies({
    searchWord,
    orderBy,
    sortOrder,
    rowNumber: 100,
  });

  const onViewClick = (
    clientNumber: number,
    clientName: string,
    id: string
  ) => {
    history.push({
      pathname: RoutePaths.Company,
      state: {
        clientNumber,
        clientName,
        clientID: id,
      },
    });
  };

  const onSearch = async (value: string) => {
    resetData();
    setSearchWord(value);
  };

  const handleSortChange = (value: any) => {
    setOrderBy(value?.orderBy || "");
    setSortOrder(value?.sortOrder || "");
    resetData();
  };

  const resetData = () => {
    setSize(1);
    mutateCompanies();
  };

  const loadMore = () => {
    setSize(prevSize => prevSize + 1);
  };
  const [loader] = useInfiniteScroll(loadMore, hasMore, [orderBy, sortOrder, searchWord]);

  return (
    <Layout>
      <div className={style.companies}>
        <h2>Your Clients</h2>
      </div>
      <div className={style.filterButtons}>
        <SingleFilterDropDown 
          id="sortClients" 
          label="Sort clients" 
          optionItems={sortOptions} 
          onOptionSelected={handleSortChange}
          mobileText="Sort"
        />
        <SearchInput 
          id="clientSearch" 
          placeholder="Try 'work permit'" 
          onChange={onSearch} 
        />
      </div>
      <div className={style.clientTableContainerWrapper}>
        <div className={style.clientTableContainer}>
          <table className={style.clientTable}>
            <thead>
              <tr>
                <th>Client Number</th>
                <th>Client Name</th>
                <th className={style.lawyer}>Lawyer</th>
                <th className={style.cases}>Total Matters</th>
              </tr>
            </thead>
            <tbody>
              {!noCompanyDataFound &&  !isValidating && companyData?.map((rowData) => (
                <DashboardTableRow
                  key={rowData.clId}
                  number={rowData.clNo}
                  company={rowData.clName}
                  responsibleLawyer={rowData.responsibleLawyer}
                  cases={rowData.liveMatters}
                  onClick={() => onViewClick(rowData.clNo, rowData.clName, rowData.clId)}
                />
              ))}
              {isValidating && companyData && size > 1 && (
                <tr>
                  <td colSpan={4}>
                    <span>Loading...</span>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div ref={loader} style={{ height: '1px', backgroundColor: 'transparent' }} />
          {size === 1 && isValidating && <LoadingContainer loadingLabel="Loading companies..." />}
          {noCompanyDataFound && !isValidating && (
            <NoDataMessage
              className={style.noDataMessage}
              title="No Clients Found!"
              description="Please reach out to your primary contact at the firm if you need assistance."
            />
          )}
        </div>
      </div>
    </Layout>
  );

};
