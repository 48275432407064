import { LoginFormComponent } from '../../components/loginForm/loginForm';
import style from "./login.module.scss";
import LoginGraphic from 'components/icons/loginGraphics/loginGraphic';
import logo from 'assets/images/company-logo.png';
import SimpleLayout from 'components/simpleLayout/simpleLayout';
import { LoginGraphicContainer } from 'components/loginGraphicContainer/loginGraphicContainer';
import { useAppState } from 'context';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from 'app/routing/routing';

export const Login = () => {
  const appState = useAppState();
  const history = useHistory();

  if (appState.Authenticated) {
    history.push(RoutePaths.Dashboard);
  }

  return (
    <SimpleLayout>
      <div className={style.content}>
        <div className={style.loginContent}>
          <div className={style.logo}>
            <img className={style.companyLogo} src={logo} alt="Company logo" />
          </div>
          <div className={style.loginModal}>
            <h3 className={style.loginTitle}>Log in</h3>
            <LoginFormComponent />
          </div>
        </div>
        <LoginGraphicContainer Graphic={LoginGraphic} />
      </div>
    </SimpleLayout>
  );
};
