import { AuthTokenPayload } from 'actions/lib/jwtActions';
import { jwtData } from 'config/config';
import { useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { getUserPasswordVersion } from 'actions/userActions';

const usePasswordVersionChecker = (): void => {
  useEffect(() => {
    const checkPasswordVersion = async () => {
      try {
        const token = localStorage.getItem(jwtData.authToken);
        if (!token) return;

        const decodedToken = jwtDecode<AuthTokenPayload>(token);
        const response = await getUserPasswordVersion();

        if (decodedToken.passwordVersion !== response.passwordVersion) {
          localStorage.clear();
          window.location.href = '/login';
        }
      } catch (error) {
        console.error('Failed to fetch password version or check token', error);
      }
    };
    checkPasswordVersion();

    const interval = setInterval(checkPasswordVersion, 60000);

    return () => clearInterval(interval);
  }, []);
};

export default usePasswordVersionChecker;