import { useState } from "react";

export const useTooltip = (delay = 500) => {
  const [visible, setVisible] = useState(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();
  const [content, setContent] = useState("");

  const showTooltip = (
    e:
    | React.MouseEvent<HTMLDivElement, MouseEvent>
    | React.TouchEvent<HTMLDivElement>
  ) => {
    e.preventDefault();
    clearTimeout(timeoutId);
    const id = setTimeout(() => setVisible(true), delay);
    setTimeoutId(id);
  };

  const hideTooltip = (
    e:
    | React.MouseEvent<HTMLDivElement, MouseEvent>
    | React.TouchEvent<HTMLDivElement>
  ) => {
    clearTimeout(timeoutId);
    setVisible(false);
  };

  const eventHandlers = (newContent: string) => {
    const start = (e: React.MouseEvent<HTMLDivElement, MouseEvent> | React.TouchEvent<HTMLDivElement>) => {
      if (newContent !== content) {
        setContent(newContent);
        showTooltip(e);
      }
    };

    const stop = (e: React.MouseEvent<HTMLDivElement, MouseEvent> | React.TouchEvent<HTMLDivElement>) => {
      if (newContent === content) {
        setContent(newContent);
        hideTooltip(e);
      }
    };

    return {
      onContextMenu: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.preventDefault(),
      onMouseEnter: start,
      onMouseLeave: stop,
      onTouchStart: start,
      onTouchEnd: stop,
    };
  };

  const forceHide = () => setVisible(false);

  return {
    tooltipVisible: visible,
    tooltipContent: content,
    forceHideTooltip: forceHide,
    tooltipEventHandlers: eventHandlers,
  };
};
