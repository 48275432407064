import style from "./reviewQuestionnaireTableRow.module.scss";
import FolderIcon from "components/icons/folder";
import classNames from "classnames";
import { IntStatus, getStatusClass, getStatusLabel } from "utils/commonFunctions";
import { ReviewQuestionnaire } from "actions/questionnaireActions";

interface ReviewQuestionnaireTableRowProp {
  data: ReviewQuestionnaire;
  onClick?: () => void;
}

export const ReviewQuestionnaireTableRow = ({ data, onClick = () => undefined}: ReviewQuestionnaireTableRowProp) => {
  const statusClass = getStatusClass(data.intStatus as IntStatus, style);
  return (
    <>
      <tr className={style.barRow}>
        <td colSpan={5}/>
      </tr>
      <tr onClick={onClick} className={style.selectableRow}>
        <td>
          <div className={style.bubble}>{data.matterNo}</div>
        </td>
        <td>
          <div className={style.tableValueColumn}><FolderIcon/>{data.clientName}</div>
        </td>
        <td>
          <div className={style.tableValueColumn}>{data.matterDescription}</div>
        </td>
        <td>
          <div className={style.tableValueColumn}>{data.questionnaire}</div>
        </td>
        <td>
          <div className={classNames(statusClass, style.bubble)}>{getStatusLabel(data.intStatus)}</div>
        </td>
      </tr>
    </>
  );
};
