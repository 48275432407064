import { FC } from 'react';
import styles from './simpleLayout.module.scss';



const SimpleLayout : FC = ({children}) => {
  return (<div className={styles.layoutParent}>
    <div className={styles.rightContent}>
      {children}
    </div>
  </div>);
};

export default SimpleLayout;
