import { useState } from 'react';
import { forgotPassword,  } from 'actions/userActions';
import { withRouter, useLocation, useHistory } from 'react-router-dom';
import { Button } from 'components/UI/button/button';
import styles from './newPasswordForm.module.scss';
import { useForm } from "react-hook-form";
import PasswordComponent from 'components/UI/passwordComponent/passwordComponent';
import { RoutePaths } from 'app/routing/routing';
import { useSetAppState } from 'context';
import classNames from 'classnames';
import { passwordValidationRegex } from 'utils/regexValidations';

export interface PasswordFieldProps {
  password: string;
  passwordRetry: string;
}

export const NewPasswordFormComponent = () => {
  const { register, handleSubmit, setValue, formState: { errors }, setError, clearErrors } = useForm<PasswordFieldProps>();
  const [showForgotPasswordMessage, setShowForgotPasswordMessage] = useState<string | null>(null);
  const [isMessageError, setIsMessageError] = useState<boolean>(false);
  const location = useLocation();
  const history = useHistory();
  const setAppState = useSetAppState();

  const getLastSegment = (pathname: string): string => {
    const segments = pathname.split('/').filter(Boolean);
    return segments.length > 0 ? segments[segments.length - 1] : '';
  };

  const lastUrlSegment = getLastSegment(location.pathname);

  const onSubmit = async (values: PasswordFieldProps) => {
    clearErrors();
    setShowForgotPasswordMessage("");
    if (values.password !== values.passwordRetry) {
      setError('passwordRetry', {
        type: "required",
        message: `Your passwords don't match. Please try again.`
      });
      return;
    }
    try{
      const forgotPasswordResult: any = await forgotPassword({password: values.password, resetKey: lastUrlSegment});
      setAppState({ Authenticated: false, Authenticating: true, LoginResult: forgotPasswordResult });
      if (forgotPasswordResult) {
        history.push(RoutePaths.TwoFactor);
      } else {
        setShowForgotPasswordMessage("Password update failed");
        setIsMessageError(false);
      }
    } catch (error: any) {
      setShowForgotPasswordMessage(error.message);
      setIsMessageError(true);
    }
  };

  const passwordValidationSchema = { 
    required: "Password is required",
    minLength: {
      value: 8,
      message: "Password must be at least 8 characters long",
    },
    maxLength: {
      value: 50,
      message: "Password must not exceed 50 characters",
    },
    pattern: {
      value: passwordValidationRegex,
      message: "Password must be at least 8 characters long, contain at least one number, one special character, no spaces, and at least one uppercase letter",
    },
  };
  
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.newPassForm}>
      <div className={styles.instructionsCenter}>
        Your new password must be:
      </div>
      <div className={styles.instructionBullets}>
        <ul>
          <li>Different from previously used passwords.</li>
          <li>At least 8 characters long, with at least one number, one special character, and one upper case character.</li>
        </ul>
      </div>
      <PasswordComponent
        register={register}
        name="password"
        placeholder="••••••••"
        className={styles.field}
        label="New Password"
        errors={errors}
        validationSchema={passwordValidationSchema}
      />

      <PasswordComponent
        register={register}
        name="passwordRetry"
        placeholder="••••••••"
        className={styles.field}
        label="Re-enter New Password"
        errors={errors}
        validationSchema={passwordValidationSchema}
      />

      <Button className={styles.submitButton} type="submit">
        Save Password
      </Button>
      
      <div className={classNames(styles.messageDiv, isMessageError ? styles.error : styles.success)}>
        {showForgotPasswordMessage}
      </div>
    </form>
  );
};

export const NewPasswordForm = withRouter(NewPasswordFormComponent);
