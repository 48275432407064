import * as base from "./lib/baseActions";
import { apiEndpoints } from "config/config";
import { is } from "cypress/types/bluebird";
import useSWR from "swr";

export interface layers {
  userID: string;
  usrFullName: string;
}

export interface LawyerOption {
  id: string;
  label: string;
}

const getLawyers = async (): Promise<LawyerOption[]> => {
  try {
    const response = await base.get(apiEndpoints.lawyers);
    if (!Array.isArray(response.data) || response.data.length === 0) {
      throw new Error("Lawyers data is empty or not in expected format.");
    }
    const lawyersData = response.data[0];
    if (!Array.isArray(lawyersData)) {
      throw new Error("Lawyers data is not an array.");
    }

    const formattedData = lawyersData
      .filter((lawyer: { usrID: number | null; usrFullName: string }) => lawyer.usrID !== null)
      .map((lawyer: { usrID: number | null; usrFullName: string }) => ({
        id: String(lawyer.usrID),
        label: lawyer.usrFullName,
      }));

    return formattedData;
  } catch (error) {
    console.error("Error fetching lawyers:", error);
    throw error; 
  }
};

const useGetLawyers = () => {
  const key = apiEndpoints.lawyers;
  const { data, error, isLoading } = useSWR<LawyerOption[]>(key, getLawyers);
  return { data, error, key, isLoading };
};

export {
  useGetLawyers,
};
