const ArrowDown = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.87039 6.49023L2.10039 8.26023L12.0004 18.1602L21.9004 8.26023L20.1304 6.49023L12.0004 14.6202L3.87039 6.49023Z" fill="#2A3192"/>
    </svg>
  );
};
  
export default ArrowDown;
  
