import DropDownSelect from 'components/UI/dropDownSelect/dropDownSelect';
import DatePicker from 'components/UI/DatePicker/datePicker';
import { Checkbox } from 'components/UI/checkbox/checkbox';
import { Input } from 'components/UI/input/input';
import FileUpload, { DocumentAdditionParams, DocumentDeletionParams } from 'components/UI/FileUpload/fileUpload';
import { getFieldDocumentNames, IntStatus, isComments, parseDateString } from 'utils/commonFunctions';
import { MultilineInput } from 'components/UI/multilineInput/multilineInput';
import { QuestionDataType } from 'components/questionnaire/questionnaireComponents/question/question';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { Label } from 'components/UI/label/label';
import { DocumentNames } from 'actions/documentActions';

interface RenderQuestionFieldProps {
  id: string;
  question: QuestionDataType | undefined;
  handleQuestionChange: (newValue: string) => void;
  errors: FieldErrors<any>;
  locked: boolean;
  lockClass?: string;
  valueColorClass?: string;
  placeholderColorClass?: string;
  dropdownWrapperClass?: string;
  isReview?: boolean;
  isTablePreviewQuestion?: boolean;
  register: UseFormRegister<any>;
  documentNames?: DocumentNames;
  addDocuments?: (params: DocumentAdditionParams) => void;
  deleteDocument?: (params: DocumentDeletionParams) => void;
  showErrorStyle?: boolean;
  onClickDocument?: (documentName: string, documentPath: string) => void;
  intStatus?: IntStatus;
}

export enum QuestionTypes {
  textbox = 'TEXTBOX',
  multiline = 'MULTILINE',
  dropdown = 'DROPDOWN',
  checkbox = 'CHECKBOX',
  document = 'DOCUMENT',
  datetime = 'DATE',
  label = 'LABEL',
}

const QuestionBaseComponent = ({ 
  id,
  question, 
  handleQuestionChange, 
  errors, 
  locked, 
  lockClass, 
  valueColorClass, 
  placeholderColorClass, 
  dropdownWrapperClass,
  isReview = false,
  register, 
  isTablePreviewQuestion,
  documentNames,
  addDocuments,
  deleteDocument,
  showErrorStyle,
  onClickDocument,
  intStatus
}: RenderQuestionFieldProps) => {
  if (!question) return <div>question object is undefined</div>;
  switch (question.fieldtype) {
    case QuestionTypes.textbox:
      return (
        <Input
          id={id}
          label={question.label}
          placeholder={question.label}
          value={question.value}
          onChange={handleQuestionChange}
          validationSchema={question.required ? { required: `${question.label} is required` } : undefined}
          errors={errors}
          locked={locked}
          containerClass={locked ? lockClass : ''}
          isTablePreviewQuestion={isTablePreviewQuestion}
          accepted={question.accepted !== false || intStatus === IntStatus.PendingReview}
          showErrorStyle={showErrorStyle}
        />
      );

    case QuestionTypes.multiline:
      return (
        <MultilineInput
          id={id}
          label={question.label}
          placeholder={question.label}
          value={question.value}
          onChange={handleQuestionChange}
          validationSchema={question.required ? { required: `${question.label} is required` } : undefined}
          errors={errors}
          locked={locked}
          containerClass={locked ? lockClass : ''}
          rows={6}
          isReview={isReview}
          isComments={isComments(question.label)}
          accepted={question.accepted !== false || intStatus === IntStatus.PendingReview}
          showErrorStyle={showErrorStyle}
        />
      );

    case QuestionTypes.dropdown:
      return (
      // TODO: We shouldn't be specifying a specific feature style for selectClass
      // meaning we should be passing a generic style class instead of passing JUST lockClass into selectClass
      // TODO: It's not good practice to be passing the same class to two different props
      // when they could be functioning differently
        <DropDownSelect
          id={id}
          title={question.label}
          placeholder="Select an option"
          startingValue={question.value}
          onChange={handleQuestionChange}
          options={(question?.drop_down || []).map((dd) => ({ value: dd?.cdcode || "", label: dd?.cddesc || "" }))}
          locked={locked}
          errors={errors}
          selectClass={locked ? lockClass : ''}
          dropDownClass={isTablePreviewQuestion ? "questionnaireTable" : "questionnaire" }
          isTablePreviewQuestion={isTablePreviewQuestion}
          accepted={question.accepted !== false || intStatus === IntStatus.PendingReview}
          showErrorStyle={showErrorStyle}
        />
      );

    case QuestionTypes.datetime:
      return (
      // TODO: We shouldn't be specifying a specific feature style for selectClass
      // meaning we should be passing a generic style class instead of passing JUST lockClass into selectClass
        <DatePicker
          id={id}
          label={question.label}
          value={parseDateString(question.value)}
          register={register}
          onChange={handleQuestionChange}
          errors={errors}
          locked={locked}
          className={locked ? lockClass : ''}
          isTablePreviewQuestion={isTablePreviewQuestion}
          accepted={question.accepted !== false || intStatus === IntStatus.PendingReview}
          showErrorStyle={showErrorStyle}
        />
      );

    case QuestionTypes.document:
      // TODO: We shouldn't be specifying a specific feature style for inputClass
      // meaning we should be passing a generic style class instead of passing JUST lockClass into inputClass
      return (
        <FileUpload
          id={id}
          label={question.label}
          locked={locked}
          inputClass={locked ? lockClass : ''}
          register={register}
          valueColorClass={valueColorClass}
          placeholderColorClass={placeholderColorClass}
          errors={errors}
          documentNames={getFieldDocumentNames(documentNames, id)}
          addDocuments={addDocuments}
          deleteDocument={deleteDocument}
          accepted={question.accepted !== false || intStatus === IntStatus.PendingReview}
          showErrorStyle={showErrorStyle}
          onClickDocument={onClickDocument}
        />
      );

    case QuestionTypes.checkbox:
      return (
      // TODO: We shouldn't be specifying a specific feature style for selectClass
      // meaning we should be passing a generic style class instead of passing JUST lockClass into selectClass
        <Checkbox
          id={id}
          label={question.label}
          value={question.value === `true`}
          onChange={handleQuestionChange}
          locked={locked}
          className={locked ? lockClass : ''}
          errors={errors}
          isTablePreviewQuestion={isTablePreviewQuestion}
          accepted={question.accepted !== false || intStatus === IntStatus.PendingReview}
        />
      );
    case QuestionTypes.label:
      return (
      // TODO: We shouldn't be specifying a specific feature style for selectClass
      // meaning we should be passing a generic style class instead of passing JUST lockClass into selectClass
        <Label
          id={id}
          label={question.label}
        />
      );

    default:
      return <div>Unsupported question type: {question.fieldtype}</div>;
  }
};

export default QuestionBaseComponent;
