const BackArrowIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={40}
      height={40}
      fill="none"
      {...props}
    >
      <path
        fill="#5E6366"
        d="M30.708 19.042h-16.29l7.117-7.117a1.47 1.47 0 0 0 0-2.071 1.452 1.452 0 0 0-2.057 0l-9.61 9.61a1.452 1.452 0 0 0 0 2.057l9.61 9.61a1.452 1.452 0 0 0 2.057 0 1.452 1.452 0 0 0 0-2.056l-7.117-7.117h16.29c.802 0 1.458-.656 1.458-1.458 0-.802-.656-1.458-1.458-1.458Z"
      />
    </svg>
  );
};

export default BackArrowIcon;