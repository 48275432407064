const DownLoadArrow = ({ ...props }) => {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.79545 4.16659V13.4749L5.4497 9.40825C5.10239 9.08325 4.53246 9.08325 4.18515 9.40825C3.83785 9.73325 3.83785 10.2583 4.18515 10.5833L10.0537 16.0749C10.401 16.3999 10.962 16.3999 11.3093 16.0749L17.1868 10.5916C17.5341 10.2666 17.5341 9.74159 17.1868 9.41659C16.8395 9.09159 16.2785 9.09159 15.9312 9.41659L11.5765 13.4749V4.16659C11.5765 3.70825 11.1758 3.33325 10.686 3.33325C10.1962 3.33325 9.79545 3.70825 9.79545 4.16659Z"
        fill="white"
      />
    </svg>
  );
};

export default DownLoadArrow;
