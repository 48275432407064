const HideIcon = ({ ...props }) => (
  <svg 
    width="13" 
    height="12" 
    viewBox="0 0 13 12" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g opacity="0.5">
      <path 
        fillRule="evenodd" 
        clipRule="evenodd" 
        d="M5.07225 8.02154C5.47815 8.31049 5.97285 8.48521 6.49926 8.48521C7.89456 8.48521 9.02983 7.28236 9.02983 5.80401C9.02983 5.24626 8.86493 4.72212 8.59221 4.29205L7.9009 5.02451C8.01506 5.25298 8.07848 5.52177 8.07848 5.80401C8.07848 6.72462 7.36815 7.47724 6.49926 7.47724C6.23288 7.47724 5.97919 7.41004 5.76355 7.28908L5.07225 8.02154ZM10.6787 2.08146C11.5984 2.96847 12.3785 4.1646 12.962 5.60263C13.0127 5.73031 13.0127 5.87815 12.962 5.9991C11.6047 9.34556 9.18829 11.3481 6.49917 11.3481H6.49283C5.26877 11.3481 4.09545 10.9247 3.06165 10.1519L1.83125 11.4556C1.73612 11.5564 1.61562 11.6034 1.49511 11.6034C1.37461 11.6034 1.24776 11.5564 1.15897 11.4556C1.00041 11.2876 0.975045 11.0188 1.10189 10.8239L1.12092 10.797L10.5011 0.858455L10.5012 0.858449C10.5138 0.845011 10.5265 0.831573 10.5329 0.818136L10.5329 0.818129C10.5455 0.804692 10.5582 0.791255 10.5646 0.777817L11.1607 0.146156C11.351 -0.0487186 11.6491 -0.0487186 11.833 0.146156C12.0233 0.34103 12.0233 0.66358 11.833 0.858455L10.6787 2.08146ZM3.96392 5.80679C3.96392 5.98151 3.98295 6.15622 4.00832 6.3175L1.66168 8.80382C1.02745 8.02433 0.475671 7.08356 0.0380537 6.00167C-0.0126846 5.88071 -0.0126846 5.73288 0.0380537 5.6052C1.3953 2.25874 3.81171 0.262958 6.49449 0.262958H6.50083C7.40778 0.262958 8.28936 0.491431 9.10117 0.921499L6.98285 3.16591C6.83063 3.13903 6.66573 3.11887 6.50083 3.11887C5.09919 3.11887 3.96392 4.32172 3.96392 5.80679Z" 
        fill="#030229"
      />
    </g>
  </svg>
);
export default HideIcon;