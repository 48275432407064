import * as base from "./lib/baseActions";
import { apiEndpoints } from "config/config";
import useSWR from "swr";

export type DropdownOptions = {
  value: string;
  label: string;
}

export type CountryOptions = {
  value: string;
  label: string;
  hasProvinceOrState: boolean;
  provinceOrStates: Array<DropdownOptions>;
};

export type StateAndProvinceOptions = {
  [key: string]: Array<DropdownOptions>;
  US: Array<DropdownOptions>;
  CAN: Array<DropdownOptions>;
};

const getCountries = async (): Promise<CountryOptions[]> => {
  const result = await base.get(`${apiEndpoints.locations}/countries`);
  return result.data;
};

const useGetCountries = () => {
  const { data, error, isLoading } = useSWR(`${apiEndpoints.locations}/countries`, () => getCountries());
  return { data, error, isLoading };
};

const getStatesAndProvinces = async (): Promise<StateAndProvinceOptions> => {
  const result = await base.get(`${apiEndpoints.locations}/statesAndProvinces`);
  return result.data;
};

const useGetStatesAndProvinces = () => {
  const { data, error, isLoading } = useSWR(`${apiEndpoints.locations}/statesAndProvinces`, () => getStatesAndProvinces());
  return { data, error, isLoading };
};

export {
  useGetCountries,
  useGetStatesAndProvinces
};