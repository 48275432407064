import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import style from './reviewApplicationForm.module.scss';
import newAppStyle from 'components/newApplicationForm/newApplicationForm.module.scss';
import { Button } from 'components/UI/button/button';
import DropDownSelect from 'components/UI/dropDownSelect/dropDownSelect';
import DatePicker from 'components/UI/DatePicker/datePicker';
import FileUpload from 'components/UI/FileUpload/fileUpload';
import { Input } from "components/UI/input/input";
import { ApplicationFormFields } from 'components/newApplicationForm/newApplicationForm';
import { MultilineInput } from 'components/UI/multilineInput/multilineInput';
import { NewFileRequestParams, getApplicationRequest } from 'actions/applicationsActions';
import { DateFormat, IntStatus, formatDateToString, getFieldDocumentNames, parseDateString } from 'utils/commonFunctions';
import { useGetCountries } from 'actions/locationsActions';
import { DocumentName, DocumentNames, SMBDocument, getDocumentNamesFromPaths, getSMBDocument } from 'actions/documentActions';
import { LoadingContainer } from 'components/UI/loadingContainer/loadingContainer';
import { Modal } from 'components/modal/modal';
import { formatFilePath } from 'components/documentCard/documentCard.utils';
import { formatImage } from 'utils/imageFunctions';
import { useGetLawyers } from 'actions/lawyerActions';
import { OptionItem } from 'components/UI/singleFilterDropDown/singleFilterDropDown';
import { getCaseAnalysts } from 'actions/caseAnalystActions';
import { getDepartments } from 'actions/departmentActions';
import { getMatterTypes } from 'actions/matterTypeActions';
import { use } from 'chai';

export interface ReviewApplicationFormProps {
  onSubmitForm: (data: NewFileRequestParams) => Promise<void>;
  recordID: string;
  closeModal: () => void;
  setHideModalHeader: (value: boolean) => void;
} 

const titleOptions = [
  { value: 'Mr.', label: 'Mr.' },
  { value: 'Ms.', label: 'Ms.' },
  { value: 'Mrs.', label: 'Mrs.' },
  { value: 'Mx.', label: 'Mx.' },
  { value: 'Dr.', label: 'Dr.' }
];


export const ReviewApplicationForm = ({ 
  onSubmitForm, 
  recordID, 
  closeModal,
  setHideModalHeader,
}: ReviewApplicationFormProps) => {
  const { register, handleSubmit, formState: { errors }, setValue, trigger, getValues, setError, clearErrors } = useForm<ApplicationFormFields>();
  const [initialReviewStatus, setInitialReviewStatus] = useState<IntStatus>(IntStatus.PendingReview);
  const [reviewStatus, setReviewStatus] = useState<IntStatus>(IntStatus.PendingReview);
  const [clientID, setClientID] = useState<string>("");
  const [dateFormatBirthday, setDateFormatBirthday] = useState<Date>();
  const [provinceLabel, setProvinceLabel] = useState<string>('');
  const [documentNames, setDocumentNames] = useState<DocumentNames>({ localDocuments: [], uploadedDocuments: [] } as DocumentNames);
  const [isLockedApplication, setIsLockedApplication] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedDocumentName, setSelectedDocumentName] = useState<string>("");
  const [documentModalOpen, setDocumentModalOpen] = useState<boolean>(false);
  const [smbDocument, setSmbDocument] = useState<SMBDocument | null>(null);
  const [documentIsLoading, setDocumentIsLoading] = useState<boolean>(false);
  const { data: lawyers } = useGetLawyers();
  const lawyerOptions: OptionItem[] = lawyers?.map(lawyer => ({
    label: lawyer.label,
    value: lawyer.id
  })) ?? [];
  const [caseAnalystOptions, setCaseAnalystOptions] = useState<OptionItem[]>([]);
  const [departmentOptions, setDepartmentOptions] = useState<OptionItem[]>([]);
  const [matterTypeOptions, setMatterTypeOptions] = useState<OptionItem[]>([]);
  const [departmentState, setDepartmentState] = useState<string>('');
  const [isLoadingMatterTypes, setIsLoadingMatterTypes] = useState(false);


  const { data: countryOptions, isLoading: countryOptionsIsLoading } = useGetCountries();

  const handleFormValueChange = (name: string, value: string) => {
    if (name === "department") {
      setDepartmentState(value);
      setValue("matterType", "null");
      clearErrors("matterType");
    }
    setValue(name as keyof ApplicationFormFields, value);
    clearErrors(name as keyof ApplicationFormFields);
  };

  useEffect(() => {
    const fetchApplication = async () => {
      try {
        setIsLoading(true);
        const application = await getApplicationRequest(`${recordID}`);
        setValue("textRequestDetails", application.textRequestDetails);
        setValue("fullName", application.txtFullName);
        setValue("lastName", application.txtLastName);
        setValue("firstName", application.txtFirstName);
        setValue("birthday", formatDateToString(parseDateString(application.dteDob), DateFormat.YYYY_MM_DD_dashes));
        setValue("nationality", application.txtNationality);
        setValue("passportNumber", application.txtPassportNum);
        setValue("title", application.txtTitle);
        setValue("streetName", application.txtStreetName);
        setValue("unitNumber", application.txtUnitNumber);
        setValue("town", application.txtTownCity);
        setValue("province", application.txtProvinceState);
        setValue("postal", application.txtPostalCode);
        setValue("country", application.countryId);
        setValue("email", application.txtEmail);
        setValue("phone", application.txtPhoneNumber);
        setValue("comments", application.txtComments);
        setValue("assignedLawyer", application.assignedLawyer);
        setValue("department", application.department);
        setValue("matterType", application.matterType);
        setValue("caseAnalyst", application.caseAnalyst);
        setDepartmentState(application.department);

        setDocumentNames(await getDocumentNames(application));

        if (application.intStatus) {
          const intStatus = parseInt(application.intStatus, 10);
          setInitialReviewStatus(intStatus);
          setReviewStatus(intStatus);
          setIsLockedApplication(intStatus === IntStatus.Approved);
        }
        setClientID(application.clId);
        setDateFormatBirthday(parseDateString(application.dteDob));
      } catch (err) {
        console.error("error fetching application: ", err);
      } finally {
        setIsLoading(false);
      }
    };
    const fetchCaseAnalysts = async () => {
      try {
        const caseAnalysts = await getCaseAnalysts();
        setCaseAnalystOptions(caseAnalysts);
      } catch (error) {
        console.error("Error fetching case analysts: ", error);
      }
    };

    const fetchDepartments = async () => {
      try {
        const departments = await getDepartments();
        setDepartmentOptions(departments);
      } catch (error) {
        console.error("Error fetching departments: ", error);
      }
    };

    fetchDepartments();
    fetchCaseAnalysts();
    fetchApplication();
  }, []);

  useEffect(() => {
    const fetchMatterTypes = async () => {
      try {
        setIsLoadingMatterTypes(true);
        const matterTypes = await getMatterTypes(getValues("department"));
        setMatterTypeOptions(matterTypes);
      } catch (error) {
        console.error("Error fetching matter types: ", error);
      }
      finally {
        setIsLoadingMatterTypes(false);
      }
    };
    fetchMatterTypes();
  }, [departmentState]);


  useEffect(() => {
    setHideModalHeader(isLoading || isSubmitting || countryOptionsIsLoading);
  }, [isLoading, isSubmitting, countryOptionsIsLoading]);

  const getDocumentNames = async (apiResult: any): Promise<DocumentNames> => {
    
    // fieldId -> comma seperated document paths
    const dataMap = [
      ["passportUpload", apiResult.txtPassportBio],
      ["postSecondaryUpload", apiResult.txtEducationDocs],
      ["resumeUpload", apiResult.txtResume],
      ["canadianUpload", apiResult.txtCadStatusDoc],
      ["marriageUpload", apiResult.txtMarriageCert],
      ["familyPassportUpload", apiResult.txtFamilyPassBio],
      ["familyBirthCertUpload", apiResult.txtBirthCerts],
    ];

    const rawUploadedDocumentNames = dataMap.reduce<DocumentName[]>((acc, data) => {
      const [fieldId, txtArray] = data;
      const paths: string[] = txtArray ? txtArray.split(',') : [];

      const newDocuments: DocumentName[] = paths.map(path => {
        return {
          name: path,
          fieldId: fieldId,
          path: path,
          permanent: Number(apiResult.intStatus) === IntStatus.Approved,
        } as DocumentName;
      });
      return [...acc, ...newDocuments];
    }, []);

    const paths = rawUploadedDocumentNames.map(rawDoc => rawDoc.name);
    const names = paths.length > 0 ? await getDocumentNamesFromPaths(paths) : [];
    
    const uploadedDocuments = rawUploadedDocumentNames.map((rawDoc, index) => {
      return {
        ...rawDoc,
        name: names[index]
      };
    });

    return {
      localDocuments: [],
      uploadedDocuments
    };
  };

  const onClickReject = () => {
    setReviewStatus(IntStatus.Rejected);
  };

  const onClickApprove = () => {
    if (!getValues("department")){
      setError("department", { type: "required", message: "Department is required" });
    }

    if (!getValues("matterType")){
      setError("matterType", { type: "required", message: "Matter Type is required" });
    }

    if (!getValues("assignedLawyer")){
      setError("assignedLawyer", { type: "required", message: "Assigned Lawyer is required" });
    }

    if (!getValues("caseAnalyst")){
      setError("caseAnalyst", { type: "required", message: "Case Analyst is required" });
    }

    if (errors.department || errors.matterType || errors.assignedLawyer || errors.caseAnalyst){
      return;
    }
    setReviewStatus(IntStatus.Approved);
  };

 

  const onClickDocument = async (documentName: string, documentPath: string) => {
    try {
      setDocumentIsLoading(true);
      setSelectedDocumentName(documentName);
      setDocumentModalOpen(true);
      
      const formattedPath = formatFilePath(documentPath);
      const newSmbDocument = await getSMBDocument(formattedPath, true);
      setSmbDocument(newSmbDocument);
    } catch (error) {
      console.error("Failed to load document:", error);
    } finally {
      setDocumentIsLoading(false);
    }
  };


  const onSubmit = async (review: ApplicationFormFields) => {
    if (isLockedApplication) {
      closeModal();
      return;
    }
    setIsSubmitting(true);
    const data: NewFileRequestParams = {
      clientID: clientID,
      formFields: review,
      reviewStatus: reviewStatus,
      recordID: recordID
    };
    await onSubmitForm(data);
    setIsSubmitting(false);
  };

  useEffect(() => {
    const selectedCountry = countryOptions?.find(
      (country) => country.value === getValues('country')
    );
    const hasProvinces = selectedCountry?.hasProvinceOrState || false;

    if (hasProvinces) {
      setProvinceLabel(selectedCountry?.provinceOrStates.find((provOrState) => provOrState.value == getValues('province'))?.label || '');
    }
    else {
      setProvinceLabel(getValues('province'));
    }
  },);
  
  if (isLoading) {
    return <LoadingContainer loadingLabel='Loading'/>;
  }
  if (isSubmitting) {
    return <LoadingContainer loadingLabel={initialReviewStatus === reviewStatus ? 'Saving' : 'Your review is being submitted'}/>;
  }

  const image = formatImage(smbDocument?.format, smbDocument?.image);

  return (
    <div className={newAppStyle.formContent}>
      <form onSubmit={handleSubmit(onSubmit)} className={newAppStyle.applicationForm}>

        <div className={newAppStyle.formSection}>
          <div className={newAppStyle.title}>
            <h4><span>1</span> What Can We Help You With?</h4>
          </div>

          <div className={newAppStyle.row}>
            <MultilineInput
              id="textRequestDetails"
              label=""
              value={getValues("textRequestDetails")}
              onChange={(value) => setValue("textRequestDetails", value)}
              placeholder="Details about the application"
              inputClass={style.inputField}
              rows={6}
              isReview={true}
              isComments={false}
              locked={true}
            />
          </div>
        </div>

        <div className={newAppStyle.formSection}>
          <div className={newAppStyle.title}>
            <h4><span>2</span> Applicant Details</h4>
          </div>
          <div className={newAppStyle.row}>
            <div className={newAppStyle.rowColumnOne}>
              <DropDownSelect
                id="title" 
                locked={true}
                options={titleOptions}
                startingValue={getValues("title")}
                title="Title"   
              />
            </div>
            <div className={newAppStyle.rowColumnTwo}>
              <Input
                id="firstName" 
                locked={true}
                label="First Name"
                value={getValues("firstName")}
                inputClass={newAppStyle.inputField}
                labelClass={newAppStyle.labels} 
              />
            </div>
            <div className={newAppStyle.rowColumnTwo}>
              <Input
                id="lastName" 
                locked={true}
                label="Last Name"
                value={getValues("lastName")}
                inputClass={newAppStyle.inputField} 
                labelClass={newAppStyle.labels} 
              />
            </div>
          </div>
          <div className={newAppStyle.row}>
            <div className={newAppStyle.rowColumnOne}>
              <DatePicker
                id="birthday"
                locked={true}
                label="Date of Birth"
                value={dateFormatBirthday}
                className={newAppStyle.inputField}
                maxDate={new Date()}
                minDate={new Date(new Date().setFullYear(new Date().getFullYear() -100))}
              />
            </div>
            <div className={newAppStyle.rowColumnTwo}>
              <DropDownSelect 
                id="nationality"
                locked={true}
                options={countryOptions || []}
                startingValue={getValues("nationality")}
                title="Nationality"
              />
            </div>
            <div className={newAppStyle.rowColumnTwo}>
              <Input
                id="passportNumber" 
                locked={true}
                label="Passport Number"
                value={getValues("passportNumber")}
                inputClass={newAppStyle.inputField} 
                labelClass={newAppStyle.labels} 
              />
            </div>
          </div>
        </div>
        
        <div className={newAppStyle.formSection}>
          <div className={newAppStyle.title}>
            <h4><span>3</span> Applicant Contact Details</h4>
          </div>
          <div className={newAppStyle.row}>
            <div className={newAppStyle.rowColumnTwo}>
              <Input
                id="streetName" 
                locked={true}
                label="Street Name"
                value={getValues("streetName")}
                inputClass={newAppStyle.inputField}
                labelClass={newAppStyle.labels} 
              />
            </div>
            <div className={newAppStyle.rowColumnTwo}>
              <Input 
                id="unitNumber" 
                locked={true}
                label="Unit Number"
                value={getValues("unitNumber")}
                inputClass={newAppStyle.inputField} 
                labelClass={newAppStyle.labels} 
              />
            </div>
          </div>

          <div className={newAppStyle.row}>
            <div className={newAppStyle.rowColumnTwo}>
              <Input
                id="town" 
                locked={true}
                label="Town/City"
                register={register} 
                value={getValues("town")}
                inputClass={newAppStyle.inputField} 
                labelClass={newAppStyle.labels} 
              />
            </div>
            <div className={newAppStyle.rowColumnTwo}>
              <Input 
                id="province" 
                locked={true}
                label="State/Province"
                value={provinceLabel}
                inputClass={newAppStyle.inputField} 
                labelClass={newAppStyle.labels}
              />
            </div>
          </div>

          <div className={newAppStyle.row}>
            <div className={newAppStyle.rowColumnTwo}>
              <Input
                id="postal" 
                locked={true}
                label="Zip/Postal"
                value={getValues("postal")}
                inputClass={newAppStyle.inputField} 
                labelClass={newAppStyle.labels} 
              />
            </div>
            <div className={newAppStyle.rowColumnTwo}>
              <DropDownSelect 
                id="country"
                locked={true}
                options={countryOptions || []}
                startingValue={getValues('country')}
                title="Country"   
              />
            </div>
          </div>

          <div className={newAppStyle.row}>
            <div className={newAppStyle.rowColumnTwo}>
              <Input
                id="email" 
                locked={true}
                label="Email"
                value={getValues("email")}
                inputClass={newAppStyle.inputField} 
                labelClass={newAppStyle.labels} 
              />
            </div>
            <div className={newAppStyle.rowColumnTwo}>
              <Input
                id="phone" 
                locked={true}
                label="Telephone"
                value={getValues("phone")}
                inputClass={newAppStyle.inputField} 
                labelClass={newAppStyle.labels} 
                type='tel'
              />
            </div>
          </div>
        </div>
        
        <div className={newAppStyle.formSection}>
          <div className={newAppStyle.title}>
            <h4><span>4</span> Applicant Documents</h4>
          </div>
          <div className={newAppStyle.row}>
            <div className={newAppStyle.fileColumn}>
              <FileUpload
                id="passportUpload"
                locked={true}
                label="Passport Bio Page (for all countries of Citizenship)"
                className={newAppStyle.fileUpload}
                documentNames={getFieldDocumentNames(documentNames, "passportUpload")}
                onClickDocument={onClickDocument}
              />
            </div>
            <div className={newAppStyle.fileColumn}>
              <FileUpload
                id="postSecondaryUpload"
                locked={true}
                label="Post-Secondary Education Docs/Certificate"
                className={newAppStyle.fileUpload}
                documentNames={getFieldDocumentNames(documentNames, "postSecondaryUpload")}
                onClickDocument={onClickDocument}
              />
            </div>
          </div>

          <div className={newAppStyle.row}>
            <div className={newAppStyle.fileColumn}>
              <FileUpload
                id="resumeUpload"
                locked={true}
                label="Resume"
                subLabel="Include all work history, job titles, description of duties, locations, dates and if employment was full-time/part-time."
                className={newAppStyle.fileUpload}
                subLabelClass={newAppStyle.fileUploadSubtext}
                documentNames={getFieldDocumentNames(documentNames, "resumeUpload")}
                onClickDocument={onClickDocument}
              />
            </div>
            <div className={newAppStyle.fileColumn}>
              <FileUpload
                id="canadianUpload"
                locked={true}
                label="Canadian Status Document (if applicable)"
                subLabel="Include all current and/or previous, such as Work Permits, Study Permits, TRVs or Business Visas."
                className={newAppStyle.fileUpload}
                subLabelClass={newAppStyle.fileUploadSubtext}
                documentNames={getFieldDocumentNames(documentNames, "canadianUpload")}
                onClickDocument={onClickDocument}
              />
            </div>
          </div>

          <div className={newAppStyle.marriage}>
            <FileUpload
              id="marriageUpload"
              locked={true}
              label="Marriage Certificate (if applicable)"
              className={newAppStyle.fileUpload}
              documentNames={getFieldDocumentNames(documentNames, "marriageUpload")}
              onClickDocument={onClickDocument}
            />
          </div>

          <div className={newAppStyle.rowColumn}>
            <p className={newAppStyle.familyMembers}>Accompanying Family Members' Documents</p>
          </div>

          <div className={newAppStyle.row}>
            <div className={newAppStyle.fileColumn}>
              <FileUpload
                id="familyPassportUpload"
                locked={true}
                label="Passport Bio Pages (for all family members)"
                className={newAppStyle.fileUpload}
                documentNames={getFieldDocumentNames(documentNames, "familyPassportUpload")}
                onClickDocument={onClickDocument}
              />
            </div>
            <div className={newAppStyle.fileColumn}>
              <FileUpload
                id="familyBirthCertUpload"
                locked={true}
                label="Birth Certificates (for all dependant children)"
                className={newAppStyle.fileUpload}
                documentNames={getFieldDocumentNames(documentNames, "familyBirthCertUpload")}
                onClickDocument={onClickDocument}
              />
            </div>
          </div>
        </div>

        <div className={newAppStyle.formSection}>
          <div className={newAppStyle.title}>
            <h4><span>5</span>G&S Comments For Client</h4>
          </div>
          <div className={newAppStyle.row}>
            <div className={newAppStyle.rowColumnOne}>
              <MultilineInput 
                id="comments"
                label="Comments"
                value={getValues("comments")}
                onChange={(value) => handleFormValueChange("comments", value)}
                placeholder="Comments"
                inputClass={style.inputField}
                labelClass={style.labels}
                rows={6}
                isReview={true}
                isComments={true}
                locked={isLockedApplication}
              />
            </div>
          </div>
        </div>

        <div className={newAppStyle.formSection}>
          <div className={newAppStyle.title}>
            <h4><span>6</span>G&S Internal Use Only</h4>
          </div>
          <div className={newAppStyle.row}>
            <div className={newAppStyle.rowColumnOne}>
              <DropDownSelect
                id="department" 
                locked={isLockedApplication}
                options={departmentOptions}
                placeholder='Select Department'
                startingValue={getValues("department")}
                title="Department"   
                onChange={(value) => {handleFormValueChange("department", value);}}
                errors={errors}
              />
            </div>
            <div className={newAppStyle.rowColumnOne}>
              <DropDownSelect
                id="matterType" 
                locked={isLockedApplication}
                options={matterTypeOptions}
                title="Matter Type"
                placeholder='Select Matter Type'
                startingValue={getValues("matterType")}
                onChange={(value) => handleFormValueChange("matterType", value)}
                isLoading={isLoadingMatterTypes}
                errors={errors}                
              />
            </div>
          </div>
          <div className={newAppStyle.row}>
            <div className={newAppStyle.rowColumnOne}>
              <DropDownSelect
                id="assignedLawyer" 
                locked={isLockedApplication}
                options={lawyerOptions}
                placeholder='Select Assigned Lawyer'
                startingValue={getValues("assignedLawyer")}
                title="Assigned Lawyer"  
                onChange={(value) => handleFormValueChange("assignedLawyer", value)} 
                errors={errors}
              />
            </div>
            <div className={newAppStyle.rowColumnOne}>
              <DropDownSelect
                id="caseAnalyst" 
                locked={isLockedApplication}
                options={caseAnalystOptions}
                placeholder='Select Case Analyst'
                startingValue={getValues("caseAnalyst")}
                title="Case Analyst"  
                onChange={(value) => handleFormValueChange("caseAnalyst", value)}
                errors={errors}
              />
            </div>
          </div>
        </div>
        <div className={newAppStyle.rowColumn}>
          <div className={style.submitSection}>
            <Button 
              className={style.saveButton} 
              type="submit" 
              style='gradient'>
              {isLockedApplication ? 'Close' : 'Save & Close'}
            </Button>

            <Button 
              className={style.rejectButton} 
              type="submit" 
              style='gradient'
              onClick={onClickReject}
              disabled={isLockedApplication}>
              Reject
            </Button>

            <Button 
              className={style.approveButton} 
              type="submit" 
              style='gradient'
              onClick={onClickApprove}
              disabled={isLockedApplication}>
              Approve
            </Button>
          </div>
        </div>
      </form>
      <Modal
        id="reviewerDocumentCard"
        title={selectedDocumentName}
        show={documentModalOpen}
        onClose={() => setDocumentModalOpen(false)}
        className={style.documentModal}
      >
        {documentIsLoading ? <LoadingContainer loadingLabel='Loading Document'/> :
          <div className={style.modalContent}>{image}</div>
        }
      </Modal>
    </div>
  );
};