import { ReactNode, useState } from "react";
import style from "./dropDownTextBlock.module.scss";
import ArrowDown from "components/icons/arrowDown";
import ArrowRight from "components/icons/arrowRight";

interface InfoBlockProps {
  id?: string;
  title: string;
  children: ReactNode;
  dropDownClassName?: string;
  defaultOpen?: boolean;
}

export const DropDownTextBlock = ({
  id,
  title,
  children,
  dropDownClassName,
  defaultOpen = false,
}: InfoBlockProps) => {
  const [showText, setShowText] = useState<boolean>(defaultOpen);

  const toggleText = () => {
    setShowText(!showText);
  };

  return (
    <div id={id} className={dropDownClassName}>
      <div
        className={style.titleClass}
        onClick={toggleText}
      >
        <div className={style.titleIcon}>
          {showText ? <ArrowDown/> : <ArrowRight/>}
        </div>
        <div className={style.titleText}>
          {title}
        </div>
      </div>
      {showText && (
        <div className={ style.textBlockClass}>
          {children}
        </div>
      )}
    </div>
  );
};
