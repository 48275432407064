const BookmarkIcon = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#A8B0B9"
      d="M15 7v12.97l-4.21-1.81-.79-.34-.79.34L5 19.97V7h10Zm4-6H8.99C7.89 1 7 1.9 7 3h10c1.1 0 2 .9 2 2v13l2 1V3c0-1.1-.9-2-2-2Zm-4 4H5c-1.1 0-2 .9-2 2v16l7-3 7 3V7c0-1.1-.9-2-2-2Z"
      opacity={0.9}
    />
  </svg>
);
export default BookmarkIcon;