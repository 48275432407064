import { createContextStore } from './base';

export interface UserInfo {
  email?: string;
  firstName?: string;
  lastName?: string;
  userType?: string;
}

const initialState = {
  email: undefined,
  firstName: '',
  lastName: '',
  userType: ''
};

const [ Provider, useState, useSetState, useMergeState ] = createContextStore<UserInfo>(initialState);

export {
  Provider as UserContextProvider,
  useState as useUserState,
  useSetState as useSetUserState,
  useMergeState as useMergeUserState,
};
