import React from 'react';
import style from "./newPassword.module.scss";
import logo from 'assets/images/company-logo.png';
import NewPasswordGraphic from 'components/icons/loginGraphics/newPasswordGraphic';
import {NewPasswordForm} from "../../components/newPasswordForm/newPasswordForm";
import SimpleLayout from 'components/simpleLayout/simpleLayout';
import { LoginGraphicContainer } from 'components/loginGraphicContainer/loginGraphicContainer';

export const NewPassword = () => {
  return (
    <SimpleLayout>
      <div className={style.content}>
        <div className={style.form}>
          <img className={style.logo} src={logo}></img>
          <h4>New Password</h4>
          <NewPasswordForm/>
        </div>
        <LoginGraphicContainer Graphic={NewPasswordGraphic}/>
      </div>
    </SimpleLayout>
  );
};
