const FolderIcon = ({ ...props }) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.334 4.50008H8.00065L6.66732 3.16675H2.66732C1.93398 3.16675 1.34065 3.76675 1.34065 4.50008L1.33398 12.5001C1.33398 13.2334 1.93398 13.8334 2.66732 13.8334H13.334C14.0673 13.8334 14.6673 13.2334 14.6673 12.5001V5.83341C14.6673 5.10008 14.0673 4.50008 13.334 4.50008ZM13.334 12.5001H2.66732V4.50008H6.11398L7.44732 5.83341H13.334V12.5001ZM10.0007 9.16675C10.734 9.16675 11.334 8.56675 11.334 7.83341C11.334 7.10008 10.734 6.50008 10.0007 6.50008C9.26732 6.50008 8.66732 7.10008 8.66732 7.83341C8.66732 8.56675 9.26732 9.16675 10.0007 9.16675ZM7.33398 11.8334H12.6673V11.1667C12.6673 10.2801 10.8873 9.83341 10.0007 9.83341C9.11398 9.83341 7.33398 10.2801 7.33398 11.1667V11.8334Z"
      fill="#A8B0B9"
    />
  </svg>
);
export default FolderIcon;