import useSWR from "swr";
import * as base from "./lib/baseActions";
import { apiEndpoints } from "config/config";
import useSWRInfinite from "swr/infinite";


export interface ClientParams {
  clientId: string;
}
export interface Client {
  bitButtonVisible: number;
}

export interface siteSearch {
  searchQuery: string;
  rowNumber: number;
  pageNumber?: number;
}

const getClientById = async (params: ClientParams): Promise<Client> => {
  const { clientId } = params;
  const result = await base.get(`${apiEndpoints.client}/${clientId}`);
  return result.data;
};

const getSiteWideSearch = async (params: siteSearch): Promise<any> => {
  const result = await base.get(apiEndpoints.search, {params: params});
  return result.data;
};

const useGetSiteWideSearch = (params: siteSearch) => {
  const getKey = (pageIndex: number, previousPageData: any[] | null) => {
    if (previousPageData && previousPageData.length === 0) return null;
    return [apiEndpoints.search, { ...params, pageNumber: pageIndex + 1 }];
  };

  const fetcher = async ([url, params]: [string, siteSearch]) => {
    const response = await getSiteWideSearch(params);
    return response;
  };

  const { data, error, mutate, size, setSize, isValidating } = useSWRInfinite(getKey, fetcher, {
    revalidateFirstPage: false,
    keepPreviousData: false,
  });
  
  const searchData = data ? data.filter(d => d !== null).flat() : [];
  const hasMore = data && data[data.length - 1]?.length === params.rowNumber;

  return {
    data: searchData,
    error,
    mutate,
    isValidating,
    size,
    setSize,
    hasMore,
    noDataFound: searchData && searchData.length === 0 || !searchData,
  };
};


export {
  getClientById,
  useGetSiteWideSearch,
};