import * as base from "./lib/baseActions";
import { apiEndpoints } from "config/config";

type DepartmentOptions = {
  value: string;
  label: string;
}

const getDepartments = async (): Promise<DepartmentOptions[]> => {
  try {
    const response = await base.get(apiEndpoints.departments);
    if (!Array.isArray(response.data) || response.data.length === 0) {
      throw new Error("Case analysts data is empty or not in expected format.");
    }
    const departments = response.data;
    if (!Array.isArray(departments)) {
      throw new Error("Case analysts data is not an array.");
    }

    const formattedDepartments = departments.map((caseAnalyst: any) => ({
      value: String(caseAnalyst.value),
      label: caseAnalyst.label,
    }));

    return formattedDepartments;
  } catch (error) {
    console.error("Error fetching case analysts:", error);
    throw error; 
  }
};

export {
  getDepartments,
};
