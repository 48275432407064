const ShowIcon = ({ ...props }) => (
  <svg 
    width="13" 
    height="12" 
    viewBox="0 0 13 12" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g opacity="0.5">
      <path 
        d="M12.8993 5.39909C12.9632 5.5263 12.9699 5.67648 12.9162 5.80832C11.5812 9.08163 9.92791 11.0041 6.5 11.2C3.06904 11.0039 1.31972 9.0782 0.0745666 5.79957C0.0264039 5.67275 0.0335153 5.53017 0.093569 5.40854C0.99561 3.58146 1.91267 2.27326 3.27894 1.34284C5.18658 0.0437576 7.82365 -0.00586716 9.75895 1.25164C11.1961 2.18547 11.9461 3.50291 12.8993 5.39909Z" 
        fill="#030229"/>
      <path 
        d="M9.34961 5.6001C9.34961 7.11888 8.11839 8.3501 6.59961 8.3501C5.08083 8.3501 3.84961 7.11888 3.84961 5.6001C3.84961 4.08131 5.08083 2.8501 6.59961 2.8501C8.11839 2.8501 9.34961 4.08131 9.34961 5.6001Z" 
        fill="white"/>
      <circle 
        cx="6.63008" 
        cy="5.55" 
        r="1.55" 
        fill="#030229"/>
    </g>
  </svg>
);
export default ShowIcon;