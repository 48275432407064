import { FC } from 'react';
import { fetchUser, UserProfileData } from 'actions/userActions';
import { withRouter, useHistory } from 'react-router-dom';
import { TextField } from 'components/UI/textField/textField';
import styles from './userForm.module.scss';
import { RoutePaths } from 'app/routing/routing';
import { useState, useEffect } from "react";
import { updateUser } from 'actions/userActions';
import { Button } from 'components/UI/button/button';
import { useForm } from 'react-hook-form';

export interface UserFormFields {
  email: string;
  firstName: string;
  lastName: string;
}

const UserFormComponent : FC = () => {
  const history = useHistory();
  const { register, handleSubmit, formState: { errors } } = useForm<UserProfileData>();
  const [userInfoResponse, setUserInfo] = useState<UserProfileData>();
  let errorMessage = '';


  const fetchData = async () => {
    const userInfoResponse = await fetchUser();
    setUserInfo(userInfoResponse);
  };

  const onSubmit = async (values: UserProfileData) => {
    
    try {
      if (userInfoResponse){
        await updateUser(values);
      }
      history.push({
        pathname: RoutePaths.UserPage,
        state: { saved: true },
      });
    } catch (err: any) {
      errorMessage = `Error saving: ${JSON.stringify(err.original.message)}`;
    }
    
  };

  useEffect(() => {
    fetchData();
  }, []);

  return ( 
    <form onSubmit={handleSubmit(onSubmit)} >
      <TextField id="firstName" register={register} type="text" placeholder={userInfoResponse? userInfoResponse.firstName : ''} label="First name" className={styles.field} labelClassName={styles.labels} />
      <TextField id="lastName" register={register} type="text" placeholder={userInfoResponse? userInfoResponse.lastName : ''} label="Last name" className={styles.field} labelClassName={styles.labels} />
      <TextField id="email" register={register} type="text" placeholder={userInfoResponse? userInfoResponse.email : ''} label="Email" className={styles.field} labelClassName={styles.labels} errors={errors} validationSchema={{ 
        pattern: {
          value: /\S+@\S+\.\S+/,
          message: "Entered value does not match email format",
        }
      }} />
      <Button className={styles.submitButton} type="submit">
            Save
      </Button>
      <p className={styles.errorMessage}>{errorMessage}</p>
    </form>
  );
};

export const UserForm = withRouter(UserFormComponent);