const CalendarIcon = ({ ...props }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.0918 9.40421H20.9157" stroke="#5E6366" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M16.4429 13.3097H16.4522" stroke="#5E6366" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12.0054 13.3097H12.0147" stroke="#5E6366" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M7.55818 13.3097H7.56744" stroke="#5E6366" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M16.4429 17.1962H16.4522" stroke="#5E6366" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12.0054 17.1962H12.0147" stroke="#5E6366" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M7.55818 17.1962H7.56744" stroke="#5E6366" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M16.0433 2V5.29078" stroke="#5E6366" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M7.96515 2V5.29078" stroke="#5E6366" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M16.2383 3.5791H7.77096C4.83427 3.5791 3 5.21504 3 8.22213V17.2718C3 20.3261 4.83427 21.9999 7.77096 21.9999H16.229C19.175 21.9999 21 20.3545 21 17.3474V8.22213C21.0092 5.21504 19.1842 3.5791 16.2383 3.5791Z" stroke="#5E6366" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>

);
export default CalendarIcon;