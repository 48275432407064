import LoadingFlag from "components/icons/loadingFlag";
import styles from "./loadingContainer.module.scss";
import classNames from "classnames";

interface LoadingContainerProps {
  loadingLabel: string;
}

export const LoadingContainer = ({ loadingLabel }: LoadingContainerProps) => {
  return (
    <div className={styles.loadingContainer}>
      <LoadingFlag className={styles.loadingFlag} />
      <p className={styles.loadingLabel}>
        {loadingLabel}
        <span className={styles.dot1}>.</span>
        <span className={styles.dot2}>.</span>
        <span className={styles.dot3}>.</span>
      </p>
    </div>
  );
};
