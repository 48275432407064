import { useEffect } from "react";

const useKeyDownTrigger = (onEnter: () => void) => {
  
  useEffect(() => {
    const handleKeydown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onEnter();
      }
    };
    window.addEventListener('keydown', handleKeydown);

    return () => window.removeEventListener('keydown', handleKeydown);
  }, []);
};

export { useKeyDownTrigger };
