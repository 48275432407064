const SettingsModalCheckmark = ({ ...props }) => (
  <svg 
    width="20" 
    height="20" 
    viewBox="0 0 20 20" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path 
      d="M7.50091 13.4999L4.58424 10.5832C4.25924 10.2582 3.74258 10.2582 3.41758 10.5832C3.09258 10.9082 3.09258 11.4249 3.41758 11.7499L6.90925 15.2415C7.23425 15.5665 7.75924 15.5665 8.08424 15.2415L16.9176 6.41652C17.2426 6.09152 17.2426 5.57485 16.9176 5.24985C16.5926 4.92485 16.0759 4.92485 15.7509 5.24985L7.50091 13.4999Z" 
      fill="#A8B0B9"
    />
  </svg>
);
export default SettingsModalCheckmark;
