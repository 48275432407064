import React, { useState } from 'react';
import style from './singleFilterDropDown.module.scss';
import SettingsModalCheckmark from 'components/icons/settingsModalCheckmark';
import { Modal } from 'components/modal/modal';
import SortIcon from 'components/icons/sortIcon';
import { useOutsideClick } from 'hooks/useOutsideClick';
import classNames from 'classnames';

export type OptionItem = {
  label: string;
  value: any;
};

type SingleFilterDropDownProps = {
  id?: string;
  label: string;
  mobileText?: string;
  optionItems: OptionItem[];
  onOptionSelected: (value: any) => void;
  dropDownDirectionClass?: string;
  className?: string;
};

const SingleFilterDropDown = ({
  id,
  label,
  mobileText,
  optionItems,
  dropDownDirectionClass,
  onOptionSelected,
  className,
}: SingleFilterDropDownProps) => {
  const [selectedValue, setSelectedValue] = useState<any>();
  const [showDropDown, setShowDropDown] = useState<boolean>(false);
  const { ref: filterRef } = useOutsideClick(() => setShowDropDown(false));
  const handleSelect = (item: OptionItem) => {
    if(item.value === selectedValue){
      setSelectedValue(undefined);
      onOptionSelected(undefined);
    }
    else{
      setSelectedValue(item.value);
      onOptionSelected(item.value);
    
    }
  };

  return (
    <div className={classNames(style.filterContainer, className)} ref={filterRef}>
      <div id={id} className={style.filter} onClick={() => setShowDropDown(!showDropDown)}>
        <div className={style.mobileOnly}>{mobileText}</div>
        <div className={mobileText ? style.desktopOnly : ''}>{label}</div>
        <div className={style.filterIcon}>
          <SortIcon />
        </div>
      </div>
      {showDropDown && (
        <div className={classNames(style.filterDropdown, dropDownDirectionClass)}>
          {optionItems.map((option, index) => (
            <>
              { index != 0 && <div className={style.barDiv}/>}
              <div id={`${id}-option-${label}`} key={index} className={style.row} onClick={() => handleSelect(option)}>
                <div className={style.labelContainer}>
                  <div className={style.label}>{option.label}</div>
                  {selectedValue === option.value && <SettingsModalCheckmark />}
                </div>
              </div>
            </>
          ))}
        </div>
      )}

    </div >
  );
};

export default SingleFilterDropDown;
