import { userIsAuthorized, fetchUser } from "actions/userActions";
import { useAppState, useMergeAppState, useSetAppState } from "context/app";
import { useSetUserState } from "context/user";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
export const useGetAuth = () => {
  const setAppState = useSetAppState();
  const setUserState = useSetUserState();
  const mergeAppState = useMergeAppState();
  const appState = useAppState();
  const history = useHistory();

  useEffect(() => {
  
    const checkUserAuth = async () => {
      const userAuth = userIsAuthorized();
      if (appState.Authenticated !== userAuth && !appState.Authenticating){
        mergeAppState({Authenticated: userAuth});
        if (userAuth) {
          try {
            const result = await fetchUser();
            setUserState(result);
          }
          catch(e) {
            mergeAppState({Authenticated: false});
          }
        }
        else {
          localStorage.clear();
          window.location.reload();
        }
      }
    };
  
    const intervalId = setInterval(() => {
      checkUserAuth();
    }, 5000);
  
    checkUserAuth();
  
    return () => {
      clearInterval(intervalId);
    };
  }, [appState, setAppState, setUserState, history]);
  
  return null;
};
  