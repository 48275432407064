/// Base Actions - calling external API
import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import _keys from "lodash.keys";
import { wrapRefreshAuthTokenOnFail } from './jwtActions';
import { fullEndpoint, getConfig } from "../../actions/lib/urls";

/// Get from endpoint
const get = <T, >(endpoint: string, params?: AxiosRequestConfig) => {
  const callAxios = () => {
    return axios.get<T, AxiosResponse<T>>(fullEndpoint(endpoint), getConfig(params));
  };

  return wrapRefreshAuthTokenOnFail(callAxios);
};

/// Get from endpoint
const getWSpecificToken = <T, >(endpoint: string, token: string) => {
  const callAxios = () => {
    return axios.get<T, AxiosResponse<T>>(fullEndpoint(endpoint), getConfigWSpecificToken(token));
  };

  return wrapRefreshAuthTokenOnFail(callAxios);
};

/// get Blob from endpoint
const getBlob = (endpoint: string, params?: AxiosRequestConfig) => {
  const callAxios = () => {
    return axios.get(fullEndpoint(endpoint), getConfig({responseType: 'blob', ...params}));
  };

  return wrapRefreshAuthTokenOnFail(callAxios);
};

/// post data to endpoint
const post = (endpoint: string, data : any, params?: AxiosRequestConfig) => {
  const callAxios = () => {
    return axios.post(fullEndpoint(endpoint), data, getConfig(params));
  };

  return wrapRefreshAuthTokenOnFail(callAxios);
};

/// post file data to endpoint
const postFile = (endpoint: string, data : any, params?: AxiosRequestConfig) => {
  const formData = new FormData();
  const keys = _keys(data);
  const config = getConfig({'content-type': 'multipart/form-data', ...params});
  
  keys.forEach((k) => {
    formData.append(k, data[k]);
  });

  const callAxios = () => {
    return axios.post(fullEndpoint(endpoint), formData, config);
  };

  return wrapRefreshAuthTokenOnFail(callAxios);
};

/// post file data to endpoint
const putFile = (endpoint : string, data: any, params?: AxiosRequestConfig) => {
  const formData = new FormData();
  const keys = _keys(data);
  const config = getConfig({'content-type': 'multipart/form-data', ...params});
  
  keys.forEach((k) => {
    formData.append(k, data[k]);
  });

  const callAxios = () => {
    return axios.put(fullEndpoint(endpoint), formData, config);
  };

  return wrapRefreshAuthTokenOnFail(callAxios);
};

/// put data to endpoint
const put = (endpoint : string, data : any, params?: AxiosRequestConfig) => {
  const callAxios = () => {
    return axios.put(fullEndpoint(endpoint), data, getConfig(params));
  };

  return wrapRefreshAuthTokenOnFail(callAxios);
};

/// delete endpoint
const del = (endpoint : string, params?: AxiosRequestConfig) => {
  const callAxios = () => {
    return axios.delete(fullEndpoint(endpoint), getConfig(params));
  };

  return wrapRefreshAuthTokenOnFail(callAxios);
};

/// create general http request
const request = (config : AxiosRequestConfig) => {
  const newConfig = { ...getConfig(), ...config };

  const callAxios = () => {
    return axios.request(newConfig);
  };

  return wrapRefreshAuthTokenOnFail(callAxios);
};

/// Private Functions
const getHeadersWSpecificToken = (specificToken:string) => {
  const headers : { Authorization?: string } = {};
  headers.Authorization = 'Bearer ' + specificToken;

  return headers;
};

const getConfigWSpecificToken = (specificToken:string) => {
  return {
    headers: getHeadersWSpecificToken(specificToken)
  };
};

export {
  get,
  getWSpecificToken,
  getBlob,
  post,
  postFile,
  put,
  putFile,
  del,
  request
};
