import parse from 'html-react-parser';
import { explicitDateRegex, invalidDocNameCharacterRegex, noTimeZoneDateRegex, nonAlphaNumericRegex, YYYY_MM_DD_DateRegex, tableQuestionFieldIdRegex } from './regexValidations';
import { DocumentName, DocumentNames } from 'actions/documentActions';
import { ErrorOption, FieldErrors } from 'react-hook-form';

interface Item {
  order: number;
}

export enum IntStatus {
  GetStarted = 0,
  Active = 1,
  PendingReview = 2,
  Rejected = 3,
  Approved = 4
}

export enum IntStatusLabels  {
  GetStarted = 'Get Started',
  Active = 'Active',
  PendingReview = 'Pending Review',
  Rejected = 'Rejected',
  Approved = 'Approved',
}

export interface FieldIdParams {
  label: string;
  recordId?: number;
  isTableQuestion: boolean;
  isTablePreviewQuestion?: boolean;
}

enum RegexOptions {
  Global = "g",
}

export enum DateFormat {
  YYYY_MM_DD_dashes = "YYYY_MM_DD_dashes",
  YYYY_MM_DD_slashes = "YYYY_MM_DD_slashes",
  M_D_YYYY_slashes = "M_D_YYYY_slashes",
}

export interface FileExtensionParse {
  supportedFiles: File[],
  error: ErrorOption | null,
}

export interface FileExtensionParse {
  supportedFiles: File[],
  error: ErrorOption | null,
}

export const getStatusLabel = (intStatus: IntStatus): IntStatusLabels => {
  switch (intStatus) {
    case IntStatus.GetStarted:
      return IntStatusLabels.GetStarted;
    case IntStatus.Active:
      return IntStatusLabels.Active;
    case IntStatus.PendingReview:
      return IntStatusLabels.PendingReview;
    case IntStatus.Rejected:
      return IntStatusLabels.Rejected;
    case IntStatus.Approved:
      return IntStatusLabels.Approved;
  }
};

export const getStatusClass = (status: IntStatus | IntStatusLabels, style: any) => {
  if (typeof status === "number") { // IntStatus
    status = getStatusLabel(status);
  }
  switch (status) {
    case IntStatusLabels.GetStarted:
      return style.getStarted;
    case IntStatusLabels.Active:
      return style.active;
    case IntStatusLabels.PendingReview:
      return style.pendingReview;
    case IntStatusLabels.Rejected:
      return style.rejected;
    case IntStatusLabels.Approved:
      return style.approved;
  }
};

export const invalidEmail = (email: string) => {
  return !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
};

export const convertYesNoToBool = (selected: string) => {
  return selected === "Yes" ? true : false;
};

export const isUndefined = (input: boolean | undefined) => {
  return input === undefined;
};

export const sortByOrder = (a: Item, b: Item) => { 
  return a.order < b.order ? -1 : a.order > b.order ? 1 : 0; 
};

export const openInNewTab = (url: string | undefined): void => {
  if (!url) return;
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

export const parseDateString = (dateString: string | undefined): Date | undefined => {
  if (!dateString) {
    return undefined;
  }

  // format 1: YYYY-MM-DD
  if (YYYY_MM_DD_DateRegex.test(dateString)) {
    const [year, month, day] = dateString.split('-').map(str => Number(str));
    const monthIndex = month - 1;
    const date = new Date(year, monthIndex, day);
    
    return date;
  }

  // format 2: Sat, Jan 13, 2024, EST 00:00:00 Eastern Standard Time (explicitDateRegex)
  if (explicitDateRegex.test(dateString)) {
    const parts = dateString.match(explicitDateRegex);
    if (!parts) {
      return undefined;
    }
    const dayOfWeek = parts[1]; 
    const monthName = parts[2];
    const dayOfMonth = parseInt(parts[3], 10);
    const year = parseInt(parts[4], 10);
    const timezone = parts[5]; 
    const time = parts[6];
    const timezoneName = parts[7];
    const monthMap: { [key: string]: number } = {
      Jan: 0, Feb: 1, Mar: 2, Apr: 3, May: 4, Jun: 5,
      Jul: 6, Aug: 7, Sep: 8, Oct: 9, Nov: 10, Dec: 11
    };
  
    const month = monthMap[monthName];
    if (month === undefined) {
      return undefined;
    }
    const [hours, minutes, seconds] = time.split(':').map(num => parseInt(num, 10));
    const date = new Date(year, month, dayOfMonth, hours, minutes, seconds);

    return date;
  }

  // format 3: 2024-01-24T14:18:15.620Z (noTimeZoneDateRegex)
  if (noTimeZoneDateRegex.test(dateString)) {
    const [year, oneIndexedMonth, dayOfMonth, hours, minutes, seconds, milliseconds] = dateString.split(/[-T:.Z]/).map(num => parseInt(num, 10));
    const zeroIndexedMonth = oneIndexedMonth - 1;
    const date = new Date(year, zeroIndexedMonth, dayOfMonth, hours, minutes, seconds);
    
    return date;
  }
};

export const formatDateToString = (date: Date | undefined, format: DateFormat): string => {
  if (!date) return '';

  switch (format) {
    case DateFormat.YYYY_MM_DD_dashes:
      return date.toISOString().split('T')[0];

    case DateFormat.YYYY_MM_DD_slashes:
      return date.toISOString().split('T')[0].replaceAll('-', '/');

    case DateFormat.M_D_YYYY_slashes:
      return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        timeZone: 'UTC'
      }).format(date);

    default:
      return '';
  }
};

export const timeSince = (timestamp: string): string => {
  const now = new Date();
  const past = new Date(timestamp);
  const diffInSeconds = Math.floor((now.getTime() - past.getTime()) / 1000);

  const secondsInMinute = 60;
  const secondsInHour = 60 * secondsInMinute;
  const secondsInDay = 24 * secondsInHour;
  const secondsInWeek = 7 * secondsInDay;

  if (diffInSeconds < secondsInHour) {
    const minutes = Math.floor(diffInSeconds / secondsInMinute);
    return `${minutes}m`;
  } else if (diffInSeconds < secondsInDay) {
    const hours = Math.floor(diffInSeconds / secondsInHour);
    return `${hours}h`;
  } else if (diffInSeconds < secondsInWeek) {
    const days = Math.floor(diffInSeconds / secondsInDay);
    return `${days}d`;
  } else {
    const weeks = Math.floor(diffInSeconds / secondsInWeek);
    return `${weeks}w`;
  }
}

export const getFileType = (filePath: string) => {
  if(filePath.includes('.')) {
    const splitPath = filePath.split('.');
    return splitPath[splitPath.length - 1];
  }
  return filePath;
};

export const getInvalidFiles = (files: File[], validExtensions: string[]): File[] => {
  return files.filter(file => {
    const fileExtension = getFileType(file.name);

    return !validExtensions.includes(fileExtension.toLowerCase());
  });
};

export const parseFileExtensions = (files: File[], validExtensions: string[]): FileExtensionParse => {
  const unsupportedFiles = getInvalidFiles(files, validExtensions);
  const unsupportedFileSet = new Set(unsupportedFiles.map(file => file.name));
  const supportedFiles = files.filter(file => !unsupportedFileSet.has(file.name));
  
  let error = null;
  if (unsupportedFiles.length > 0) {
    error = {
      type: "fileType",
      message: `"${unsupportedFiles.map(file => file.name).join(', ')}" upload failed: file type not supported; please upload ${validExtensions.join(", ")} files only`
    };
  }
  return { supportedFiles, error };
};

export const fileToBase64 = (file: File): Promise<string> => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result as string);
  reader.onerror = error => reject(error);
});

export const parseHTML = (html?: string) => {
  return html && parse(html);
};

export const isEmptyDropDownValue = (value?: string) => {
  const nullValues = new Set(['(not specified)', '--Please Select--', '']);
  return !value || nullValues.has(value);
};

export const getFieldId = ({ label, recordId, isTableQuestion, isTablePreviewQuestion }: FieldIdParams) => {
  let fieldId = label.replace(RegExp(nonAlphaNumericRegex, RegexOptions.Global), '-');
  if (isTableQuestion) {
    if (recordId) {
      fieldId = `tableQuestion-${isTablePreviewQuestion ? 'preview' : ''}-${fieldId}-${recordId}`;
    } else {
      console.error(`getFieldId() err: recordId is required for table questions`);
    }
  }
  return fieldId;
};

export const getErroredRecordIds = (errors: FieldErrors<any> | undefined) => {
  if (!errors) return new Set([]);
  const erroredTableFieldIds = Object.keys(errors).filter(fieldId => tableQuestionFieldIdRegex.test(fieldId));
  const erroredRecordIds = erroredTableFieldIds.map(fieldId => Number(fieldId.split('-').pop()));
  const uniqueRecordIds = new Set(erroredRecordIds);

  return uniqueRecordIds;
};

export const isComments = (label?: string) => {
  return label === "Comments";
};

export const getFieldDocumentNames = (documentNames: DocumentNames | undefined, fieldId: string) => {
  if (!documentNames) return undefined;
  return {
    localDocuments: documentNames.localDocuments.filter(doc => doc.fieldId === fieldId),
    uploadedDocuments: documentNames.uploadedDocuments.filter(doc => doc.fieldId === fieldId),
  } as DocumentNames;
};

export const parseDocName = (name: string): string => {
  return name.replace(RegExp(invalidDocNameCharacterRegex, RegexOptions.Global), '').toLowerCase();
};

export const parentValueCheck = (parentValues: string[] | undefined, parentValue: string | undefined, matchParentValue: boolean | undefined): boolean => {
  if (!parentValue) return false;
  if (!parentValues) return true;
  const isIncluded = parentValues.includes(parentValue);
  return matchParentValue !== false ? isIncluded : !isIncluded;
};