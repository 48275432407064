import { createContextStore } from './base';

export interface Filter {
  isShowingFilter: boolean
}

const initialState = {
  isShowingFilter: false
};

const [ Provider, useState, useSetState, useMergeState ] = createContextStore<Filter>(initialState);

export {
  Provider as FilterContextProvider,
  useState as useFilterState,
  useSetState as useSetFilterState,
  useMergeState as useMergeFilterState,
};
