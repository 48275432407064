import * as base from "./lib/baseActions";
import { apiEndpoints } from "config/config";
import useSWR, { mutate } from "swr";

export interface userNotification {
  bitRead: boolean;
  clientNumber: string;
  dateCreated: string;
  id: string;
  objectType: string;
  recordId: string;
  textDetails: string
  title: string;
  userId: string;
}

const getNotificationsInfo = async (): Promise<userNotification[]> => {
  const result = await base.get(apiEndpoints.notifications);
  return result.data;
};

const useGetNotificationsInfo = () => {
  const { data, error } = useSWR([apiEndpoints.notifications], () => getNotificationsInfo());
  return { data, error };
};

const markNotificationAsRead = async (recordId: string) => {
  const result = await base.put(apiEndpoints.markNotificationAsRead,
    {
      recordId: recordId,
    });
  
  mutate([apiEndpoints.notifications]);
  return result.data;
}


export {
  useGetNotificationsInfo,
  markNotificationAsRead,
};