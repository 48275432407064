import React, { useState, useEffect } from "react";
import styles from "./updateUserForm.module.scss";
import { Input } from "components/UI/input/input";
import { Button } from "components/UI/button/button";
import DropDownSelect from "components/UI/dropDownSelect/dropDownSelect";
import { updateUser, useGetUser } from "actions/userActions";
import { UserProfileData } from "actions/userActions";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { DropdownOptions, useGetCountries } from "actions/locationsActions";
import {
  validTownCityRegex,
  validNameRegex,
  validPhoneNumberRegex,
  validStreetNumberRegex,
  validUnitNumberRegex,
  validZipCodePostalCodeRegex,
} from "utils/regexValidations";
import { isEmptyDropDownValue } from "utils/commonFunctions";

const UpdateUserFormFieldsKeys = {
  firstName: "firstName",
  lastName: "lastName",
  country: "country",
  streetAddress: "streetAddress",
  unitNumber: "unitNumber",
  townCity: "townCity",
  provinceState: "provinceState",
  postalCode: "postalCode",
  phoneNumber: "phoneNumber",
} as const;

type UpdateUserFormFields = Record<typeof UpdateUserFormFieldsKeys[keyof typeof UpdateUserFormFieldsKeys], string>;


export const UpdateUserForm = () => {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    getValues,
    trigger,
  } = useForm<UserProfileData>();

  const {
    data: countryOptions,
    error: errorCountryOptions,
    isLoading: countryOptionsIsLoading,
  } = useGetCountries();

  const {
    data: userInfo,
    error: userError,
    isLoading: userIsLoading,
    mutate: mutateUser,
  } = useGetUser();

  const [provinceOptions, setProvinceOptions] = useState<DropdownOptions[]>();
  const [disableProvinceStatesDropdown, setDisableProvinceStatesDropdown] = useState(true);

  const handleFormValueChange = (name: string, value: string) => {
    setValue(name as keyof UpdateUserFormFields, value);
    trigger(name as keyof UpdateUserFormFields);
  };
     
  const handleCountryChange = (value: string) => {
    trigger(UpdateUserFormFieldsKeys.country);
    setValue(UpdateUserFormFieldsKeys.country, value);
    const selectedCountry = countryOptions?.find(country => country.value === value);
    const hasProvinces = selectedCountry?.hasProvinceOrState || false;

    setDisableProvinceStatesDropdown(countryOptionsIsLoading || isEmptyDropDownValue(value));

    setProvinceOptions(hasProvinces ? selectedCountry?.provinceOrStates : []);

    if (userInfo && selectedCountry?.value !== userInfo.country) {
      setValue(UpdateUserFormFieldsKeys.provinceState, "");
    }
  };
  const countryHasProvinces = (country: string): boolean => {
    const selectedCountry = countryOptions?.find(
      (countryOptions) => countryOptions.value === country
    );
    return selectedCountry?.hasProvinceOrState || false;
  };

  const getProvinceStateValue = (values: UserProfileData): string => {
    if (countryHasProvinces(values.country) && !values.provinceState) {
      return "(not specified)";
    }
    return values.provinceState || "";
  };

  useEffect(() => {
    if (userInfo) {
      reset(userInfo);
      handleCountryChange(userInfo.country);
    }
  }, [userInfo, countryOptions]);



  const onSubmit = async (values: UserProfileData) => {
    setIsSubmitting(true);
    try {
      if (userInfo) {
        values.provinceState = getProvinceStateValue(values);
        await updateUser(values);
      }
      mutateUser({...values});
    } catch (err: any) {
      console.error(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const ProvinceStateInput = () => {
    const commonProps = {
      fieldId: UpdateUserFormFieldsKeys.provinceState,
      placeholder: "Province/State",
      register: register,
      errors: errors,
      labelClass: styles.accountLabel,
    };

    if (provinceOptions && provinceOptions.length > 0 || countryOptionsIsLoading) {
      return (
        <DropDownSelect
          id={UpdateUserFormFieldsKeys.provinceState}
          title={"Province/State"}  
          options={provinceOptions ? provinceOptions : []}
          startingValue={getValues(UpdateUserFormFieldsKeys.provinceState)}
          selectClass={styles.accountDropdown}
          dropDownClass={"userSettings"}
          isLoading={countryOptionsIsLoading}
          onChange={(value) => setValue(UpdateUserFormFieldsKeys.provinceState, value)}
          locked={countryOptionsIsLoading}
          {...commonProps}
        />
      );
    } else {
      return (
        <Input
          id={UpdateUserFormFieldsKeys.provinceState}
          label={"Province/State"}
          value={getValues(UpdateUserFormFieldsKeys.provinceState)}
          onChange={(value) => setValue(UpdateUserFormFieldsKeys.provinceState, value)}
          containerClass={styles.accountInputContainer}
          wrapperClass={styles.accountInputWrapper}
          inputClass={styles.accountInput}
          locked={disableProvinceStatesDropdown}
          {...commonProps}
        />
      );
    }
  };

  return (
    <>
      {userError && (
        <div className={styles.alert}>
          Error fetching user data: {userError.message}
        </div>
      )}

      {errorCountryOptions && (
        <div className={styles.alert}>
          Error fetching country options: {errorCountryOptions.message}
        </div>
      )}

      {!userIsLoading && (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.account}>
          <Input
            id={UpdateUserFormFieldsKeys.firstName}
            register={register}
            validationSchema={{
              pattern: {
                value: validNameRegex,
                message: "Invalid first name format",
              },
              required: "First name is required",
            }}
            value={getValues(UpdateUserFormFieldsKeys.firstName)}
            onChange={(value) => handleFormValueChange(UpdateUserFormFieldsKeys.firstName, value)}
            label="First Name"
            placeholder="First Name"
            containerClass={styles.accountInputContainer}
            wrapperClass={styles.accountInputWrapper}
            inputClass={styles.accountInput}
            labelClass={styles.accountLabel}
            errors={errors}
          />
          <Input
            id={UpdateUserFormFieldsKeys.lastName}
            register={register}
            validationSchema={{
              pattern: {
                value: validNameRegex,
                message: "Invalid last name format",
              },
              required: "Last name is required",
            }}
            value={getValues(UpdateUserFormFieldsKeys.lastName)}
            onChange={(value) => handleFormValueChange(UpdateUserFormFieldsKeys.lastName, value)}
            label="Last Name"
            placeholder="Last Name"
            containerClass={styles.accountInputContainer}
            wrapperClass={styles.accountInputWrapper}
            inputClass={styles.accountInput}
            labelClass={styles.accountLabel}
            errors={errors}
          />
          <DropDownSelect
            id={UpdateUserFormFieldsKeys.country}
            register={register}
            options={countryOptions ? countryOptions.slice(1) : []}
            onChange={(value) => handleCountryChange(value)}
            startingValue={userInfo?.country}
            placeholder={"Country"}
            title={"Country"}
            selectClass={styles.accountDropdown}
            labelClass={styles.accountLabel}
            dropDownClass={"userSettings"}
            locked={countryOptionsIsLoading}
            errors={errors}
            isLoading={countryOptionsIsLoading}
            isRequiredMessage="Country is required"
          />
          <Input
            id={UpdateUserFormFieldsKeys.streetAddress}
            register={register}
            validationSchema={{
              pattern: {
                value: validStreetNumberRegex,
                message: "Invalid street address format",
              },
            }}
            value={getValues(UpdateUserFormFieldsKeys.streetAddress)}
            onChange={(value) => setValue(UpdateUserFormFieldsKeys.streetAddress, value)}
            label={"Street Address"}
            placeholder="Street Address"
            containerClass={styles.accountInputContainer}
            wrapperClass={styles.accountInputWrapper}
            inputClass={styles.accountInput}
            labelClass={styles.accountLabel}
            errors={errors}
          />
          <Input
            id={UpdateUserFormFieldsKeys.unitNumber}
            register={register}
            validationSchema={{
              pattern: {
                value: validUnitNumberRegex,
                message: "Invalid unit number format",
              },
            }}
            value={getValues(UpdateUserFormFieldsKeys.unitNumber)}
            onChange={(value) => setValue(UpdateUserFormFieldsKeys.unitNumber, value)}
            label="Unit Number"
            placeholder="Unit Number"
            containerClass={styles.accountInputContainer}
            wrapperClass={styles.accountInputWrapper}
            inputClass={styles.accountInput}
            labelClass={styles.accountLabel}
            errors={errors}
          />
          <Input
            id={UpdateUserFormFieldsKeys.townCity}
            register={register}
            validationSchema={{
              pattern: {
                value: validTownCityRegex,
                message: "Invalid city/town name format",
              },
            }}
            value={getValues(UpdateUserFormFieldsKeys.townCity)}
            onChange={(value) => setValue(UpdateUserFormFieldsKeys.townCity, value)}
            label="Town/City"
            placeholder="Town/City"
            containerClass={styles.accountInputContainer}
            wrapperClass={styles.accountInputWrapper}
            inputClass={styles.accountInput}
            labelClass={styles.accountLabel}
            errors={errors}
          />
          <ProvinceStateInput />
          <Input
            id={UpdateUserFormFieldsKeys.postalCode}
            register={register}
            validationSchema={{
              pattern: {
                value: validZipCodePostalCodeRegex,
                message: "Invalid postal code/Zip code format",
              },
            }}
            value={getValues(UpdateUserFormFieldsKeys.postalCode)}
            onChange={(value) => setValue(UpdateUserFormFieldsKeys.postalCode, value)}
            label="Postal Code/Zip Code"
            placeholder="Postal Code/Zip Code"
            containerClass={styles.accountInputContainer}
            wrapperClass={styles.accountInputWrapper}
            inputClass={styles.accountInput}
            labelClass={styles.accountLabel}
            errors={errors}
          />
          <Input
            id={UpdateUserFormFieldsKeys.phoneNumber}
            register={register}
            validationSchema={{
              pattern: {
                value: validPhoneNumberRegex,
                message: "Invalid phone number format",
              },
            }}
            value={getValues(UpdateUserFormFieldsKeys.phoneNumber)}
            onChange={(value) => setValue(UpdateUserFormFieldsKeys.phoneNumber, value)}
            label="Phone"
            placeholder="Phone Number"
            containerClass={styles.accountInputContainer}
            wrapperClass={styles.accountInputWrapper}
            inputClass={styles.accountInput}
            labelClass={styles.accountLabel}
            errors={errors}
          />
          <Button id={'saveAccountDetails'} className={styles.saveButton} type="submit" disabled={isSubmitting}>
            {isSubmitting ? "Submitting... " : "Save Changes"}
          </Button>
        </form>
      )}
      {errorMessage && <div className={styles.alert}>{errorMessage}</div>}
    </>
  );
};
