import { createContextStore } from './base';
import { authResult, userIsAuthorized } from 'actions/userActions';

export interface AppInfo {
  Authenticated?: boolean; 
  Authenticating?: boolean;
  LoginResult?: authResult;
  searchQuery?: string;
}

const initialState: AppInfo = {
  Authenticated: userIsAuthorized(),
  Authenticating: false,
};

const [ Provider, useState, useSetState, useMergeState ] = createContextStore<AppInfo>(initialState);

export {
  Provider as AppContextProvider,
  useState as useAppState,
  useSetState as useSetAppState,
  useMergeState as useMergeAppState,
};
