import style from './questionnaire.module.scss';
import { Button } from 'components/UI/button/button';
import { LoadingContainer } from 'components/UI/loadingContainer/loadingContainer';
import { useQuestionnaireHook } from './questionnaireHook';
import QuestionnaireSection from './questionnaireComponents/questionnaireSection/questionnaireSection';
import { parseHTML } from 'utils/commonFunctions';
import { QuestionDataType } from './questionnaireComponents/question/question';
import { Modal } from 'components/modal/modal';
import { useState } from 'react';
import { SMBDocument, getSMBDocument } from 'actions/documentActions';
import { formatFilePath } from 'components/documentCard/documentCard.utils';
import { formatImage } from 'utils/imageFunctions';
import classNames from 'classnames';

interface QuestionnaireProps {
  selectedQuestionnaire: QuestionnaireDataParams;
  closeQuestionnaire: () => void;
  mutateQuestionnaires: () => void;
  isReview: boolean;
  setHideQuestionnaireModalHeader: (value: boolean) => void;
}

export interface QuestionnaireDataParams {
  title: string;
  contID: number;
  fileID: number;
  questionaireID: number;
  intStatus: number;
}

export const COMMENTS_QUESTION_ID = '103';
export const COMMENTS_SECTION_ID = 24;

const Questionnaire = ({ selectedQuestionnaire, closeQuestionnaire, isReview, setHideQuestionnaireModalHeader, mutateQuestionnaires }: QuestionnaireProps) => {
  const { 
    parsedQuestionnaire, 
    workingQuestionnaire, 
    questionnaireJSONError, 
    submitError, 
    register, 
    setValue, 
    updateGridQuestion, 
    updateTableQuestion,
    errors, 
    intStatus,
    tableModalIsOpen,
    openTableModal,
    closeTableModal,
    saveTableModal,
    isLockedQuestionnaire,
    isLockedTable,
    onClickClientSaveClose,
    onClickClientSubmit,
    onClickReviewerSaveClose,
    onClickReviewerSubmit,
    addTableRow,
    deleteTableRow,
    setSectionAcceptance,
    isSubmitting,
    submitMessage,
    isLoadingDocuments,
    documentNames,
    addDocuments,
    deleteDocument,
    shouldRenderTableQuestion
  } = useQuestionnaireHook(selectedQuestionnaire, closeQuestionnaire, mutateQuestionnaires, isReview, setHideQuestionnaireModalHeader);

  const [selectedDocumentName, setSelectedDocumentName] = useState<string>("");
  const [documentModalOpen, setDocumentModalOpen] = useState<boolean>(false);
  const [smbDocument, setSmbDocument] = useState<SMBDocument | null>(null);
  const [documentIsLoading, setDocumentIsLoading] = useState<boolean>(false);

  const onClickDocument = async (documentName: string, documentPath: string) => {
    try {
      setDocumentIsLoading(true);
      setSelectedDocumentName(documentName);
      setDocumentModalOpen(true);
      
      const formattedPath = formatFilePath(documentPath);
      const newSmbDocument = await getSMBDocument(formattedPath, true);
      setSmbDocument(newSmbDocument);
    } catch (error) {
      console.error("Failed to load document:", error);
    } finally {
      setDocumentIsLoading(false);
    }
  };
  
  
  if (questionnaireJSONError) {
    return (
      <div className={style.errorModal}>
        <div className={classNames(style.modalHeader)}>
          <h3 id={'closeErrorModal'} className={style.exitButton} onClick={() => closeQuestionnaire()}>X</h3>
        </div>
        <div className={style.questionnaireJSONErrorStyle}>{parseHTML(questionnaireJSONError.message)}</div>
      </div>
    );
  }
  if (isSubmitting) {
    return <LoadingContainer loadingLabel={submitMessage} />;
  }
  if (!workingQuestionnaire || !parsedQuestionnaire || isLoadingDocuments) {
    return <LoadingContainer loadingLabel={'Loading questionnaire'} />;
  }

  const image = formatImage(smbDocument?.format, smbDocument?.image);

  return (
    <form className={style.form}>
      <div className={style.questionsContainer}>
        {workingQuestionnaire.sections.map((section, index) => (
          <QuestionnaireSection
            key={section.txtLabel}
            section={section}
            index={index}
            errors={errors}
            intStatus={intStatus}
            updateGridQuestion={updateGridQuestion}
            updateTableQuestion={updateTableQuestion}
            register={register}
            setValue={setValue}
            tableModalIsOpen={tableModalIsOpen}
            openTableModal={openTableModal}
            closeTableModal={closeTableModal}
            saveTableModal={saveTableModal}
            addTableRow={addTableRow}
            deleteTableRow={deleteTableRow}
            setSectionAcceptance={setSectionAcceptance}
            isReview={isReview}
            reviewStatus={selectedQuestionnaire.intStatus}
            documentNames={documentNames}
            addDocuments={addDocuments}
            deleteDocument={deleteDocument}
            shouldRenderTableQuestion={shouldRenderTableQuestion}
            isLockedQuestionnaire={isLockedQuestionnaire}
            isLockedTable={isLockedTable}
            onClickDocument={onClickDocument}
          />
        ))}
      </div>
      { isReview ? <>
        <div className={style.buttonContainer}>
          <Button 
            id={'reviewSaveClose'} 
            className={style.reviewerSaveButton} 
            onClick={onClickReviewerSaveClose} 
            style='gradient'>
            {isLockedQuestionnaire ? 'Close' : 'Save & Close'}
          </Button>
          <Button 
            id={'reviewSubmit'} 
            className={style.reviewerSubmitButton} 
            onClick={onClickReviewerSubmit} 
            style='gradient'
            disabled={isLockedQuestionnaire}>
            Submit
          </Button>
        </div>
      </>:
        <>
          <div className={style.buttonContainer}>
            <Button 
              id={'clientSaveClose'} 
              className={style.userSaveButton} 
              onClick={onClickClientSaveClose} 
              style='gradient'>
              {isLockedQuestionnaire ? 'Close' : 'Save & Close'}
            </Button>
            <Button 
              id={'clientSubmit'} 
              className={style.userSubmitButton} 
              onClick={onClickClientSubmit} 
              style='gradient'
              disabled={isLockedQuestionnaire}>
              Submit
            </Button>
          </div>
        </>}

      {submitError && <div className={style.submitError}>{submitError}</div>}
      <Modal
        id="reviewerQuestionnaireDocumentCard"
        title={selectedDocumentName}
        show={documentModalOpen}
        onClose={() => setDocumentModalOpen(false)}
        className={style.documentModal}
      >
        {documentIsLoading ? <LoadingContainer loadingLabel='Loading Document'/> :
          <div className={style.modalContent}>{image}</div>
        }
      </Modal>
    </form> 
  );
};

export default Questionnaire;
