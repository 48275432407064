import React from "react";
import styles from "./sidebar.module.scss";
import logo from 'assets/images/company-logo.png';
import { InterfaceNavLink } from "./navLink/navLink";
import TabIcon from "components/icons/tab";
import WatchLaterIcon from "components/icons/watchLater";
import BookmarkIcon from "components/icons/bookmarks";
import { useSetAppState } from 'context/index';
import { useHistory, useLocation } from 'react-router-dom';
import { logoutUser } from "actions/userActions";
import ReviewQuestionniareIcon from "components/icons/reviewQuestionnaireIcon";
import ReviewApplicationIcon from "components/icons/reviewApplicationIcon";
import { isUserClient, isUserStandard } from "utils/userFunctions";
import { RoutePaths, StandardUserRoutePaths } from "app/routing/routing";

const Sidebar = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const setAppState = useSetAppState();

  const handleLogout = () => {
    logoutUser();
    setAppState({ Authenticated: false, Authenticating: false });
  };


  const getClientNavLinks = () => {
    if (isUserClient()) {
      return (
        <>
          <InterfaceNavLink 
            id="homeLink"
            to="" 
            activeLinks={[RoutePaths.Dashboard, RoutePaths.Company, RoutePaths.Application]} 
            icon={<TabIcon />} 
            iconClassName={styles.sideBarIcon}
          >Home</InterfaceNavLink>        
          <InterfaceNavLink 
            id="allMattersLink"
            to={RoutePaths.AllMatters} 
            activeLinks={[RoutePaths.AllMatters]}  
            icon={<WatchLaterIcon />} 
            iconClassName={styles.sideBarIcon}
          > All Matters</InterfaceNavLink>
          <InterfaceNavLink 
            id="documentsLink"
            to={RoutePaths.Documents} 
            activeLinks={[RoutePaths.Documents]} 
            icon={<BookmarkIcon />} 
            iconClassName={styles.sideBarIcon}
          >Documents</InterfaceNavLink>
        </>
      );
    }
    if (isUserStandard()) {
      return (
        <>
          <InterfaceNavLink 
            id="questionnairesLink"
            to={StandardUserRoutePaths.ReviewQuestionnaires} 
            activeLinks={[StandardUserRoutePaths.ReviewQuestionnaires]} 
            icon={<ReviewQuestionniareIcon />} 
            iconClassName={styles.sideBarIcon}
          >Questionnaires</InterfaceNavLink>

          <InterfaceNavLink 
            id="newMattersLink"
            to={StandardUserRoutePaths.ReviewApplications}
            activeLinks={[StandardUserRoutePaths.ReviewApplications]} 
            icon={<ReviewApplicationIcon />} 
            iconClassName={styles.sideBarIcon}
          >New Matters</InterfaceNavLink>
        </>
      );
    }
    return null;
  };
  
  const navLinks = getClientNavLinks();

  return (
    <div className={styles.sidebar}>
      <h3 id="exitSideBar" className={styles.exitButton}>X</h3>
      <div className={styles.sidebarOptionsWrapper}>
        <div className={styles.topSidebar}>
          <img className={styles.companyLogo} src={logo} alt="" />
        </div>
        <div className={styles.topSidebarButtons}>
          {navLinks}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
