import React from "react";
import styles from "./modal.module.scss";
import { ReactNode } from "react";
import classNames from "classnames";
import { parseHTML } from "utils/commonFunctions";
interface ModalProps {
  id: string;
  title: string;
  children?: ReactNode | string;
  className?: string;
  headerClass?: string;
  hideExitButton?: boolean;
  hideModalHeader?: boolean;
  show: boolean;
  onClose?: any;
  lockModal?: boolean;
}

export const Modal = ({
  id,
  title,
  children,
  className,
  headerClass,
  show,
  hideModalHeader = false,
  hideExitButton = false,
  onClose,
  lockModal = true,
}: ModalProps) => {

  if (!show) {
    return null;
  }
  
  return (
    <div className={styles.modalWrapper} onClick={lockModal ?  null : onClose}>
      <div className={classNames(styles.modalBackdrop, className)} onClick={(e) => e.stopPropagation()}>
        {hideModalHeader ||
          <div className={classNames(styles.modalHeader, headerClass)}>
            <h2 className={styles.headerLabel}>{parseHTML(title)}</h2>
            {hideExitButton || <h3 id={`${id}-exitModal`} className={styles.exitButton} onClick={onClose}>X</h3>}
          </div>
        }
        {children}
      </div>
    </div>
  );
};