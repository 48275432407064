import { ChangeEvent, FC } from 'react';
import classNames from 'classnames';
import styles from './textField.module.scss';
import { FieldErrors, UseFormRegister } from 'react-hook-form';

interface TextFieldProps {
  id: string;
  type: 'email' | 'password' | 'text' | 'textArea';
  register: UseFormRegister<any>;
  errors? : FieldErrors<any>;
  validationSchema?: any;
  label?: string;
  required?: boolean;
  placeholder?: string;
  className?: string;
  labelClassName?: string;
  fieldClassName?: string;
  startingValue?: string;
  autoFocus?: boolean;
  onChange?: (value: string) => void;
}

export const TextField : FC<TextFieldProps> = ({id, label, required, type, placeholder, className, labelClassName, fieldClassName, register, errors, validationSchema, startingValue, onChange, autoFocus }) => {
  
  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {  
    if(onChange){
      onChange(event.target.value);
    }
  };
  
  return (
    <div className={className}>
      { (label || required) 
        && (
          <label htmlFor={id} className={classNames(styles.label, labelClassName)}>
            {label}
            {required && (<span className={styles.required}>&emsp;</span>)}
          </label>
        )
      }
      {type === 'textArea' ? 
        (<textarea {...register(id as `${string}`, validationSchema)} placeholder={placeholder} className={classNames(styles.field, fieldClassName)} autoFocus={autoFocus}/>)
        : (<input id={id} {...register(id as `${string}`, validationSchema)} onChange={handleChange} type={type} placeholder={placeholder} className={classNames(styles.field, fieldClassName)} autoFocus={autoFocus} defaultValue={startingValue}/>)
      }
      {errors && (
        <div>
          <span id={`${id}-error`} className={styles.alert}>{errors[id]?.message}</span>
        </div>
      )}
    </div>
  );
};

export default TextField;