import { FC } from 'react';
import { AppContextProvider } from './app';
import { UserContextProvider } from './user';
import { FilterContextProvider } from './filter';

export { useUserState, useSetUserState, useMergeUserState } from './user';
export { useAppState, useSetAppState, useMergeAppState } from './app';
export { useFilterState, useSetFilterState, useMergeFilterState } from './filter';

export interface AppContextInterface {
  token?: string;
  email?: string;
  userName?: string;
}

export const AppProvider : FC = ({children}) => {
  return (
    <AppContextProvider>
      <UserContextProvider>
        <FilterContextProvider>
          {children}
        </FilterContextProvider>
      </UserContextProvider>
    </AppContextProvider>
  );
};
