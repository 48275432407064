import { FC } from "react";
import { AppProvider } from 'context/index';
import { Routing } from './routing/routing';

import "./app.scss";

const App: FC = () => {

  return (
    <>
      <AppProvider>
        <Routing />
      </AppProvider>
    </>
  );
};

export default App;
