import { FC } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import { Login } from "pages/login/login";
import { SignUp } from "pages/signUp/signUp";
import { useAppState } from 'context';
import { UserPage } from "pages/userPage/userPage";
import { EditUser } from "pages/editUser/editUser";
import { TwoFactor } from "pages/twoFactor/twoFactor";
import { Dashboard } from "pages/dashboard/dashboard";
import { ForgotPassword } from "pages/forgotPassword/forgotPassword";
import { Company } from "pages/company/company";
import { Documents } from "pages/documents/documents";
import { Application } from "pages/appPage/appPage";
import { Settings } from "pages/settings/settings";
import { NewPassword } from "pages/newPassword/newPassword";
import { ReviewQuestionnaires } from "pages/reviewQuestionnaires/reviewQuestionnaires";
import { ReviewApplications } from "pages/reviewApplications/reviewApplications";
import { isUserClient, isUserStandard } from "utils/userFunctions";
import { useGetAuth } from "hooks/useGetAuth";
import { AllMatters } from "pages/allMatters/allMatters";
import { Notifications } from "pages/notifications/notifications";
import { HelpPage } from "pages/helpPage/helpPage";
import { Search } from "pages/search/search";
import usePasswordVersionChecker from "hooks/usePasswordVersionChecker";

export enum RoutePaths {
  Dashboard = "/",
  UserPage = "/user",
  EditUser = "/user-edit",
  Login = "/login",
  SignUp = "/signUp",
  Company = "/company",
  Help = "/help",
  Documents = "/documents",
  AllMatters = "/allMatters",
  TwoFactor = "/twofactor",
  ResetPassword= "/resetPassword",
  ForgotPassword = "/forgotPassword",
  Application = "/applications",
  Settings = "/settings",
  Search = "/search",
  Notifications = "/notifications",
}

export enum StandardUserRoutePaths {
  ReviewQuestionnaires = "/reviewQuestionnaires",
  ReviewApplications = "/reviewApplications"
}

const StandardUserRoutes = () => {
  usePasswordVersionChecker();
  return (
    <Switch>
      <Route path={StandardUserRoutePaths.ReviewQuestionnaires}>
        <ReviewQuestionnaires />
      </Route>
      <Route path={StandardUserRoutePaths.ReviewApplications}>
        <ReviewApplications />
      </Route>
      <Route path={`/*`}>
        <ReviewQuestionnaires />
      </Route>
    </Switch>
  );
};

const ClientUserRoutes = () => {
  usePasswordVersionChecker();
  return (
    <Switch>
      <Route path={RoutePaths.UserPage}>
        <UserPage />
      </Route>
      <Route path={RoutePaths.EditUser}>
        <EditUser/>
      </Route>
      <Route path={RoutePaths.Documents}>
        <Documents />
      </Route>
      <Route path={RoutePaths.Company}>
        <Company/>
      </Route>
      <Route path={RoutePaths.Help}>
        <HelpPage />
      </Route>
      <Route path={RoutePaths.AllMatters}>
        <AllMatters/>
      </Route>
      <Route path={RoutePaths.Search}>
        <Search/>
      </Route>
      <Route path={RoutePaths.Application}>
        <Application />
      </Route>
      <Route path={RoutePaths.Settings}>
        <Settings />
      </Route>
      <Route path={RoutePaths.Notifications}>
        <Notifications />
      </Route>
      <Route path={RoutePaths.Dashboard}>
        <Dashboard />
      </Route>
      <Route path={`/*`}>
        <Dashboard />
      </Route>
    </Switch>
  );
};

export const Routing : FC = () => {
  const appState = useAppState();
  useGetAuth();

  return (
    <Router>
      <Switch>
        <Route path={RoutePaths.SignUp}>
          <SignUp />
        </Route>
        <Route path={RoutePaths.Login}>
          <Login />
        </Route>
        <Route path={RoutePaths.ForgotPassword}>
          <ForgotPassword />
        </Route>
        <Route path={RoutePaths.ResetPassword}>
          <NewPassword />
        </Route>
        <Route
          render={({ location }) => {
            if(appState.Authenticating && !appState.Authenticated) {
              return <Route path={RoutePaths.TwoFactor}>
                <TwoFactor />
              </Route>;
            } else if (appState.Authenticated && isUserStandard()) {
              return <StandardUserRoutes />;
            } else if (appState.Authenticated && isUserClient()) {
              return <ClientUserRoutes />;
            }else {
              return <Redirect
                to={{
                  pathname: RoutePaths.Login,
                  state: { from: location }
                }}
              />;
            }
          }}
        />
      </Switch>
    </Router>
  );
};
